import { StateCreator } from "zustand";


interface Stepper {
    activeStep: number;
    handleNext:Function;
    handleBack: Function;
}

const initialState = {
    activeStep: 0,
};

const useStepperSlice: StateCreator<Stepper> = (set, get) => ({
  ...initialState,
  set: (steps:number) => {
    set({ activeStep: steps });
  },
   handleNext : () => {
    set({ activeStep:get().activeStep+1})
  },

   handleBack : () => {
    set({ activeStep:get().activeStep-1})
  },
});

export default useStepperSlice;
