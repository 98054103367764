import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { ChemistData } from "../../../domain/models/chemist/ChemistData";
import { FirmNameType } from "../../../domain/models/chemist/firmNameType";
import { Contacts } from "../../../domain/models/contacts";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";

type Props = {
  chemistDetails: ChemistData;
  mobileContacts: Contacts[];
  emailContacts: Contacts[];
};

const ChemistDetails: React.FC<Props> = ({
  chemistDetails,
  mobileContacts,
  emailContacts,
}) => {
  const [firmNames, setFirmNames] = useState<FirmNameType[]>([]);

  let regex = /(^|\s)\S/g;

  useEffect(() => {
    for (let i = 0; i <= chemistDetails.firmNames.length - 1; i++) {
      if (
        chemistDetails.firmNames[i].source == "cpay" ||
        chemistDetails.firmNames[i].source == "cpaisa"
      ) {
        firmNames.push(chemistDetails.firmNames[i]);
        setFirmNames([...firmNames]);
      }
    }
  }, [chemistDetails._id]);

  return (
    <div>
      {firmNames.length > 0 && (
        <GaTypography typographyType={TypographyType.LG_BOLD}>
          {firmNames[firmNames.length - 1].name
            ?.toLowerCase()
            .replace(regex, (letter) => letter.toUpperCase())}
        </GaTypography>
      )}

      <div style={{ marginBlock: 10 }}>
        <GaTypography
          typographyType={TypographyType.SM}
          color={TypographyColor.PRIMARY}
        >
          {mobileContacts[mobileContacts.length - 1]?.value}
        </GaTypography>
        <GaTypography
          typographyType={TypographyType.SM}
          color={TypographyColor.PRIMARY}
        >
          {emailContacts && emailContacts[emailContacts.length - 1]?.value}
        </GaTypography>
      </div>
      <div>
        {chemistDetails.addresses.length > 0 && (
          <>
            <Divider />
            <div style={{ marginBlock: "10px" }}>
              <GaTypography typographyType={TypographyType.SM}>
                ADDRESS:
              </GaTypography>
              <GaTypography
                typographyType={TypographyType.SM}
                color={TypographyColor.INFO}
              >
                {[
                  chemistDetails?.addresses[
                    chemistDetails?.addresses.length - 1
                  ]?.line,
                  chemistDetails?.addresses[
                    chemistDetails?.addresses.length - 1
                  ]?.landmark,
                  chemistDetails?.addresses[
                    chemistDetails?.addresses.length - 1
                  ]?.area,
                  chemistDetails?.addresses[
                    chemistDetails?.addresses.length - 1
                  ]?.city,
                  chemistDetails?.addresses[
                    chemistDetails?.addresses.length - 1
                  ]?.district,
                  chemistDetails?.addresses[
                    chemistDetails?.addresses.length - 1
                  ]?.state,
                  chemistDetails?.addresses[
                    chemistDetails?.addresses.length - 1
                  ]?.pincode,
                ]
                  .filter((el) => {
                    return el != null && el != "";
                  })
                  .join(", ")
                  ?.toLowerCase()
                  .replace(regex, (letter) => letter.toUpperCase())}
              </GaTypography>
            </div>
          </>
        )}
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBlock: 10,
        }}
      >
        {chemistDetails.panNumber && (
          <div>
            <GaTypography typographyType={TypographyType.SM}>
              PAN NUMBER:
            </GaTypography>
            <GaTypography
              typographyType={TypographyType.SM}
              color={TypographyColor.INFO}
            >
              {chemistDetails?.panNumber}
            </GaTypography>
          </div>
        )}
        {chemistDetails.gstNumber && (
          <div>
            <GaTypography typographyType={TypographyType.SM}>
              GST NUMBER:
            </GaTypography>
            <GaTypography
              typographyType={TypographyType.SM}
              color={TypographyColor.INFO}
            >
              {chemistDetails?.gstNumber}
            </GaTypography>
          </div>
        )}
      </div>
      <div style={{ marginBottom: "6px", marginTop: "22px" }}>
        <GaTypography typographyType={TypographyType.SM}>
          Image Uploaded :
        </GaTypography>
      </div>

      <Divider />
    </div>
  );
};

export default ChemistDetails;
