import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { GaAccordion } from "../../ga-components/Accordion";
import BrickChemistDetails from "./BrickChemistDetails";
import { MappedChemist } from "../../../domain/models/doctor/mappedChemist";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import jwt_decode from "jwt-decode";

type Props = {
  doctorDetails: DoctorData;
};

const MappedChemists: React.FC<Props> = ({ doctorDetails }) => {
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>();
  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);

  const filterMappedChemist = () => {
    let filteredData: MappedChemist[] = [];
    if (loggedInUserDetails) {
      filteredData = doctorDetails.chemists.filter((item) => {
        return (
          item.deptCode == loggedInUserDetails.dept_code &&
          item.orgCode == loggedInUserDetails.org_code &&
          item.teamCode == loggedInUserDetails.team_code
        );
      });
      return filteredData;
    }
    return filteredData;
  };
  return (
    <div>
      <GaAccordion
        expandIcon={<ExpandMoreIcon />}
        bgColor={"#F5F5F5"}
        summary={
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >{`${filterMappedChemist().length} Mapped Chemists`}</GaTypography>
        }
        details={
          <div style={{ overflow: "auto", height: "200px" }}>
            {filterMappedChemist().length > 0 &&
              filterMappedChemist().map((data: MappedChemist) => {
                return (
                  <BrickChemistDetails data={data} doctorData={doctorDetails} />
                );
              })}
          </div>
        }
      />
    </div>
  );
};

export default MappedChemists;
