import HTTPStatusCode from "../../../domain/enums/httpStatusCode";
import { FetchChemists } from "../../../domain/usages/Chemists/fetch-chemists";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchChemists implements FetchChemists {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}
  async fetch(params: FetchChemists.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        ["ngrok-skip-browser-warning"]: "1",
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    console.log("response", httpResponse.data);
    if (httpResponse.status == HTTPStatusCode.OK) {
      return httpResponse.data;
    }
  }
}
