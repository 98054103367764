import { useCallback, useEffect, useState } from "react";
import { Brick } from "../../domain/models/brick";
import { FetchBricks } from "../../domain/usages/fetch-bricks";

const useBricks = (fetchBricks: FetchBricks) => {
  const [bricks, setBricks] = useState<Brick[]>([]);

  const fetch = useCallback(async () => {
    const filter = {
      type: "BRICK",
      length: 100,
    };
    let result = await fetchBricks.fetch(filter);
    if (result) {
      setBricks(result.data);
    }
  }, [fetchBricks]);

  useEffect(() => {
    fetch();
  }, []);

  return bricks;
};

export default useBricks;
