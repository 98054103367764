import React, { ChangeEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { GaButtonColor } from "../ga-components/Button";
import { useNavigate } from "react-router-dom";
import { AddCircleOutlineOutlined, ArrowForward } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  MobileStepper,
  Stack,
} from "@mui/material";
import path from "path";
import { List } from "lodash";
import {
  AUTH_TOKEN_KEY,
  AUTH_HEADER_CHANNELPAY,
  AUTH_API_URL,
} from "../../base";
import { RemoteConfirmFileUpload } from "../../data/usecases/remote-confirm-file-upload";
import { RemoteGenerateFileUploadUrl } from "../../data/usecases/remote-generate-file-upload-url";
import { RemoteUploadFile } from "../../data/usecases/remote-upload-file";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import useFileUpload from "../hooks/useFileUpload";
import { ConfirmFileUpload } from "../../domain/usages/confirm-file-upload";
import { GenerateFileUploadUrl } from "../../domain/usages/generate-file-upload-url";
import { UploadFile } from "../../domain/usages/upload-file";
import useMultiFileUpload from "../hooks/useMultiFileUpload";
import { pageRoutes } from "../../routes";
import Header from "./Header/Header";
import { ChemistStore } from "../../store/main/ChemistStore";
import loadingIndicator from "../../images/loading_indicator.gif";

type DocumentUploadComponentProps = {
  documentName: string;
  documentCode: string;
  screenTitle: string;
  mobileNumber: string;
};

const DocumentUploadComponent: React.FC<DocumentUploadComponentProps> = ({
  documentName,
  documentCode,
  screenTitle,
  mobileNumber,
}) => {
  var navigate = useNavigate();
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();

  // TODO:: replace the hard coded string with the dynamic user token
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER_CHANNELPAY]: token,
  });

  const remoteGenerateUploadURL = new RemoteGenerateFileUploadUrl(
    `${AUTH_API_URL}${Endpoints.GET_UPLOADED_IMAGE_URL}`,
    axiosHttpClient
  );

  const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);

  const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
    `${AUTH_API_URL}${Endpoints.CONFIRM_IMAGE_UPLOAD_TO_BUCKET}`,
    axiosHttpClient
  );

  const { formData, GenerateUploadURlData } = ChemistStore();

  // TODO:: replace the hard coded string with the dynamic user token
  const { fetchUploadState, activeStep, handleBack, handleNext } =
    ChemistStore();

  return (
    <Stack
      sx={{
        backgroundImage: "linear-gradient(to bottom, #E4E1F2 , #FFFFFF )",
      }}
    >
      <Header
        title=""
        showBackButton={true}
        handleClick={() => {
          navigate(pageRoutes.chemistRegistration);
          handleBack();
        }}
      />

      <Typography
        variant="caption"
        fontWeight={500}
        color={"black"}
        align="center"
        mb={"1rem"}
      >
        Upload FORM 20
      </Typography>
      <MobileStepper
        variant="dots"
        steps={3}
        position="static"
        activeStep={activeStep}
        sx={{
          maxWidth: 400,
          // right:70,
          alignSelf: "center",

          flexGrow: 1,
          bgcolor: "transparent",
          height: "10px",
        }}
        nextButton
        backButton
      />

      <Stack gap={2} sx={{ marginX: 2 }}>
        <Stack flexDirection="row" gap={0.5}>
          <Typography>Hey</Typography>
          <Typography color="blue">{formData.name}</Typography>
        </Stack>

        <Typography variant="h4" fontWeight={700} textAlign={"left"}>
          Upload Drug Licence Form 20
        </Typography>
        <Typography variant="body1" fontWeight={400} textAlign={"left"}>
          Please upload your DL Form 20
          {/* <Typography variant="body1" sx={{ fontWeight: "700" }}>
            {" "}
            {}
          </Typography> */}
        </Typography>

        <MultipleFileUploadComponent
          generateUploadURL={remoteGenerateUploadURL}
          uploadFile={remoteUploadFile}
          confirmFileUpload={remoteConfirmFileUpload}
          documentCode={"DRUG_LICENSE_FORM_20"}
        />

        <Box
          sx={{
            height: "20px",
          }}
        ></Box>
      </Stack>
    </Stack>
  );
};

type FileDisplayComponentProps = {
  file: File;
  isSuccess: boolean;
  index: number;
  // handleReuploadChange: (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   index: number
  // ) => void;
};

const FileDisplayComponent: React.FC<FileDisplayComponentProps> = ({
  file,
  isSuccess,
  index,
  // handleReuploadChange,
}) => (
  <Box
    sx={{
      p: 2,
      border: "1px dashed grey",
      borderRadius: "8px",
      width: "100%",
      height: "100%",
    }}
  >
    {isSuccess === true ? (
      <>
        {file.type.startsWith("image/") ? (
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        ) : (
          <Typography>{file.name}</Typography>
        )}
        {/* </label> */}
      </>
    ) : (
      <Box
        component="img"
        src={loadingIndicator}
        sx={{ width: "100%", height: "100%", objectFit: "contain" }}
      />
    )}
  </Box>
);

type FilePickerComponentProps = {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const FilePickerComponent: React.FC<FilePickerComponentProps> = ({
  handleFileChange,
}) => {
  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid grey",
        borderRadius: "8px",
        width: "100%",
        height: "100%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <AddCircleOutlineOutlined />
      </label>
    </Box>
  );
};

type Props = {
  generateUploadURL: GenerateFileUploadUrl;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmFileUpload;
  documentCode: string;
};

const MultipleFileUploadComponent: React.FC<Props> = ({
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
  documentCode,
}) => {
  const [fileUploads, isLoading, uploadFiles, reset, reuploadFile] =
    useMultiFileUpload(
      generateUploadURL,
      uploadFile,
      confirmFileUpload,
      documentCode
    );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log("@@@@@@");
    if (event.target.files) {
      const files = Array.from(event.target.files);
      console.log(files);
      uploadFiles(files);
    }
  };

  // const handleReuploadChange = (
  //   event: ChangeEvent<HTMLInputElement>,
  //   index: number
  // ) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const newFile = event.target.files[0];
  //     reuploadFile(newFile, index);
  //     console.log(newFile, index);
  //   }
  // };
  const { fetchUploadState, activeStep, handleBack, handleNext } =
    ChemistStore();

  const handleReuploadChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.files && event.target.files) {
      for (index = 0; index < event.target.files.length; index++) {
        const newFile = event.target.files[index];
        reuploadFile(newFile, index);
        console.log(newFile, index);
      }
    }
  };
  let navigate = useNavigate();

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          overflow: "auto",
          maxHeight: "70vh",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {/* {fileUploads.map((fileModel, index) => (
        <Grid item xs={6} key={index} sx={{ height: "42vw" }}>
          <FileDisplayComponent
            file={fileModel.file}
            isSuccess={fileModel.success}
            index={index}
            handleReuploadChange={(event) => handleReuploadChange(event, index)}
          />
        </Grid>
      ))} */}
        {fileUploads.map((fileModel, index) => (
          <Grid item xs={6} key={index} sx={{ height: "42vw" }}>
            <FileDisplayComponent
              file={fileModel.file}
              isSuccess={fileModel.success}
              index={index}
              // handleReuploadChange={handleReuploadChange}
            />
          </Grid>
        ))}
        {fileUploads.length < 2 && (
          <Grid item xs={6} sx={{ height: "42vw" }}>
            <FilePickerComponent handleFileChange={handleFileChange} />
          </Grid>
        )}
      </Grid>
      <Stack
        gap={2}
        position={"absolute"}
        bottom={30}
        right={10}
        padding={"12px 24px"}
        alignSelf={"flex-end"}
      >
        <LoadingButton
          size="medium"
          variant="contained"
          disabled={fileUploads.length == 0}
          loading={false}
          endIcon={<ArrowForward />}
          color={GaButtonColor.PRIMARY}
          sx={{
            height: "48px",
            padding: "12px 24px",
            borderRadius: "16px",
            alignSelf: "flex-end",
          }}
          onClick={() => {
            navigate(pageRoutes.confirmRegistrationPage, {
              state: {
                uploadedFiles: fileUploads,
              },
            });
            handleNext();
          }}
        >
          <Typography color="white" textTransform={"none"}>
            {"Continue"}
          </Typography>
        </LoadingButton>
      </Stack>
    </>
    //     <div>
    //     <input type="file" multiple onChange={handleFileChange} />
    //     {/* <button onClick={reset}>Reset</button> */}
    //     {isLoading && <p>Uploading files...</p>}
    //     <ul>
    //       {fileUploads.map((upload, index) => (
    //         <li key={index}>
    //           {upload.file.name} -{" "}
    //           {upload.success ? "Success" : `Failed: ${upload.error}`}
    //           <div>
    //             <img src={upload.previewUrl} alt={upload.file.name} width="100" />
    //             {upload.uploadedUrl && (
    //               <img
    //                 src={upload.uploadedUrl}
    //                 alt={upload.file.name}
    //                 width="100"
    //               />
    //             )}
    //           </div>

    //         </li>
    //       ))}
    //     </ul>
    //   </div>
  );
};

export default DocumentUploadComponent;
