import { StateCreator } from "zustand";
import {  AUTH_API_URL, AUTH_HEADER_CHANNELPAY,  AUTH_TOKEN_KEY } from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import Endpoints from "../../../domain/endpoints";
import { RemoteFetchBricks } from "../../../data/usecases/remote-fetch-bricks";
import { Brick, BrickApiResponse } from "../../../domain/models/brick";

interface ChemistBrickDetailsSliceInterface {
  getBricksDetails: () => Promise<void>; 
  BricksDetails: Brick[]; 
}

const initialStates: ChemistBrickDetailsSliceInterface = {
  BricksDetails: [], 
  getBricksDetails: async () => {}, 
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const getChemistBrickSlice: StateCreator<ChemistBrickDetailsSliceInterface> = (
  set,
  get
) => ({
  ...initialStates,
  getBricksDetails: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);

    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER_CHANNELPAY]:token }); 

    const remoteFetchBricks = new RemoteFetchBricks(
      `${AUTH_API_URL}${Endpoints.FETCH_BRICKS}`,
      axiosHttpClient
    );

    try {
        const result:BrickApiResponse = await remoteFetchBricks.fetch({
            length:1000,
        });
    
        set((state) => ({
            BricksDetails: result.data,
        }));
      } catch (error) {
        console.error("Failed to fetch bricks details:", error);
      }
    }
}); 