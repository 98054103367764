import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { GaAccordion } from "../../ga-components/Accordion";
import { HospitalData } from "../../../domain/models/Hospital/hospitalData";
import { HospitalMappedDoctor } from "../../../domain/models/Hospital/hospitalMappedDoctor";
import BrickHospitalDoctorDetails from "./BrickHospitalDoctorDetails";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import jwt_decode from "jwt-decode";

type Props = {
  hospitalDetails: HospitalData;
};

const MappedHospitalDoctors: React.FC<Props> = ({ hospitalDetails }) => {
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>();

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);
  const filterMappedDoctors = () => {
    let filteredData: HospitalMappedDoctor[] = [];
    if (loggedInUserDetails) {
      filteredData = hospitalDetails.doctors.filter((item) => {
        return (
          item.deptCode == loggedInUserDetails.dept_code &&
          item.orgCode == loggedInUserDetails.org_code &&
          item.teamCode == loggedInUserDetails.team_code
        );
      });
      return filteredData;
    }
    return filteredData;
  };
  return (
    <div>
      <GaAccordion
        expandIcon={<ExpandMoreIcon />}
        bgColor={"#F5F5F5"}
        summary={
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >{`${filterMappedDoctors().length} Mapped Doctor(s)`}</GaTypography>
        }
        details={
          <div style={{ overflow: "auto", height: "200px" }}>
            {filterMappedDoctors().length > 0 &&
              filterMappedDoctors().map((data: HospitalMappedDoctor) => {
                return (
                  <BrickHospitalDoctorDetails
                    data={data}
                    hospitalData={hospitalDetails}
                  />
                );
              })}
          </div>
        }
      />
    </div>
  );
};

export default MappedHospitalDoctors;
