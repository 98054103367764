import React, { useCallback, useEffect, useState } from "react";
import { Image } from "../../domain/models/image";
import Status from "../../domain/models/status";
import { GetReadLink } from "../../domain/usages/get-read-link";
import { CardType, GaCard } from "../ga-components/Card";
import {
  GaTypography,
  TypographyAlignment,
  TypographyColor,
  TypographyType,
} from "../ga-components/Typography";
import ImagePreviewModal from "./ImagePreviewModal";

type Props = {
  imageDetails: Image;
  getReadLink: GetReadLink;
  uuid: string;
};

const ImageCards: React.FC<Props> = ({ imageDetails, getReadLink, uuid }) => {
  const [imageLink, setImageLink] = useState("");
  const [showImagePreview, setShowImagePreview] = useState(false);
  let regex = /(^|\s)\S/g;

  const getImage = useCallback(
    async (uuid: string) => {
      if (uuid) {
        let result = await getReadLink.get(uuid, {});
        if (result.url) setImageLink(result.url);
        else setImageLink(result.data?.url);
      }
    },
    [getReadLink]
  );

  useEffect(() => {
    if (uuid) {
      getImage(uuid);
    }
  }, [uuid]);

  const borderColor: Record<string, CardType> = {
    [Status.REJECTED]: CardType.REJECTED_CARD,
    [Status.PROCESSING]: CardType.PENDING_CARD,
    [Status.VERIFIED]: CardType.APPROVED_CARD,
    [Status.UPLOADED]: CardType.NORMAL_CARD,
  };

  const textColor: Record<string, TypographyColor> = {
    [Status.REJECTED]: TypographyColor.ERROR,
    [Status.PROCESSING]: TypographyColor.SECONDARY,
    [Status.VERIFIED]: TypographyColor.SUCCESS,
    [Status.UPLOADED]: TypographyColor.WARNING,
  };

  const handleClose = () => {
    setShowImagePreview(false);
  };
  const handlePreview = () => {
    setShowImagePreview(true);
  };
  return (
    <div>
      <div
        style={{
          marginTop: "13px",
          width: "70px",
          height: "60px",
          paddingLeft: "5px",
        }}
      >
        <GaCard
          cardType={
            borderColor[
              imageDetails.status ? imageDetails.status : Status.PROCESSING
            ]
          }
        >
          <img
            src={imageLink}
            style={{ width: "70px", height: "60px" }}
            alt=""
            onClick={handlePreview}
          ></img>
        </GaCard>
      </div>
      {/* <div
        style={{ display: "flex", justifyContent: "center", marginTop: "12px" }}
      >
        <GaIcon
          color={IconColor.WARNING}
          children={<RefreshIcon fontSize="small" />}
        />
      </div> */}

      <div style={{ textAlign: "center", marginTop: "12px" }}>
        <GaTypography typographyType={TypographyType.XS}>
          {imageDetails.type?.toLowerCase()}
        </GaTypography>
      </div>
      <div style={{ marginTop: "4px" }}>
        <GaCard
          cardType={
            borderColor[
              imageDetails.status ? imageDetails.status : Status.PROCESSING
            ]
          }
          borderRadius="20px"
        >
          <GaTypography
            color={
              textColor[
                imageDetails.status ? imageDetails.status : Status.PROCESSING
              ]
            }
            align={TypographyAlignment.CENTER}
          >
            {imageDetails.status
              ?.toLowerCase()
              .replace(regex, (letter) => letter.toUpperCase())}
          </GaTypography>{" "}
        </GaCard>
      </div>
      {showImagePreview && (
        <ImagePreviewModal
          imageLink={imageLink}
          handleClose={handleClose}
          showImagePreview={showImagePreview}
        />
      )}
    </div>
  );
};

export default ImageCards;
