import { Box } from "@mui/material";
import Header from "../components/Header/Header";
import Tabbar from "../components/TabBar/Tabbar";
import { useLocation } from "react-router-dom";

const CustomerProfiler = () => {
  const location: any = useLocation();
  return (
    <Box>
      <Header title="Customers" />
      <Tabbar tabValue={location.state?.value ? location.state.value : 0} />
    </Box>
  );
};

export default CustomerProfiler;
