import React, { useEffect, useState } from "react";
import axios from "axios";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: "auto",
  color: theme.palette.text.secondary,
}));

type stackProps = {
  count: number;
};

const ChemistSummary: React.FC<stackProps> = ({ count }) => {
  const heading = {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14.06px",
    color: "info.main",
  };
  const digits = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "18.75px",
    color: "#212121",
    marginTop: 1,
    textAlign: "center",
  };

  return (
    <Stack
      direction="row"
      mt={2}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Item elevation={0}>
        <Typography sx={heading}>Total Chemists</Typography>
        <Typography sx={digits}>{count}</Typography>
      </Item>
      <Item elevation={0}>
        <Typography sx={heading}>Verified Chemists</Typography>
        <Typography sx={digits}>0</Typography>
      </Item>
      <Item elevation={0}>
        <Typography sx={heading}>Verification Failed</Typography>
        <Typography sx={digits}>0</Typography>
      </Item>
    </Stack>
  );
};
export default ChemistSummary;
