import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { GaAccordion } from "../../ga-components/Accordion";
import BrickHospitalPharmacyDetails from "./BrickHospitalPharmacyDetails";
import { HospitalMappedPharmacy } from "../../../domain/models/Hospital/hospitalMappedPharmacy";
import { HospitalData } from "../../../domain/models/Hospital/hospitalData";
import PharmacyStaffDetails from "./PharmacyStaffDetails";

type Props = {
  mappedPharmacy: HospitalMappedPharmacy;
  hospitalData: HospitalData;
  handleOpenConfirmModal: Function;
};

const MappedPharmacy: React.FC<Props> = ({
  mappedPharmacy,
  hospitalData,
  handleOpenConfirmModal,
}) => {
  return (
    <div style={{ display: "grid", gap: 20 }}>
      <GaAccordion
        expandIcon={<ExpandMoreIcon />}
        bgColor={"#F5F5F5"}
        summary={
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >{` Mapped Pharmacy`}</GaTypography>
        }
        details={
          <div style={{ overflow: "auto", height: "200px" }}>
            <BrickHospitalPharmacyDetails
              data={mappedPharmacy}
              hospitalData={hospitalData}
              handleOpenConfirmModal={() => {
                handleOpenConfirmModal(true);
              }}
            />
          </div>
        }
      />
      {mappedPharmacy.staffs.length > 0 && (
        <GaAccordion
          expandIcon={<ExpandMoreIcon />}
          bgColor={"#F5F5F5"}
          summary={
            <GaTypography
              typographyType={TypographyType.SM}
              color={TypographyColor.INFO}
            >{`Pharmacy Staff`}</GaTypography>
          }
          details={
            <div style={{ overflow: "auto", height: "200px" }}>
              <PharmacyStaffDetails data={mappedPharmacy} />
            </div>
          }
        />
      )}
    </div>
  );
};

export default MappedPharmacy;
