import { createElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import indexRoutes, { pageRoutes } from "./routes";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={pageRoutes.authenticate} />} />
      {indexRoutes.map((prop, key) => {
        return (
          <Route
            key={key}
            path={prop.path}
            element={createElement(prop.component)}
          ></Route>
        );
      })}
    </Routes>
  );
}

export default App;
