import ArrowBack from "@mui/icons-material/ArrowBack";
import React, { useState } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { GaIcon, IconColor, IconSize } from "../../ga-components/Icons";
import { IconButton } from "@mui/material";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import { HelpAndInfoModal } from "../HelpAndInfoModal";

type headerProps = {
  showBackButton?: boolean;
  trailing?: React.ReactNode;
  title: string;
  handleClick?: () => void;
};

const Header: React.FC<headerProps> = ({
  showBackButton = true,
  trailing = "",
  title,
  handleClick = () => {},
}) => {
  const styles = {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Roboto",
    fontStyle: "normal",
  };

  const [helpAndInfoModal, setHelpAndInfoModal] = useState(false);

  const openHelpAndInfoModal = (open: boolean) => {
    setHelpAndInfoModal(open);
  };

  return (
    <>
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          margin: 20,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          {showBackButton && (
            <>
              <div style={{ margin: 1, marginRight: "10px" }}>
                <GaIcon color={IconColor.INHERIT} fontSize={IconSize.SMALL}>
                  <ArrowBack onClick={handleClick} />
                </GaIcon>
              </div>
            </>
          )}

          <div
            style={{
              fontSize: styles.fontSize,
              fontWeight: styles.fontWeight,
              fontFamily: styles.fontFamily,
              fontStyle: styles.fontStyle,
            }}
          >
            <GaTypography
              color={TypographyColor.PRIMARY}
              typographyType={TypographyType.LG}
            >
              {title}
            </GaTypography>
          </div>
        </div>

        <div>{trailing}</div>
        {title === "Customers" && (
          <div>
            <IconButton
              onClick={() => openHelpAndInfoModal(true)}
              sx={{
                position: "fixed",
                top: 16,
                right: 16,
              }}
            >
              <HelpRoundedIcon />
            </IconButton>
          </div>
        )}
        {helpAndInfoModal && (
          <HelpAndInfoModal
            open={helpAndInfoModal}
            handleClose={() => openHelpAndInfoModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default Header;
