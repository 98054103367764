import { CheckMobile } from "../../../domain/usages/Chemists/check-mobile";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";
import { HttpPutClient } from "../../protocols/http/http-put-client";
// import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteCheckMobile implements CheckMobile {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async create(params: CheckMobile.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":mobile",params.mobile),
      query: params,
      headers: {
        ["ngrok-skip-browser-warning"]: "1",
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
