const Endpoints = {
  //HOSPITAL ENDPOINTS
  FETCH_HOSPITALS: "/v1/hospitals",
  GET_HOSPITAL_BY_ID: "/v1/hospitals/id/:id",
  ADD_PHARMACY_TO_HOSPITAL: "/v1/hospitals/:id/pharmacy",
  ADD_DOCTORS_TO_HOSPITAL: "/v1/hospitals/:id/doctors",
  ADD_STAFF_TO_PHARMACY: "/v1/hospitals/:id/staffs",
  FETCH_HOSPITAL_MASTER: "/v1/hospitals/masters",
  CREATE_HOSPITAL: "/v1/hospitals",
  GET_HOSPITAL_PHARMACY: "/v1/hospitals/:id/pharmacy",
  ADD_HOSPITAL_IMAGES: "/v1/hospitals/:id/images",
  HOSPITAL_QUESTIONAIRE: "/v1/hospitals/:id/questionnaire",
  DELETE_MAPPED_PHARMACY: "/v1/hospitals/:id/remove-pharmacy-mapping",
  FETCH_HOSPITAL_THERAPY: "/v1/hospitals/:id/prescriptions",
  FETCH_HOSPITAL_BRANDS: "/v1/brands/brands",
  FETCH_HOSPITAL_COMPETITORS: "/v1/brands/:id/competitors",

  //DOCTOR ENDPOINT
  GET_ALL_PROGRAM_PRODUCTS: "/v1/doctors/programs/:id",
  ENROLL_DOCTOR_FOR_PROGRAM: "/v1/doctors/:id/programs",
  ADD_DOCTOR_IMAGES: "/v1/doctors/:id/images",
  FETCH_DOCTORS: "/v1/doctors",
  CREATE_DOCTORS: "/v1/doctors",
  GET_DOCTOR_BY_ID: "/v1/doctors/:id",
  APPEND_DOCTOR_IMAGES: "/v1/doctors/:id/images",
  MAP_CHEMISTS_TO_DOCTOR: "/v1/doctors/:id/map-chemists",
  GET_DOCTOR_UPLOADED_IMAGE_URL: "/v1/uploads/generate-upload-url",
  CONFIRM_DOCTOR_IMAGE_UPLOAD_TO_BUCKET: "/v1/uploads/confirm-upload",
  GET_DOCTOR_READ_IMAGE_URL: "/v1/uploads/read-url/:uuid",
  FETCH_DOCTOR_MASTERS: "/v1/masters/doctors",
  FETCH_BRICKS: "/v1/geographic/headquarters",
  FETCH_THERAPY: "/v1/doctors/:id/prescriptions",
  FETCH_BRANDS: "/v1/brands/brands",
  FETCH_COMPETITORS: "/v1/brands/:id/competitors",
  STORE_ADDRESS: "/v1/doctors/:id/addresses",
  ADD_DOCTOR_CONTACTS: "/v1/doctors/:id/contacts",
  CONFIRM_DOCTOR_ADDRESS: "/v1/doctors/id/:id/addresses/:addressId/confirm",
  UPDATE_DOCTOR_ADDRESS: "/v1/doctors/:id/addresses/:addressId",
  FETCH_PROGRAMS_FOR_DOCTOR: "/v1/doctors/filter/programs",

  //CUSTOMER ENDPOINTS
  FETCH_MAPPED_DOCTORS: "/v1/doctors/:id/chemists",
  MAP_DOCTORS_TO_CHEMIST: "/v1/doctors/map-doctors",
  APPEND_CUSTOMER_IMAGES: "/v1/customers/:id/images",
  CREATE_CUSTOMERS: "/v1/retailer/register",
  CHECK_MOBILE:"/v3/customer/mobile/:mobile/is-registered",
  GET_CUSTOMER_BY_ID: "/v1/customers/id/:id",
  FETCH_CUSTOMERS: "/v1/customers",
  ADD_STAFF: "/v1/customers/:id/workers",
  ADD_CUSTOMER_IMAGE: "/v1/customers/:id/images",
  WORKER_TYPES: "/v1/customers/workers/types",
  ADD_WORKER_CONTACT: "/v1/customers/:customerId/worker/:workerId/contacts",
  FIND_WORKERS_BY: "/v1/customers/:id/workers",
  FIND_UPI_BY: "/v1/customers/:id/payments",
  STORE_UPI_ID: "/v1/customers/:id/payments/vpas",
  FETCH_CHEMIST_TYPES: "/v1/customers/types",

  //COMMON ENDPOINTS
  GET_READ_LINK: "/v1/file/:uuid",
  GET_UPLOADED_IMAGE_URL: "/v1/file",
  CONFIRM_IMAGE_UPLOAD_TO_BUCKET: "/v1/file",
  GET_HEADQUARTER_DETAILS: "/v1/geographic/headquarters/:id",
  GEO_LOCATION: "/v1/geo-locations/fetch-address",
  GET_ADDRESS_BY_PINCODE: "/v1/locations/pincodes/:pincode",
};

export default Endpoints;
