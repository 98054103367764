import {
  AUTH_API_URL,
  AUTH_HEADER,
  AUTH_HEADER_CHANNELPAY,
  AUTH_TOKEN_KEY,
  DOCTOR_PROFILER_API_URL,
  LOCATION_SERVICE_API_URL,
  LOCATION_SERVICE_TOKEN,
} from "../../base";
import { RemoteRegisterDoctor } from "../../data/usecases/Doctors/remote-register-doctor";
import { RemoteConfirmFileUpload } from "../../data/usecases/remote-confirm-file-upload";
import { RemoteFetchBricks } from "../../data/usecases/remote-fetch-bricks";
import { RemoteFetchGeoAddresses } from "../../data/usecases/remote-fetch-geo-addresses";
import { RemoteGenerateFileUploadUrl } from "../../data/usecases/remote-generate-file-upload-url";
import { RemoteUploadFile } from "../../data/usecases/remote-upload-file";
import Endpoints from "../../domain/endpoints";
import { Qualification } from "../../domain/models/doctor/qualification";
import { Speciality } from "../../domain/models/doctor/speciality";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import DoctorRegistrationForm from "../../presentation/components/Doctor/DoctorRegistrationForm";
import { RemoteGetAddressByPincode } from "../../data/usecases/remote-get-address-by-pincode";

type Props = {
  specialities: Speciality[];
  qualifications: Qualification[];
};

export const UploadDoctorFileFactory: React.FC<Props> = ({
  specialities,
  qualifications,
}) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const axiosHttpClientChannelpay = AxiosHttpClient.getInstance();
  axiosHttpClientChannelpay.setAuthHeaders({
    [AUTH_HEADER_CHANNELPAY]: token,
  });

  const axiosHttpClientMaster = AxiosHttpClient.getInstance();
  axiosHttpClientMaster.setAuthHeaders({
    [AUTH_HEADER]: `${LOCATION_SERVICE_TOKEN}`,
  });

  const remoteGenerateUploadURL = new RemoteGenerateFileUploadUrl(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.GET_DOCTOR_UPLOADED_IMAGE_URL}`,
    axiosHttpClient
  );

  const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);

  const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.CONFIRM_DOCTOR_IMAGE_UPLOAD_TO_BUCKET}`,
    axiosHttpClient
  );

  const remoteFetchBricks = new RemoteFetchBricks(
    `${AUTH_API_URL}${Endpoints.FETCH_BRICKS}`,
    axiosHttpClientChannelpay
  );

  const remoteRegisterDoctor = new RemoteRegisterDoctor(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.CREATE_DOCTORS}`,
    axiosHttpClient
  );

  const remoteFetchGeoAddresses = new RemoteFetchGeoAddresses(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.GEO_LOCATION}`,
    axiosHttpClient
  );

  const remoteGetAddressByPincode = new RemoteGetAddressByPincode(
    `${LOCATION_SERVICE_API_URL}${Endpoints.GET_ADDRESS_BY_PINCODE}`,
    axiosHttpClientMaster
  );

  return (
    <DoctorRegistrationForm
      registerDoctor={remoteRegisterDoctor}
      generateUploadURL={remoteGenerateUploadURL}
      uploadFile={remoteUploadFile}
      confirmFileUpload={remoteConfirmFileUpload}
      specialities={specialities}
      qualifications={qualifications}
      fetchBricks={remoteFetchBricks}
      fetchGeoAddresses={remoteFetchGeoAddresses}
      getAddressByPincode={remoteGetAddressByPincode}
    />
  );
};
