import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Button from "@mui/material/Button";
import { pageRoutes } from "../../../routes";
import {
  GaTypography,
  TypographyType,
  TypographyColor,
  TypographyAlignment,
} from "../../ga-components/Typography";
import { ChemistData } from "../../../domain/models/chemist/ChemistData";
import { GaAccordion } from "../../ga-components/Accordion";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import BrickDoctorDetails from "./BrickDoctorDetails";
import { FetchMappedDoctors } from "../../../domain/usages/Chemists/fetch-mapped-doctors";
import { CardContent, Skeleton } from "@mui/material";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Contacts } from "../../../domain/models/contacts";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import jwt_decode from "jwt-decode";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import { FirmNameType } from "../../../domain/models/chemist/firmNameType";
import { Mappings } from "../../../domain/models/mappings";

type Props = {
  cardDetails: ChemistData;
  fetchMappedDoctors: FetchMappedDoctors;
};

const ChemistDetailsCard: React.FC<Props> = ({
  cardDetails,
  fetchMappedDoctors,
}) => {
  const [mappedDoctors, setMappedDoctors] = useState<DoctorData[]>([]);
  const [loading, setLoading] = useState(FetchState.DEFAULT);
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>();
  const [mobileContacts, setMobileContacts] = useState<Contacts[]>([]);
  const [emailContacts, setEmailContacts] = useState<Contacts[]>([]);
  const navigate = useNavigate();

  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const [firmNames, setFirmNames] = useState<FirmNameType[]>([]);

  const container = {
    fontFamily: "Roboto",
    width: "100%",
  };
  const subTitleStyle = {
    margin: 2,
    marginBottom: "10px",
    color: "#6B6B6B",
  };

  const fetchMappedDoctorsList = async (id: string) => {
    if (id) {
      setLoading(FetchState.LOADING);
      let result = await fetchMappedDoctors.fetch(id);
      if (result.success) {
        setMappedDoctors(result.data);
        setLoading(FetchState.SUCCESS);
      }
    }
  };

  const handleClick = () => {
    navigate(pageRoutes.chemistProfilePage, {
      state: {
        id: cardDetails._id,
      },
    });
  };
  const handleMapping = () => {
    navigate(pageRoutes.doctorMapping, {
      state: {
        chemist: cardDetails,
        brickCode: checkMapDoctorValidation(cardDetails.mappings).brickCode,
      },
    });
  };

  useEffect(() => {
    for (let i = 0; i <= cardDetails.firmNames.length - 1; i++) {
      if (
        cardDetails.firmNames[i].source == "cpay" ||
        cardDetails.firmNames[i].source == "cpaisa"
      ) {
        firmNames.push(cardDetails.firmNames[i]);
        setFirmNames([...firmNames]);
      }
    }

    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }

    mobileContacts.length = 0;
    {
      cardDetails.contacts?.map((contact, index) => {
        if (contact.type == "mobile" && contact.status == "active") {
          mobileContacts.length = 0;
          mobileContacts.push(contact);
          return <div key={index}></div>;
        } else if (contact.type == "email" && contact.status == "active") {
          emailContacts.length = 0;
          emailContacts.push(contact);
          return <div key={index}></div>;
        }
      });
    }
  }, [cardDetails._id]);

  const checkMapDoctorValidation = (mappingData: Mappings[]) => {
    if (mappingData.length > 0) {
      for (let i = 0; i <= mappingData.length - 1; i++) {
        if (
          mappingData[i].hqCode == loggedInUserDetails?.hq_code &&
          mappingData[i].source == "cpay" &&
          mappingData[i].brickCode &&
          mappingData[i].status === "active"
        ) {
          return mappingData[i];
        }
      }
    }
    return {} as Mappings;
  };

  return (
    <div style={container}>
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={handleClick}
      >
        <>
          <div style={{ width: "100%" }}>
            {firmNames.length > 0 && (
              <GaTypography
                typographyType={TypographyType.XL_BOLD}
                color={TypographyColor.INHERIT}
              >
                {firmNames[firmNames.length - 1].name}
              </GaTypography>
            )}
          </div>
          {checkMapDoctorValidation(cardDetails.mappings) &&
            checkMapDoctorValidation(cardDetails.mappings).brickName && (
              <GaTypography
                typographyType={TypographyType.SM}
                align={TypographyAlignment.RIGHT}
              >
                {checkMapDoctorValidation(cardDetails.mappings).brickName}
              </GaTypography>
            )}
        </>
      </div>
      <div style={subTitleStyle}>
        <GaTypography typographyType={TypographyType.SM}>
          {mobileContacts[mobileContacts.length - 1]?.value}
        </GaTypography>
      </div>

      <GaAccordion
        expandIcon={<ExpandMore />}
        onChange={() => fetchMappedDoctorsList(cardDetails._id)}
        summary={
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >
            Mapped Doctors
          </GaTypography>
        }
        details={
          <>
            {loading === FetchState.LOADING ? (
              <div>
                <Skeleton animation="wave" width="40%">
                  <CardContent />
                </Skeleton>
                <Skeleton animation="wave" width="20%">
                  <GaTypography children="do" />
                </Skeleton>
              </div>
            ) : (
              mappedDoctors.length > 0 &&
              mappedDoctors.map((details) => {
                return (
                  <div>
                    <BrickDoctorDetails data={details} />
                  </div>
                );
              })
            )}
          </>
        }
      />

      {checkMapDoctorValidation(cardDetails.mappings) &&
        checkMapDoctorValidation(cardDetails.mappings).brickCode && (
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                handleMapping();
              }}
            >
              <GaTypography
                typographyType={TypographyType.SM}
                color={TypographyColor.SECONDARY}
              >
                MAP DOCTOR
              </GaTypography>
            </Button>
          </div>
        )}
    </div>
  );
};

export default ChemistDetailsCard;
