class Utils {
  static getActiveMobile = (
    contacts: Array<any>,
    type: string,
    status: string
  ): string => {
    for (const contact of contacts) {
      if (contact.type === type && contact.status === status) {
        return contact.value;
      }
    }
    return "";
  };
}

export default Utils;
