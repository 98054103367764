import { SaveHospitalQuestionaireData } from "../../../domain/usages/Hospitals/save-hospital-questionaire-data";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteSaveHospitalQuestionaireData implements SaveHospitalQuestionaireData {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async save(id: string, params: SaveHospitalQuestionaireData.Params): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url.replace(":id", id),
      body: params,
      headers: {
        ["ngrok-skip-browser-warning"]: "1",
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
