import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../../../routes";
import Fab from "@mui/material/Fab";
import Add from "@mui/icons-material/Add";
import { GaCard, GaCardVariant, CardType } from "../../ga-components/Card";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import { ChemistData } from "../../../domain/models/chemist/ChemistData";
import ChemistDetailsCard from "./ChemistDetailsCard";
import { FetchMappedDoctors } from "../../../domain/usages/Chemists/fetch-mapped-doctors";
import dataNotFound from "../../../images/customers_not_found.png";
import { useEffect, useState } from "react";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import jwt_decode from "jwt-decode";

type Props = {
  chemistCardDetails: ChemistData[];
  fetchMappedDoctors: FetchMappedDoctors;
};

const ChemistCard: React.FC<Props> = ({
  chemistCardDetails,
  fetchMappedDoctors,
}) => {
  const cardStyle = {
    maxWidth: "100%",
    margin: "30px 20px 20px 20px",
    borderColor: "#94D2BD",
  };

  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>({} as LoggedInUserDetails);

  const navigate = useNavigate();

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginBottom: 70 }}>
        {chemistCardDetails.length > 0 ? (
          chemistCardDetails?.map((details) => {
            return (
              <div style={cardStyle}>
                <GaCard
                  variant={GaCardVariant.OUTLINED}
                  cardType={CardType.APPROVED_CARD}
                >
                  <CardContent>
                    <ChemistDetailsCard
                      cardDetails={details}
                      fetchMappedDoctors={fetchMappedDoctors}
                    />
                  </CardContent>
                </GaCard>
              </div>
            );
          })
        ) : (
          <div style={{ textAlign: "center" }}>
            <img
              src={dataNotFound}
              width={240}
              style={{ marginTop: "3rem", width: "60%" }}
            />

            <GaTypography typographyType={TypographyType.BASE_BOLD}>
              No Chemist Found
            </GaTypography>
          </div>
        )}
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          zIndex: 1,
          width: "100%",
          backgroundColor: "#fff",
        }}
      >
        {loggedInUserDetails.role === "HQ_MNG" && (
          <div style={{ marginLeft: "55%" }}>
            <Fab
              onClick={() => navigate(pageRoutes.chemistRegistration)}
              variant="extended"
              sx={{
                margin: 1,
                bgcolor: "#FF9600",
                boxShadow:
                  "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Add sx={{ mr: 1, color: "#fff" }} />

              <div style={{ color: "#fff" }}>
                <GaTypography typographyType={TypographyType.BASE}>
                  Register
                </GaTypography>
              </div>
            </Fab>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChemistCard;
