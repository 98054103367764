import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { FetchDoctors } from "../../../domain/usages/Doctors/fetch-doctors";
import { pageRoutes } from "../../../routes";
import Header from "../../components/Header/Header";
import DoctorUserDetails from "../../components/UserDetails/DoctoruserDetails";
import InfiniteScroll from "react-infinite-scroll-component";
import { AddDoctorsToHospital } from "../../../domain/usages/Hospitals/add-doctors-to-hospital";
import { HospitalMappedDoctor } from "../../../domain/models/Hospital/hospitalMappedDoctor";
import Utils from "../../../common/Utils";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import dataNotFound from "../../../images/customers_not_found.png";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import jwt_decode from "jwt-decode";

type Props = {
  mapDoctor: AddDoctorsToHospital;
  fetchBrickDoctors: FetchDoctors;
};
const HospitalDoctorMapping: React.FC<Props> = ({
  mapDoctor,
  fetchBrickDoctors,
}) => {
  const [brickDoctorList, setBrickDoctorList] = useState<DoctorData[]>([]);
  const location: any = useLocation();
  const [searchFilter, setSearchFilter] = useState("");
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [brick, setBrick] = useState("");
  const [selectedDoctors, setSelectedDoctors] = useState<any[]>([]);
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>({} as LoggedInUserDetails);

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);

  const filterRecords = (records: any) => {
    return records.filter((record: any) => {
      return (
        record.teamCode === loggedInUserDetails.team_code &&
        record.orgCode === loggedInUserDetails.org_code &&
        record.deptCode === loggedInUserDetails.dept_code
      );
    });
  };

  const fetchBrickDoctorsList = async (
    brickCode: string,
    page: number,
    scrolled: boolean
  ) => {
    const filter = {
      q: searchFilter,
      brickCode: brickCode,
      page: page,
    };
    try {
      if (!scrolled) {
        setFetchState(FetchState.LOADING);
      }
      let result = await fetchBrickDoctors.fetch(filter);
      if (result.success) {
        setCurrentPage(result.data.page);
        setLastPage(result.data.totalPages);
        if (scrolled) {
          setBrickDoctorList((oldData) => [
            ...oldData,
            ...filterRecords(result.data.result),
          ]);
        } else {
          setBrickDoctorList(filterRecords(result.data.result));
          updateSelectedDoctors(filterRecords(result.data.result));
        }
        setFetchState(FetchState.SUCCESS);
      }
    } catch (err) {
      setFetchState(FetchState.ERROR);
    }
  };

  const updateSelectedDoctors = (doctorResult: DoctorData[]) => {
    let doctorList: DoctorData[] = [];
    let mappedDoctors: HospitalMappedDoctor[] = location.state.mappedDoctor;
    doctorResult.forEach((doctor: DoctorData) => {
      mappedDoctors.forEach((mappedDoctor: HospitalMappedDoctor) => {
        if (doctor._id === mappedDoctor.doctor) {
          doctor.selected = true;
        }
      });
      doctorList.push(doctor);
    });
    setBrickDoctorList(doctorList);
  };

  useEffect(() => {
    if (location.state.brickCode) {
      setBrick(location.state.brickCode);
      fetchBrickDoctorsList(location.state.brickCode, 1, false);
    }
  }, [searchFilter]);

  const trailingStyle = {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textAlign: "center",
    color: "info.main",
  };

  const navigate = useNavigate();
  const handleSkip = () => {
    navigate(pageRoutes.customerProfiler, {
      state: {
        value: 2,
      },
    });
  };

  const doctorMapping = async () => {
    const payload = {
      doctors: selectedDoctors,
    };
    if (selectedDoctors.length > 0) {
      let result = await mapDoctor.map(location.state.id, payload);
      if (result.success) {
        navigate(pageRoutes.hospitalProfilePage, {
          state: {
            id: location.state.id,
          },
        });
        Swal.fire("Doctors Mapped Successfully to Hospital", "", "success");
      } else if (result.statusCode && Array.isArray(result.message)) {
        Swal.fire(result.message[0].message, "", "error");
      } else if (result.statusCode && !Array.isArray(result.message)) {
        Swal.fire(result.message, "", "error");
      } else if (!result.success) {
        Swal.fire(result.message, "", "error");
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.target.checked) {
      selectedDoctors.push({
        name: brickDoctorList[index].fullName,
        speciality: brickDoctorList[index].speciality,
        doctor: brickDoctorList[index]._id,
        teamCode: loggedInUserDetails.team_code
          ? loggedInUserDetails.team_code
          : null,
        deptCode: loggedInUserDetails.dept_code,
        orgCode: loggedInUserDetails.org_code,
      });
    } else {
      const newSelectedDoctor = selectedDoctors.filter(
        (item) => item.doctor != brickDoctorList[index]._id
      );
      setSelectedDoctors(newSelectedDoctor);
    }
    const doctorList = brickDoctorList?.map((doctor) => {
      if (e.target.value === doctor._id) {
        doctor.selected = e.target.checked;
      }
      return doctor;
    });

    setBrickDoctorList(doctorList);
  };

  const handleSearch = (e: any) => {
    setSearchFilter(e.target.value);
  };
  const debouncedOnChange = debounce(handleSearch, 2000);

  const hasMoreData = () => {
    return fetchState == FetchState.SUCCESS ? currentPage < lastPage : false;
  };
  return (
    <>
      <Box mt={2}>
        <Header
          title="Map To Hospital Doctors"
          showBackButton={false}
          trailing={
            <Button onClick={handleSkip}>
              <Typography sx={trailingStyle}>Skip</Typography>
            </Button>
          }
        />
        <Box m={3} mt={3}>
          <SearchIcon
            sx={{ position: "absolute", marginTop: 1, marginLeft: 1 }}
          />
          <input
            style={{
              width: "90%",
              padding: 10,
              textIndent: 30,
              borderRadius: "4px",
            }}
            onChange={debouncedOnChange}
            placeholder="Search Doctor..."
          />
          <div id="div" style={{ overflowY: "auto", height: "68vh" }}>
            {fetchState === FetchState.LOADING && (
              <div
                style={{
                  margin: 0,
                  position: "absolute",
                  top: "50%",
                  left: "45%",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {fetchState === FetchState.SUCCESS && (
              <>
                {brickDoctorList.length > 0 ? (
                  <InfiniteScroll
                    scrollableTarget="div"
                    dataLength={brickDoctorList?.length}
                    next={() => {
                      fetchBrickDoctorsList(brick, currentPage + 1, true);
                    }}
                    hasMore={hasMoreData()}
                    loader={<h4>Loading...</h4>}
                  >
                    {brickDoctorList.map((brickDoctor: DoctorData, index) => {
                      return (
                        <>
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: 20,
                            }}
                          >
                            <DoctorUserDetails data={brickDoctor} />

                            <Checkbox
                              value={brickDoctor._id}
                              checked={brickDoctor.selected ? true : false}
                              onChange={(e) => handleChange(e, index)}
                              color="secondary"
                            />
                          </div>
                          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                        </>
                      );
                    })}
                  </InfiniteScroll>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={dataNotFound}
                      style={{ marginTop: "3rem", width: "70%" }}
                    />

                    <GaTypography typographyType={TypographyType.BASE_BOLD}>
                      No Doctors Found
                    </GaTypography>
                  </div>
                )}
              </>
            )}
          </div>
          {brickDoctorList.length > 0 && (
            <div
              style={{
                position: "fixed",
                bottom: 0,
                right: 0,
                left: 0,
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <div style={{ margin: 20 }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  sx={{ color: "#fff" }}
                  onClick={doctorMapping}
                >
                  Map Doctors
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default HospitalDoctorMapping;
