import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { GaAccordion } from "../../ga-components/Accordion";
import { WorkersDetails } from "../../../domain/models/chemist/workersDetails";
import { Button, Divider } from "@mui/material";
import ButtonVariant from "../../ga-components/Button/button-variant";
import { AddWorkerContact } from "../../../domain/usages/Chemists/add-worker-contact";
import AddWorkerContacts from "./AddWorkerContacts";
import Swal from "sweetalert2";
import { ChemistData } from "../../../domain/models/chemist/ChemistData";
import { Contacts } from "../../../domain/models/contacts";
import { WorkerData } from "../../../domain/models/chemist/workerData";

type Props = {
  workersDetails: WorkersDetails;
  addWorkerContact: AddWorkerContact;
  getWorkersDetails: Function;
  loadingIndicator: Function;
  chemistDetails: ChemistData;
};

type AddWorkersForm = {
  mobileNumber: string;
  emailId: string;
};

const ChemistWorkers: React.FC<Props> = ({
  workersDetails,
  addWorkerContact,
  getWorkersDetails,
  loadingIndicator,
  chemistDetails,
}) => {
  let regex = /(^|\s)\S/g;
  const titleStyles = {
    color: "#212121",
    width: "100%",
  };
  const subTitleStyle = {
    color: "#6B6B6B",
    margin: 2,
  };
  const iconStyle = {
    color: "#2EC4B6",
    position: "absolute",
    marginLeft: 1,
  };
  const [openWorkerContactForm, setOpenWorkerContactForm] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState<WorkerData>(
    {} as WorkerData
  );
  const [mobileContacts, setMobileContacts] = useState<Contacts[]>([]);
  const [emailContacts, setEmailContacts] = useState<Contacts[]>([]);

  const addWorkerContacts = async (data: AddWorkersForm, wokerId: string) => {
    let contacts = [];

    if (data.mobileNumber) {
      contacts.push({ type: "mobile", value: data.mobileNumber });
    }
    if (data.emailId) {
      contacts.push({ type: "email", value: data.emailId });
    }

    const payload = {
      contacts: contacts,
    };
    loadingIndicator(true);
    setOpenWorkerContactForm(false);
    let result = await addWorkerContact.add(
      workersDetails.customer,
      wokerId,
      payload
    );
    if (result.success) {
      loadingIndicator(false);
      getWorkersDetails(workersDetails.customer);
      setOpenWorkerContactForm(false);
      Swal.fire("Contact Added Successfully", "", "success");
    } else {
      setOpenWorkerContactForm(false);
      loadingIndicator(false);
      Swal.fire(result.errors[0].message, "", "error");
    }
  };

  const handleCloseWorkerContactForm = () => {
    setOpenWorkerContactForm(false);
  };

  const handleEditContacts = (index: number) => {
    setSelectedWorker(workersDetails.workers[index]);
    setOpenWorkerContactForm(true);
  };
  return (
    <div>
      <GaAccordion
        expandIcon={<ExpandMoreIcon />}
        bgColor={"#F5F5F5"}
        summary={
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >{`${
            workersDetails.workers ? workersDetails.workers?.length : 0
          } Owners(s)/Staff(s)`}</GaTypography>
        }
        details={
          <div style={{ overflow: "auto", height: "200px" }}>
            {workersDetails.workers?.map((data, index) => {
              mobileContacts.length = 0;
              emailContacts.length = 0;
              return (
                <div style={{ fontFamily: "Roboto" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={titleStyles}>
                      <GaTypography typographyType={TypographyType.BASE}>
                        {data.name
                          ?.toLowerCase()
                          .replace(regex, (letter) => letter.toUpperCase())}
                      </GaTypography>
                    </div>
                    <Button
                      onClick={() => handleEditContacts(index)}
                      variant={ButtonVariant.TEXT}
                    >
                      <GaTypography
                        typographyType={TypographyType.SM}
                        color={TypographyColor.SECONDARY}
                      >
                        Add Contact
                      </GaTypography>
                    </Button>
                  </div>

                  <div style={subTitleStyle}>
                    {data.contacts?.map((contact, index) => {
                      if (
                        contact.type == "mobile" &&
                        contact.status == "active"
                      ) {
                        mobileContacts.length = 0;
                        mobileContacts.push(contact);
                        return <div key={index}></div>;
                      } else if (
                        contact.type == "email" &&
                        contact.status == "active"
                      ) {
                        emailContacts.length = 0;
                        emailContacts.push(contact);
                        return <div key={index}></div>;
                      }
                    })}
                    <div>
                      {mobileContacts.length > 0 && (
                        <GaTypography typographyType={TypographyType.XS}>
                          {mobileContacts[mobileContacts.length - 1].value}
                        </GaTypography>
                      )}
                      {emailContacts.length > 0 && (
                        <GaTypography typographyType={TypographyType.XS}>
                          {emailContacts[emailContacts.length - 1].value}
                        </GaTypography>
                      )}
                    </div>
                  </div>

                  <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                </div>
              );
            })}
          </div>
        }
      />

      <AddWorkerContacts
        handleClose={handleCloseWorkerContactForm}
        open={openWorkerContactForm}
        successCallBack={addWorkerContacts}
        selectedWorker={selectedWorker}
      />
    </div>
  );
};

export default ChemistWorkers;
