import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import jwt_decode from "jwt-decode";

type userProps = {
  data: DoctorData;
};

const BrickDoctorDetails: React.FC<userProps> = ({ data }) => {
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>();

  const titleStyles = {
    color: "#212121",
    width: "100%",
  };
  const subTitleStyle = {
    color: "#6B6B6B",
    margin: 2,
  };
  const iconStyle = {
    color: "#2EC4B6",
    position: "absolute",
    marginLeft: 1,
  };

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);
  let regex = /(^|\s)\S/g;
  return (
    <div style={{ fontFamily: "Roboto" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={titleStyles}>
          <GaTypography typographyType={TypographyType.BASE}>
            {data.fullNames[data.fullNames.length - 1].name
              ?.toLowerCase()
              .replace(regex, (letter) => letter.toUpperCase())}
          </GaTypography>
        </div>
        {data.mappings?.map((mappingData, index) => {
          if (
            mappingData.hqCode == loggedInUserDetails?.hq_code &&
            mappingData.brickCode
          ) {
            return (
              <div key={index}>
                <GaTypography typographyType={TypographyType.SM}>
                  {mappingData.brickName}
                </GaTypography>
              </div>
            );
          }
        })}
      </div>

      <div style={subTitleStyle}>
        {data.contacts?.map((contact, index) => {
          if (contact.type == "mobile" && contact.status == "active") {
            return (
              <div key={index}>
                <GaTypography typographyType={TypographyType.XS}>
                  {contact.value}
                </GaTypography>
              </div>
            );
          }
        })}
      </div>

      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
    </div>
  );
};

export default BrickDoctorDetails;
