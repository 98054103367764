import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { GaAccordion } from "../../ga-components/Accordion";
import { UPIDetails } from "../../../domain/models/chemist/UPIDetails";
import { Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type Props = {
  UPIDetails: UPIDetails;
};

const ScannedUPI: React.FC<Props> = ({ UPIDetails }) => {
  const titleStyles = {
    color: "#212121",
    width: "100%",
  };
  const subTitleStyle = {
    color: "#6B6B6B",
    margin: 2,
  };
  const iconStyle = {
    color: "#2EC4B6",
    position: "absolute",
    marginLeft: 1,
  };
  return (
    <div>
      <GaAccordion
        expandIcon={<ExpandMoreIcon />}
        bgColor={"#F5F5F5"}
        summary={
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >{`${
            UPIDetails.vpas ? UPIDetails.vpas?.length : 0
          } Scanned UPI ID(s)`}</GaTypography>
        }
        details={
          <div style={{ overflow: "auto", height: "200px" }}>
            {UPIDetails?.vpas?.map((data) => {
              return (
                <>
                  <div style={{ fontFamily: "Roboto" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={titleStyles}>
                        <GaTypography typographyType={TypographyType.BASE}>
                          {data.name}
                        </GaTypography>
                      </div>
                      <GaTypography
                        typographyType={TypographyType.SM}
                        color={TypographyColor.SECONDARY}
                      >
                        {data.isValid && <CheckCircleIcon color={"success"} />}
                      </GaTypography>
                    </div>

                    <div style={subTitleStyle}>
                      <GaTypography typographyType={TypographyType.XS}>
                        {data.vpa}
                      </GaTypography>
                    </div>

                    <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                  </div>
                </>
              );
            })}
          </div>
        }
      />
    </div>
  );
};

export default ScannedUPI;
