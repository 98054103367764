import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import { HospitalMappedPharmacy } from "../../../domain/models/Hospital/hospitalMappedPharmacy";

type userProps = {
  data: HospitalMappedPharmacy;
};

const PharmacyStaffDetails: React.FC<userProps> = ({ data }) => {
  const titleStyles = {
    color: "#212121",
    width: "100%",
  };
  const subTitleStyle = {
    color: "#6B6B6B",
    margin: 2,
  };

  return (
    <div style={{ fontFamily: "Roboto" }}>
      {data.staffs.length > 0 &&
        data.staffs.map((staff) => {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ display: "flex" }}>
                    <div style={titleStyles}>
                      <GaTypography typographyType={TypographyType.BASE}>
                        {staff.name}
                      </GaTypography>
                    </div>
                    <div style={subTitleStyle}>
                      <GaTypography typographyType={TypographyType.XS_BOLD}>
                        ({staff.type})
                      </GaTypography>
                    </div>
                  </div>
                  <div style={subTitleStyle}>
                    {staff.contacts.map((contact) => {
                      if (contact.type == "mobile") {
                        return (
                          <GaTypography typographyType={TypographyType.XS}>
                            {contact.value}
                          </GaTypography>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>

              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            </div>
          );
        })}
    </div>
  );
};

export default PharmacyStaffDetails;
