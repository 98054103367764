import { AddWorkerContact } from "../../../domain/usages/Chemists/add-worker-contact";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteAddWorkerContact implements AddWorkerContact {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) { }

  async add(
    customerId: string,
    workerId: string,
    params: AddWorkerContact.Params
  ): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url
        .replace(":customerId", customerId)
        .replace(":workerId", workerId),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
