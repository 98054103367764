import HTTPStatusCode from "../../../domain/enums/httpStatusCode";
import { FindWorkersById } from "../../../domain/usages/Chemists/find-workers-by-id";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFindWorkersById implements FindWorkersById {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}
  async find(id: string, params: FindWorkersById.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":id", id),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == HTTPStatusCode.OK) {
      return httpResponse.data;
    }
  }
}
