import {
  Box,
  Button,
  SwipeableDrawer,
  FormControlLabel,
  MenuItem,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Program } from "../../../domain/models/doctor/program";
import styled from "@emotion/styled";
import { Constants } from "../../../common/constants";
import SelectedProgram from "./SelectedProgram";
import { useDoctorStore } from "../../../store/main/DoctorStore";

const style = {
  height: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

type Props = {
  programs: Program[];
  open: boolean;
  handleClose: Function;
  fetchDoctorsList: Function;
};

const DoctorFilterModal: React.FC<Props> = ({
  open,
  handleClose,
  programs,
  fetchDoctorsList,
}) => {
  const { doctorFilter, setDoctorFilter, resetDoctorFilter } = useDoctorStore();

  const [selectedProgram, setSelectedProgram] = useState<string>(
    doctorFilter.selectedProgram
  );
  const [isSampleReady, setIsSampleReady] = useState<boolean>(
    doctorFilter.sampleEligible
  );

  const handleSubmit = () => {
    setDoctorFilter({ selectedProgram, sampleEligible: isSampleReady });
    fetchDoctorsList(1, false, false, {
      selectedProgram,
      sampleEligible: isSampleReady,
    });

    handleClose();
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onOpen={() => {}}
      onClose={() => {
        handleClose();
      }}
    >
      <Box sx={style}>
        <Typography variant="h6" textAlign={"center"}>
          Filter Your Doctor
        </Typography>
        <Stack marginTop={2} gap={2}>
          <TextField
            label={"Select Program"}
            value={selectedProgram}
            onChange={(e) => setSelectedProgram(e.target.value)}
            fullWidth
            select
            size="small"
            sx={{
              backgroundColor: Constants.CHIP_BACKGROUND_COLOR,
            }}
          >
            {programs.length > 0 &&
              programs.map((program) => {
                return (
                  <MenuItem key={program._id} value={program.code}>
                    {program.name}
                  </MenuItem>
                );
              })}
          </TextField>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={"24px"}>Sample Ready?</Typography>
            <Switch
              checked={isSampleReady}
              onChange={(e) => setIsSampleReady((prev) => !prev)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>
          <Stack flexDirection={"row"} gap={2}>
            <Button
              variant="contained"
              fullWidth
              color="error"
              onClick={() => {
                setIsSampleReady(false);
                setSelectedProgram("");
                resetDoctorFilter();
              }}
            >
              <Typography
                color={"white"}
                fontWeight={660}
                textTransform={"none"}
              >
                Reset
              </Typography>
            </Button>
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              onClick={handleSubmit}
            >
              <Typography
                color={"white"}
                fontWeight={660}
                textTransform={"none"}
              >
                Submit
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
};

export default DoctorFilterModal;
