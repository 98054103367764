import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GOOGLE_MAPS_API_KEY } from "../../../base";
import Constants from "../../../Constants";
import useGeoLocation from "../../../domain/hooks/useGeoLocation";
import { ChemistData } from "../../../domain/models/chemist/ChemistData";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import { FetchMappedDoctors } from "../../../domain/usages/Chemists/fetch-mapped-doctors";
import { GetChemistDetails } from "../../../domain/usages/Chemists/get-chemist-details";
import { ConfirmFileUpload } from "../../../domain/usages/confirm-file-upload";
import { FetchGeoAddresses } from "../../../domain/usages/fetch-geo-addresses";
import { GenerateFileUploadUrl } from "../../../domain/usages/generate-file-upload-url";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import { AddMoreImages } from "../../../domain/usages/add-more-image";
import { UploadFile } from "../../../domain/usages/upload-file";
import { pageRoutes } from "../../../routes";
import ChemistDetails from "../../components/Chemist/ChemistDetails";
import MappedDoctors from "../../components/Chemist/MappedDoctors";
import Header from "../../components/Header/Header";
import ImageCards from "../../components/ImageCards";
import AddMoreImagesComponent from "../../components/UploadButton/AddMoreImagesComponent";
import useFileUpload from "../../hooks/useFileUpload";
import { GaIcon, IconColor } from "../../ga-components/Icons";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { AddWorkers } from "../../../domain/usages/Chemists/add-workers";
import AddIcon from "@mui/icons-material/Add";
import AddWorkersForm from "../../components/Chemist/AddWorkersForm";
import ChemistWorkers from "../../components/Chemist/ChemistWorkers";
import ScannedUPI from "../../components/Chemist/ScannedUPI";
import { StoreScannedUPI } from "../../../domain/usages/Chemists/store-scanned-UPI";
import ChemistQRCodeScanner from "../../components/Chemist/ChemistQRCodeScanner";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { FindWorkersById } from "../../../domain/usages/Chemists/find-workers-by-id";
import { FindUPIById } from "../../../domain/usages/Chemists/find-UPI-by-id";
import { WorkersDetails } from "../../../domain/models/chemist/workersDetails";
import { UPIDetails } from "../../../domain/models/chemist/UPIDetails";
import { AddWorkerContact } from "../../../domain/usages/Chemists/add-worker-contact";
import LoadingSkeleton from "../../components/Chemist/LoadingSkeleton";
import { FetchWorkerTypes } from "../../../domain/usages/Chemists/fetch-workers-types";
import { Contacts } from "../../../domain/models/contacts";

type AddWorkerForm = {
  memberType: string;
  name: string;
  mobileNumber: string;
  emailId: string;
};

const useReuploadImage = (
  ReuploadImage: AddMoreImages
): [boolean, Function, any] => {
  const geoLocation = useGeoLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();

  const submit = useCallback(
    async (
      imageType: string,
      file: string,
      id: string,
      handleOpenDialog: Function
    ) => {
      setIsLoading(true);
      const payload = {
        images: [
          {
            type: imageType,
            uuid: file,
            latLong: geoLocation?.latitude + "," + geoLocation?.longitude,
          },
        ],
      };
      let result = await ReuploadImage.reupload(id, payload);
      if (result.success) {
        setResponse(result);
        setIsLoading(false);
        Swal.fire("Image Added successfully.", "", "success");
      } else {
        handleOpenDialog();
        Swal.fire("Image Added Failed.", "", "error");
      }
    },
    [ReuploadImage, geoLocation]
  );

  return [isLoading, submit, response];
};

type Props = {
  getReadLink: GetReadLink;
  getChemistDetails: GetChemistDetails;
  fetchMappedDoctors: FetchMappedDoctors;
  generateUploadURL: GenerateFileUploadUrl;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmFileUpload;
  ReuploadImage: AddMoreImages;
  addWorkers: AddWorkers;
  storeUPI: StoreScannedUPI;
  findWorkersById: FindWorkersById;
  findUPIById: FindUPIById;
  addWorkerContact: AddWorkerContact;
  fetchWorkerTypes: FetchWorkerTypes;
};

const ChemistProfilePage: React.FC<Props> = ({
  getReadLink,
  getChemistDetails,
  fetchMappedDoctors,
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
  ReuploadImage,
  addWorkers,
  storeUPI,
  findWorkersById,
  findUPIById,
  addWorkerContact,
  fetchWorkerTypes,
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [chemistDetails, setChemistDetails] = useState<ChemistData>(
    {} as ChemistData
  );
  const [workersDetails, setWorkersDetails] = useState<WorkersDetails>(
    {} as WorkersDetails
  );
  const [UPIDetails, setUPIDetails] = useState<UPIDetails>({} as UPIDetails);
  const [mappedDoctors, setMappedDoctors] = useState<DoctorData[]>([]);
  const [openDialog, setOpenDialog] = useState(true);
  const [imageType, setImageType] = useState("");
  const [openAddMembersForm, setOpenAddMembersForm] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [mobileContacts, setMobileContacts] = useState<Contacts[]>([]);
  const [emailContacts, setEmailContacts] = useState<Contacts[]>([]);

  const handleCloseAddMembersForm = () => {
    setOpenAddMembersForm(false);
  };

  const handleCloseScanner = () => {
    setOpenScanner(false);
    getDetails(chemistDetails._id);
  };

  const loadingIndicator = (loading: boolean) => {
    if (loading) {
      Swal.fire({
        title: "Response Loading...",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  const getDetails = async (id: string) => {
    if (id) {
      setFetchState(FetchState.LOADING);
      try {
        let result = await getChemistDetails.get(id);
        if (result.success) {
          setChemistDetails(result.data);
          findLatestContacts(result.data.contacts);
          setFetchState(FetchState.SUCCESS);
        }
      } catch (err) {}
    }
  };

  const findLatestContacts = (contacts: Contacts[]) => {
    {
      contacts?.map((contact, index) => {
        if (contact.type == "mobile" && contact.status == "active") {
          mobileContacts.length = 0;
          mobileContacts.push(contact);
          return <div key={index}></div>;
        } else if (contact.type == "email" && contact.status == "active") {
          emailContacts.length = 0;
          emailContacts.push(contact);
          return <div key={index}></div>;
        }
      });
    }
  };

  const getWorkersDetails = async (id: string) => {
    if (id) {
      try {
        let result = await findWorkersById.find(id);
        if (result.success) {
          setWorkersDetails(result.data);
        }
      } catch (err) {}
    }
  };

  const getUPIDetails = async (id: string) => {
    if (id) {
      try {
        let result = await findUPIById.find(id);
        if (result.success) {
          setUPIDetails(result.data);
        }
      } catch (err) {}
    }
  };

  const storeScannedUPI = async (data: any) => {
    const payload = {
      data: data.text.toString(),
    };
    setOpenScanner(false);
    loadingIndicator(true);
    let result = await storeUPI.store(chemistDetails._id, payload);
    if (result.success) {
      loadingIndicator(false);
      setOpenScanner(false);
      Swal.fire("UPI Scanned Successfully", "", "success");
      getDetails(chemistDetails._id);
      getUPIDetails(chemistDetails._id);
    } else {
      loadingIndicator(false);
      setOpenScanner(false);
      Swal.fire(result.errors[0].message, "", "error");
    }
  };

  const fetchMappedDoctorsList = async (id: string) => {
    if (id) {
      let result = await fetchMappedDoctors.fetch(id);
      if (result.success) {
        setMappedDoctors(result.data);
      }
    }
  };

  const [fileUpload, processingFile, processFile, resetFile] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "INVOICE_IMAGE"
  );

  const [submitting, submit, response] = useReuploadImage(ReuploadImage);
  useEffect(() => {
    if (location.state.id) {
      getDetails(location.state.id);
      getWorkersDetails(location.state.id);
      getUPIDetails(location.state.id);
      fetchMappedDoctorsList(location.state.id);
    }
  }, [response]);

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };
  const handleSubmit = () => {
    submit(imageType, fileUpload?.uuid, chemistDetails._id, handleOpenDialog);
  };

  const handleImageType = (event: any) => {
    setImageType(event.target.value as string);
  };

  useEffect(() => {
    setImageType("");
  }, [fileUpload?.uuid]);

  const imageTypeOptions = [
    {
      key: "1",
      value: "banner",
    },
    {
      key: "2",
      value: "invoice",
    },
  ];

  const handleScanner = () => {
    const result = workersDetails.workers.filter(
      (worker) => worker.workerType == "owner"
    );
    if (result.length > 0) {
      setOpenScanner(true);
    } else {
      Swal.fire("Add atleast one owner to enable scan UPI", "", "error");
    }
  };

  const addCustomerWorkers = async (data: AddWorkerForm) => {
    let contacts = [];

    if (data.mobileNumber) {
      contacts.push({ type: "mobile", value: data.mobileNumber });
    }
    if (data.emailId) {
      contacts.push({ type: "email", value: data.emailId });
    }
    const payload = {
      worker: {
        name: data.name,
        workerType: data.memberType,
        contacts: contacts,
      },
    };
    setOpenAddMembersForm(false);
    loadingIndicator(true);
    let result = await addWorkers.add(chemistDetails._id, payload);
    if (result.success) {
      loadingIndicator(false);
      setOpenAddMembersForm(false);
      Swal.fire("Worker Added Successfully", "", "success");
      getDetails(chemistDetails._id);
      getWorkersDetails(chemistDetails._id);
    } else {
      loadingIndicator(false);
      setOpenAddMembersForm(false);
      Swal.fire(result.errors[0].message, "", "error");
    }
  };

  return (
    <div>
      {fetchState === FetchState.LOADING && <LoadingSkeleton />}
      {fetchState === FetchState.SUCCESS && (
        <>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Header
                title={""}
                showBackButton
                handleClick={() =>
                  navigate(pageRoutes.customerProfiler, {
                    state: {
                      value: 1,
                    },
                  })
                }
              />
              {workersDetails.workers?.length > 0 && (
                <div
                  onClick={handleScanner}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: 15,
                    gap: 2,
                    borderRadius: "5px",
                  }}
                >
                  <GaIcon color={IconColor.SECONDARY}>
                    <QrCodeScannerIcon />
                  </GaIcon>
                  <div style={{ marginTop: 5 }}>
                    <GaTypography
                      color={TypographyColor.SECONDARY}
                      typographyType={TypographyType.SM_BOLD}
                    >
                      SCAN UPI
                    </GaTypography>
                  </div>
                </div>
              )}
            </div>

            <div style={{ marginInline: "32px", marginTop: 0 }}>
              <ChemistDetails
                chemistDetails={chemistDetails}
                mobileContacts={mobileContacts}
                emailContacts={emailContacts}
              />
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  flexDirection: "row",
                  overflowX: "auto",
                  height: "150px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12px",
                  }}
                >
                  <AddMoreImagesComponent
                    id={"6"}
                    onFileSelect={(file: File) => processFile(file)}
                    file={fileUpload?.file}
                    reset={() => resetFile()}
                    isLoading={processingFile}
                    submit={handleSubmit}
                    openDialog={openDialog}
                    imageType={imageType}
                    handleImageType={handleImageType}
                    handleOpenDialog={handleOpenDialog}
                    imageTypeOptions={imageTypeOptions}
                  />
                </div>
                {chemistDetails.images?.map((image) => {
                  return (
                    <ImageCards
                      getReadLink={getReadLink}
                      uuid={image.uuid}
                      imageDetails={image}
                    />
                  );
                })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
                marginInline: "16px",
              }}
              onClick={() => setOpenAddMembersForm(true)}
            >
              <GaIcon color={IconColor.SECONDARY}>
                <AddIcon />
              </GaIcon>
              <div style={{ marginTop: 5 }}>
                <GaTypography
                  color={TypographyColor.SECONDARY}
                  typographyType={TypographyType.BASE_BOLD}
                >
                  Add Owner/Staff
                </GaTypography>
              </div>
            </div>
            <div style={{ margin: "16px", marginTop: 0 }}>
              <ChemistWorkers
                workersDetails={workersDetails}
                chemistDetails={chemistDetails}
                addWorkerContact={addWorkerContact}
                getWorkersDetails={getWorkersDetails}
                loadingIndicator={loadingIndicator}
              />
            </div>
            <div style={{ margin: "16px", marginTop: 0 }}>
              <MappedDoctors mappedDoctors={mappedDoctors} />
            </div>
            <div style={{ margin: "16px", marginTop: 0 }}>
              <ScannedUPI UPIDetails={UPIDetails} />
            </div>
          </div>
          {openAddMembersForm && (
            <AddWorkersForm
              handleClose={handleCloseAddMembersForm}
              open={openAddMembersForm}
              successCallBack={addCustomerWorkers}
              fetchWorkerTypes={fetchWorkerTypes}
            />
          )}
          {openScanner && (
            <ChemistQRCodeScanner
              handleClose={handleCloseScanner}
              open={openScanner}
              successCallBack={storeScannedUPI}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ChemistProfilePage;
