import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { Button } from "@mui/material";
import ProgramDetailsModal from "./ProgramDetailsModal";
import { EnrolledProgram } from "../../../domain/models/doctor/enrolled-program";
import moment from "moment";
import Constants from "../../../Constants";

type userProps = {
  data: EnrolledProgram;
};

const ProgramDetails: React.FC<userProps> = ({ data }) => {
  const [openProgramDetailsModal, setOpenProgramDetailsModal] = useState(false);

  const titleStyles = {
    color: "#212121",
    width: "100%",
  };
  const handleCloseProgramDetailsModal = () => {
    setOpenProgramDetailsModal(false);
  };
  const handleOpenProgramDetailsModal = () => {
    setOpenProgramDetailsModal(true);
  };

  return (
    <div style={{ fontFamily: "Roboto" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={titleStyles}>
          <GaTypography typographyType={TypographyType.SM_BOLD}>
            {data.program.name}
          </GaTypography>
          <GaTypography
            typographyType={TypographyType.XS}
            color={TypographyColor.INFO}
          >
            {moment(data.updatedAt.toString()).format(
              Constants.YEAR_MONTH_DATE_FORMAT
            )}
          </GaTypography>
        </div>
        <Button color="secondary" onClick={handleOpenProgramDetailsModal}>
          <GaTypography typographyType={TypographyType.XS_BOLD}>
            Click to View Product Details
          </GaTypography>
        </Button>
      </div>

      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <ProgramDetailsModal
        open={openProgramDetailsModal}
        handleClose={handleCloseProgramDetailsModal}
        selectedProgramProductDetails={data}
      />
    </div>
  );
};

export default ProgramDetails;
