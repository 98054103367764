import { useLocation } from "react-router-dom";
import MobileExistsComponent from "../../components/MobileExistsComponent";

type Props = {};

const MobileExistsPage: React.FC<Props> = ({

}) => {
    const location: any = useLocation();

    return (
        <div>
            <MobileExistsComponent userName={location.state?.name ?? ''} mobileNumber={location.state.mobile ?? ''} />
            
        </div>
    );
};

export default MobileExistsPage;
