import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  CUSTOMER_PROFILER_API_URL,
  DOCTOR_PROFILER_API_URL,
} from "../../base";
import { RemoteFetchChemists } from "../../data/usecases/Chemists/remote-fetch-chemists";
import { RemoteFetchMappedDoctors } from "../../data/usecases/Chemists/remote-fetch-mapped-doctors";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import ChemistsListSummary from "../../presentation/components/Chemist/ChemistsListSummary";

export const ChemistsFetchingFactory = () => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const customerServiceAxiosHttpClient = AxiosHttpClient.getInstance();
  customerServiceAxiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const remoteFetchChemists = new RemoteFetchChemists(
    `${CUSTOMER_PROFILER_API_URL}${Endpoints.FETCH_CUSTOMERS}`,
    customerServiceAxiosHttpClient
  );

  const remoteFetchMappeddoctors = new RemoteFetchMappedDoctors(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.FETCH_MAPPED_DOCTORS}`,
    axiosHttpClient
  );

  return (
    <ChemistsListSummary
      fetchChemists={remoteFetchChemists}
      fetchMappedDoctors={remoteFetchMappeddoctors}
    />
  );
};
