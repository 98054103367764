import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  HOSPITAL_PROFILER_API_URL,
} from "../../base";
import { RemoteFetchHospitalMaster } from "../../data/usecases/Hospitals/remote-fetch-hospital-masters";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import HospitalRegistrationPage from "../../presentation/pages/Hospital/HospitalRegistrationPage";

export const HospitalRegistrationFactory = () => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const remoteFetchHospitalMaster = new RemoteFetchHospitalMaster(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.FETCH_HOSPITAL_MASTER}`,
    axiosHttpClient
  );

  return (
    <HospitalRegistrationPage fetchHospitalMaster={remoteFetchHospitalMaster} />
  );
};
