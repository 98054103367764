import {
  AUTH_HEADER,
  AUTH_HEADER_CHANNELPAY,
  AUTH_TOKEN_KEY,
  DOCTOR_PROFILER_API_URL,
  LOCATION_SERVICE_API_URL,
  LOCATION_SERVICE_TOKEN,
} from "../../base";
import { RemoteGetDoctorDetails } from "../../data/usecases/Doctors/remote-get-doctor-details";
import { RemoteConfirmFileUpload } from "../../data/usecases/remote-confirm-file-upload";
import { RemoteGenerateFileUploadUrl } from "../../data/usecases/remote-generate-file-upload-url";
import { RemoteGetReadLink } from "../../data/usecases/remote-get-read-Link";
import { RemoteAddMoreImages } from "../../data/usecases/remote-add-more-image";
import { RemoteUploadFile } from "../../data/usecases/remote-upload-file";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import DoctorProfilePage from "../../presentation/pages/Doctor/DoctorProfilePage";
import { RemoteEnrollDoctorForProgram } from "../../data/usecases/Doctors/remote-enroll-doctor-for-program-";
import { RemoteGetAllProgramProducts } from "../../data/usecases/Doctors/remote-get-all-program-products";
import { RemoteGetEnrolledPrograms } from "../../data/usecases/Doctors/remote-get-enrolled-programs";
import { RemoteGetAddressByPincode } from "../../data/usecases/remote-get-address-by-pincode";
import { RemoteStoreDoctorAddress } from "../../data/usecases/Doctors/remote-store-doctor-address";
import { RemoteAddDoctorContacts } from "../../data/usecases/Doctors/remote-add-contacts";
import { RemoteConfirmDoctorAddress } from "../../data/usecases/Doctors/remote-confirm-doctor-address";
import { RemoteUpdateDoctorAddress } from "../../data/usecases/Doctors/remote-update-doctor-address";

export const GetDoctorDetailsFactory = () => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const axiosHttpClientMaster = AxiosHttpClient.getInstance();
  axiosHttpClientMaster.setAuthHeaders({
    [AUTH_HEADER]: `${LOCATION_SERVICE_TOKEN}`,
  });

  const cPayaxiosHttpClient = AxiosHttpClient.getInstance();
  cPayaxiosHttpClient.setAuthHeaders({
    [AUTH_HEADER_CHANNELPAY]: token,
  });

  const remoteGetDoctorByID = new RemoteGetDoctorDetails(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.GET_DOCTOR_BY_ID}`,
    axiosHttpClient
  );

  const remoteGetReadLink = new RemoteGetReadLink(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.GET_DOCTOR_READ_IMAGE_URL}`,
    axiosHttpClient
  );

  const remoteGenerateUploadURL = new RemoteGenerateFileUploadUrl(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.GET_DOCTOR_UPLOADED_IMAGE_URL}`,
    axiosHttpClient
  );

  const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);

  const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.CONFIRM_DOCTOR_IMAGE_UPLOAD_TO_BUCKET}`,
    axiosHttpClient
  );

  const remoteAddMoreImage = new RemoteAddMoreImages(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.ADD_DOCTOR_IMAGES}`,
    axiosHttpClient
  );

  const remoteEnrollDoctorForProgram = new RemoteEnrollDoctorForProgram(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.ENROLL_DOCTOR_FOR_PROGRAM}`,
    axiosHttpClient
  );

  const remoteGetAllProgramProducts = new RemoteGetAllProgramProducts(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.GET_ALL_PROGRAM_PRODUCTS}`,
    axiosHttpClient
  );

  const remoteGetEnrolledPrograms = new RemoteGetEnrolledPrograms(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.ENROLL_DOCTOR_FOR_PROGRAM}`,
    axiosHttpClient
  );

  const remoteGetAddressByPincode = new RemoteGetAddressByPincode(
    `${LOCATION_SERVICE_API_URL}${Endpoints.GET_ADDRESS_BY_PINCODE}`,
    axiosHttpClientMaster
  );
  const remoteStoreDoctorAddress = new RemoteStoreDoctorAddress(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.STORE_ADDRESS}`,
    axiosHttpClient
  );
  const remoteAddDoctorContacts = new RemoteAddDoctorContacts(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.ADD_DOCTOR_CONTACTS}`,
    axiosHttpClient
  );
  const remoteConfirmDoctorAddress = new RemoteConfirmDoctorAddress(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.CONFIRM_DOCTOR_ADDRESS}`,
    axiosHttpClient
  );
  const remoteUpdateDoctorAddress = new RemoteUpdateDoctorAddress(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.UPDATE_DOCTOR_ADDRESS}`,
    axiosHttpClient
  );

  return (
    <DoctorProfilePage
      getDoctorDetails={remoteGetDoctorByID}
      getReadLink={remoteGetReadLink}
      generateUploadURL={remoteGenerateUploadURL}
      uploadFile={remoteUploadFile}
      confirmFileUpload={remoteConfirmFileUpload}
      ReuploadImage={remoteAddMoreImage}
      getAllProgramProducts={remoteGetAllProgramProducts}
      getEnrolledPrograms={remoteGetEnrolledPrograms}
      enrollDoctorForProgram={remoteEnrollDoctorForProgram}
      getAddressByPincode={remoteGetAddressByPincode}
      storeAddress={remoteStoreDoctorAddress}
      addContacts={remoteAddDoctorContacts}
      confirmAddress={remoteConfirmDoctorAddress}
      updateAddress={remoteUpdateDoctorAddress}
    />
  );
};
