import { StateCreator } from "zustand";
import {
  AUTH_API_URL,
  AUTH_HEADER,
  AUTH_HEADER_CHANNELPAY,
  AUTH_TOKEN_KEY,
} from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import Endpoints from "../../../domain/endpoints";
import { RemoteUploadFile } from "../../../data/usecases/remote-upload-file";
import { UploadFileType } from "../../../domain/models/UploadFile";

interface UploadFileInterface {
  UploadFileData: UploadFileType;
  uploadFile: Function;
}

const initialStates = {
  UploadFileData: {}as UploadFileType,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const UploadFileSlice: StateCreator<UploadFileInterface> = (
  set,
  get
) => ({
  ...initialStates,
  uploadFile: async (files:File) => {
    const token = storage.get(AUTH_TOKEN_KEY);

    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER_CHANNELPAY]: token });

    const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);

    try {
      const result = await remoteUploadFile.upload({
          file: files, 
          url: ""
      });

      if (result.success) {
        set((state) => ({
          UploadFileData: result
        }));
      } else {
        console.error("Remote fetch failed:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
});