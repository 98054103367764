import * as React from "react";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { GaButtonColor } from "../../ga-components/Button";
import { CheckMobile } from "../../../domain/usages/Chemists/check-mobile";
import { CheckMobileFormDetails } from "../../../domain/models/checkMobileFormDetails";
import { FieldSize, FieldType, GaTextField } from "../../ga-components/Inputs";
import { Stack } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { pageRoutes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { ChemistStore } from "../../../store/main/ChemistStore";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import Header from "../Header/Header";

type Props = {
  checkMobile: CheckMobile;
};

const ChemistMobileRegistration: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const { formData, setFormData, checkMobilenumber } = ChemistStore();
  const { handleSubmit, control, setError, setValue, watch } =
    useForm<CheckMobileFormDetails>({
      mode: "onChange",
    });

  const Imgloader = (x: boolean) => {
    setSubmitting(x);
  };
  const handleNavigation = (value: boolean) => {
    if (value) {
      navigate(pageRoutes.mobileExists, {
        state: {
          name: "GoApptiv Wellness",
          mobile: formData.contacts.value,
        },
      });
    } else {
      navigate(pageRoutes.chemistRegistration, {});
    }
  };
  const onSubmit = (data: CheckMobileFormDetails) => {
    const newformData = { ...formData };
    newformData.contacts.value = data.mobileNumber;
    setFormData(newformData);
    checkMobilenumber(data.mobileNumber, Imgloader, handleNavigation);
  };
  React.useEffect(() => {
    setValue("mobileNumber", formData.contacts.value);
  }, [formData]);

  const regexPattern = /^[6-9]{1}[0-9]{9}$/;

  return (
    <>
      <Stack
        gap={1}
        sx={{
          backgroundImage: "linear-gradient(to bottom, #E4E1F2 , #FFFFFF )",
        }}
      >
        <Header
          title={""}
          showBackButton
          handleClick={() => navigate(pageRoutes.customerProfiler)}
        />
        <Stack gap={1} marginX={4} marginTop={3}>
          <Typography gutterBottom variant="h4" fontWeight={700}>
            Enter customer's phone number
          </Typography>
          <Typography variant="body2" fontWeight={400}>
            Mobile Number
          </Typography>

          <Controller
            name={"mobileNumber"}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <GaTextField
                onChange={onChange}
                value={value}
                placeholder="Enter Mobile Number"
                error={!!error}
                helperText={error?.message}
                inputBoxType={FieldType.SEARCHBOX}
                size={FieldSize.MEDIUM}
                startAdornment={<PhoneOutlinedIcon />}
              />
            )}
            rules={{
              pattern: {
                value: /^[6-9]{1}[0-9]{9}$/,
                message: "Invalid mobile",
              },
              required: { value: true, message: "Field Required" },
            }}
          />
          <Typography variant="caption" color={"GrayText"}>
            By tapping Get started, you agree to the Terms & Conditions
          </Typography>

          <LoadingButton
            size="small"
            disabled={!regexPattern.test(watch("mobileNumber"))}
            endIcon={<ArrowForward />}
            variant="contained"
            loadingPosition="start"
            loading={submitting}
            color={GaButtonColor.PRIMARY}
            sx={{
              position: "absolute",
              bottom: 30,
              right: 10,
              padding: "12px 24px",
              borderRadius: "16px",
              alignSelf: "flex-end",
            }}
            onClick={handleSubmit(onSubmit)}
          >
            <Typography ml={1} color="white" textTransform={"none"}>
              {submitting ? "Checking..." : "Get Started"}
            </Typography>
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
};

export default ChemistMobileRegistration;
