export class Constants {
  static MASTER_MANAGEMENT_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Im9uZWdvYXBwdGl2X21tIn0.eyJ1c2VybmFtZSI6Ik9ORUdPQVBQVElWX0NNIiwiaXNBZG1pbiI6dHJ1ZSwiaWF0IjoxNjY0ODc2Njk2fQ.T8vale6a4qgtRC-5t4d_dK84YDAQRWGy0R4iDMZp46k";

  static MOBILE_REGREX = /^[6-9]{1}[0-9]{9}$/;
  static EMAIL_REGREX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i;
  static CAPITALIZE_REGEX = /(^|\s)\S/g;
  static CHIP_BACKGROUND_COLOR = "#FFF1DD";
  static CHIP_BORDER_COLOR = "#7E7A74";
}
