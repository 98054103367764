import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../../../routes";
import Fab from "@mui/material/Fab";
import Add from "@mui/icons-material/Add";
import { GaCard, GaCardVariant, CardType } from "../../ga-components/Card";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import HospitalDetailsCard from "./HospitalDetailsCard";
import dataNotFound from "../../../images/customers_not_found.png";
import SkeletonCard from "../../ga-components/SkeletonCard";
import { HospitalData } from "../../../domain/models/Hospital/hospitalData";
import { GetHospitalPharmacy } from "../../../domain/usages/Hospitals/get-hospital-pharmacy";
import { useEffect, useState } from "react";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import jwt_decode from "jwt-decode";
import { DeleteMappedPharmacy } from "../../../domain/usages/Hospitals/delete-mapped-pharmacy";
import ConfirmModal from "../ConfirmModal";
import Swal from "sweetalert2";
import { HospitalMappedPharmacy } from "../../../domain/models/Hospital/hospitalMappedPharmacy";
import { FetchState } from "../../../domain/models/fetch-state-type";

type Props = {
  hospitalCardDetails: HospitalData[];
  getHospitalPharmacy: GetHospitalPharmacy;
  deleteMappedPharmacy: DeleteMappedPharmacy;
};

const HospitalCard: React.FC<Props> = ({
  hospitalCardDetails,
  getHospitalPharmacy,
  deleteMappedPharmacy,
}) => {
  const cardStyle = {
    maxWidth: "100%",
    margin: "20px",
    borderColor: "#94D2BD",
  };

  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>({} as LoggedInUserDetails);
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfimModal] = useState(false);
  const [confirmMessage, setConfrimMessage] = useState("");
  const [selectedHospitalData, setSelectedHospitalData] = useState(
    {} as HospitalData
  );
  const [mappedPharmacy, setMappedPharmacy] = useState<HospitalMappedPharmacy>(
    {} as HospitalMappedPharmacy
  );
  const [loading, setLoading] = useState(FetchState.DEFAULT);

  const handleOpenConfirmModal = (
    open: boolean,
    hospitalData: HospitalData,
    hospitalMappedPharmacy: HospitalMappedPharmacy
  ) => {
    setShowConfimModal(open);
    if (
      hospitalMappedPharmacy &&
      hospitalData &&
      hospitalMappedPharmacy._id &&
      hospitalData._id
    ) {
      setConfrimMessage(
        `Are you sure you want to unmap ${hospitalMappedPharmacy.name} from ${
          hospitalData.fullNames[hospitalData.fullNames.length - 1].name
        }`
      );
      setSelectedHospitalData(hospitalData);
    } else {
      setConfrimMessage("");
      setSelectedHospitalData({} as HospitalData);
    }
  };

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);

  const loadingIndicator = (loading: boolean) => {
    if (loading) {
      Swal.fire({
        title: "Please wait... processing request",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  const fetchMappedPharmacy = async (id: string) => {
    if (id) {
      setLoading(FetchState.LOADING);
      let result = await getHospitalPharmacy.get(id);
      if (result) {
        setMappedPharmacy(result.data);
        setLoading(FetchState.SUCCESS);
      } else {
        setMappedPharmacy({} as HospitalMappedPharmacy);
        setLoading(FetchState.SUCCESS);
      }
    }
  };

  const deletePharmacy = async () => {
    handleOpenConfirmModal(
      false,
      {} as HospitalData,
      {} as HospitalMappedPharmacy
    );
    loadingIndicator(true);
    if (selectedHospitalData && selectedHospitalData._id) {
      let result = await deleteMappedPharmacy.delete(selectedHospitalData._id);
      if (result.success) {
        loadingIndicator(false);
        Swal.fire("Mapped Pharmacy Removed Successfully", "", "success");
        fetchMappedPharmacy(selectedHospitalData._id);
      } else {
        loadingIndicator(false);
        if (result.statusCode && Array.isArray(result.message)) {
          Swal.fire(result.message[0].message, "", "error");
        } else if (result.statusCode && !Array.isArray(result.message)) {
          Swal.fire(result.message, "", "error");
        } else if (!result.success) {
          if (result.errors) {
            let errorText = "";
            for (let x in result.errors) {
              errorText += result.errors[x] + "\n";
            }
            Swal.fire(errorText, "", "error");
          } else {
            Swal.fire(result.message, "", "error");
          }
        }
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginBottom: 70 }}>
        {hospitalCardDetails?.length ? (
          hospitalCardDetails?.map((details) => {
            return (
              <div style={cardStyle}>
                {details ? (
                  <>
                    <GaCard
                      variant={GaCardVariant.OUTLINED}
                      cardType={CardType.APPROVED_CARD}
                    >
                      <CardContent>
                        <HospitalDetailsCard
                          cardDetails={details}
                          handleOpenConfirmModal={handleOpenConfirmModal}
                          fetchMappedPharmacy={fetchMappedPharmacy}
                          loading={loading}
                          mappedPharmacy={mappedPharmacy}
                        />
                      </CardContent>
                    </GaCard>
                  </>
                ) : (
                  <SkeletonCard />
                )}
              </div>
            );
          })
        ) : (
          <div style={{ textAlign: "center" }}>
            <img
              src={dataNotFound}
              width={240}
              style={{ marginTop: "3rem", width: "60%" }}
            />

            <GaTypography typographyType={TypographyType.BASE_BOLD}>
              No Hospitals Found
            </GaTypography>
          </div>
        )}
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          zIndex: 1,
          width: "100%",
          backgroundColor: "#fff",
        }}
      >
        {loggedInUserDetails.role === "HQ_MNG" && (
          <div style={{ marginLeft: "55%" }}>
            <Fab
              onClick={() => navigate(pageRoutes.hospitalRegistration)}
              variant="extended"
              sx={{
                margin: 1,
                bgcolor: "#FF9600",
                boxShadow:
                  "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Add sx={{ mr: 1, color: "#fff" }} />

              <div style={{ color: "#fff" }}>
                <GaTypography typographyType={TypographyType.BASE}>
                  Register
                </GaTypography>
              </div>
            </Fab>
          </div>
        )}
      </div>
      {showConfirmModal && (
        <ConfirmModal
          open={showConfirmModal}
          handleClose={() => {
            handleOpenConfirmModal(
              false,
              {} as HospitalData,
              {} as HospitalMappedPharmacy
            );
          }}
          message={confirmMessage}
          successCallback={deletePharmacy}
        />
      )}
    </div>
  );
};

export default HospitalCard;
