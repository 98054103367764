import { Dialog } from "@mui/material";
import { debounce } from "lodash";
import React from "react";
import { QrReader } from "react-qr-reader";
import { GaButton, GaButtonColor } from "../../ga-components/Button";
import { GaTypography, TypographyType } from "../../ga-components/Typography";

type Props = {
  handleClose: Function;
  open: boolean;
  successCallBack: Function;
};

const ChemistQRCodeScanner: React.FC<Props> = ({
  handleClose,
  open,
  successCallBack,
}) => {
  const handleChange = (result: any) => {
    debounce(successCallBack(result), 2000);
  };
  return (
    <Dialog open={open} onClose={() => handleClose()} fullScreen>
      <div style={{ display: "grid", placeContent: "center", marginTop: 40 }}>
        <GaTypography typographyType={TypographyType.LG_BOLD}>
          Scan UPI QR Code
        </GaTypography>
      </div>
      <QrReader
        videoId="chemist"
        containerStyle={{ marginInline: 24 }}
        onResult={(result: any, error: any) => {
          if (result) {
            handleChange(result);
          }
        }}
        scanDelay={1000}
        constraints={{ facingMode: "environment" }}
      />
      <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <div style={{ marginInline: 20, marginBottom: 20 }}>
          <GaButton
            onClick={() => handleClose()}
            color={GaButtonColor.SECONDARY}
            children={
              <GaTypography typographyType={TypographyType.SM_BOLD}>
                DISMISS
              </GaTypography>
            }
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ChemistQRCodeScanner;
