import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { AUTH_API_URL, AUTH_HEADER_CHANNELPAY, AUTH_TOKEN_KEY } from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { RemoteCheckMobile } from "../../../data/usecases/Chemists/remote-check-mobile";
import { CheckMblResponse } from "../../../domain/models/CheckMblResponse";

interface checkMobileInterface {
  checkMobilenumber: Function;
  fetchUploadState: FetchState;
}

const initialStates = {
  fetchUploadState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useCheckMobile: StateCreator<checkMobileInterface> = (set, get) => ({
  ...initialStates,

  checkMobilenumber: async (mobile: string,loader:Function, handleNavigation: Function ) => {
    try {
      set(() => ({ fetchUploadState: FetchState.LOADING }));
      loader(true);

      const token = storage.get(AUTH_TOKEN_KEY);

      axiosHttpClient.setAuthHeaders({ [AUTH_HEADER_CHANNELPAY]:token }); 

      const remoteCheckMobile = new RemoteCheckMobile(
        `${AUTH_API_URL}${Endpoints.CHECK_MOBILE}`,
        
        axiosHttpClient
      );

      const result:CheckMblResponse = await remoteCheckMobile.create({mobile});
      loader(false);

    //   if (result.success) {
    if(result){
      set(() => ({ fetchUploadState: FetchState.SUCCESS }));
      handleNavigation(result.success)
    }
       
      
    //   } else {
    //     Swal.fire("Oops...", "Something went wrong!", "error");
    //     set(() => ({ fetchUploadState: FetchState.ERROR }));
    //   }
      } catch (error) {
        console.error("Error uploading coupon offer:", error);
        Swal.fire("Oops...", "Something went wrong!", "error");
        set(() => ({ fetchUploadState: FetchState.ERROR }));
      }
  },
});
