import React, { useEffect, useState } from "react";
import DoctorTherapyCard from "./DoctorTherapyCard";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { TherapyCompetitor } from "../../../../domain/models/doctor/therapyCompetitor";
import Header from "../../Header/Header";
import { pageRoutes } from "../../../../routes";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchPrescriptions } from "../../../../domain/usages/Doctors/therapy/fetch-prescriptions";
import { FetchAllBrands } from "../../../../domain/usages/Doctors/therapy/fetch-all-brands";
import { SavePrescriptionDetails } from "../../../../domain/usages/Doctors/therapy/save-prescription-details";
import dataNotFound from "../../../../images/customers_not_found.png";
import Swal from "sweetalert2";
import TherapyLoadingSkeleton from "./TherapyLoadingSkeleton";
import { FetchState } from "../../../../domain/models/fetch-state-type";
import { AllBrandsSummary } from "../../../../domain/models/doctor/allBrandsSummary";
import { TherapyDetails } from "../../../../domain/models/doctor/therapyDetails";
import { TherapyBrandDetails } from "../../../../domain/models/doctor/therapyBrandDetails";
import { TherapyDataToSave } from "../../../../domain/models/doctor/therapyDataToSave";
import { TherapyWiseBrands } from "../../../../domain/models/doctor/therapyWiseBrands";
import { Prescription } from "../../../../domain/models/doctor/Prescription";

type Props = {
  fetchPrescriptions: FetchPrescriptions;
  fetchAllBrands: FetchAllBrands;
  savePrescriptionDetails: SavePrescriptionDetails;
};

const DoctorTherapyList: React.FC<Props> = ({
  fetchPrescriptions,
  fetchAllBrands,
  savePrescriptionDetails,
}) => {
  const [prescriptionData, setPrescriptionData] = useState<Prescription[]>([]);
  const [therapywiseBrand, setTherapywiseBrand] = useState<TherapyWiseBrands[]>(
    []
  );
  const [allBrandsSummary, setAllBrandsSummary] = useState<AllBrandsSummary[]>(
    []
  );
  const [fetchState, setFetchState] = useState<FetchState>(FetchState.SUCCESS);
  const [therapies, setTherapies] = useState<TherapyBrandDetails[]>([]);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location: any = useLocation();

  const combineArrays = (
    prescriptions: Prescription[],
    therapyData: TherapyWiseBrands[]
  ) => {
    return therapyData.map((therapy) => {
      return {
        therapyId: therapy.therapyId,
        brands: therapy.brandsIds.map((brand) => {
          const prescription = prescriptions.find(
            (p) =>
              p.therapyId === therapy.therapyId &&
              p.brandId === brand.brand.brandId
          );
          return {
            ...brand,
            units: prescription?.units ? prescription.units : 0,
            competitors: prescription?.competitors
              ? prescription.competitors
              : [],
            competitorMaster: brand.competitors,
          };
        }),
      };
    });
  };

  const finalArray: TherapyDetails[] = combineArrays(
    prescriptionData,
    therapywiseBrand
  );

  const fetchTherapies = async (id: string) => {
    if (id) {
      setFetchState(FetchState.LOADING);
      try {
        let result = await fetchPrescriptions.fetch(id);
        if (result.success) {
          setFetchState(FetchState.SUCCESS);
          setPrescriptionData(result.data.prescriptions);
        }
      } catch (err) {}
    }
  };

  const fetchAllBrandSummary = async () => {
    try {
      let result = await fetchAllBrands.fetch();
      if (result.success) {
        filteredBrandsData(result.data);
        setAllBrandsSummary(result.data);
      }
    } catch (err) {}
  };

  const saveTherapyDetails = async (
    id: string,
    therapyDataToSave: TherapyDataToSave[]
  ) => {
    if (id) {
      setLoading(true);
      let payload = {
        therapies: therapyDataToSave,
      };
      let result = await savePrescriptionDetails.save(id, payload);
      if (result.success) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Details Saved Successfully!",
          timer: 2000,
        });
        setTimeout(
          () => {
            fetchTherapies(id);
            fetchAllBrandSummary();
          },

          2000
        );
      } else if (result.statusCode && Array.isArray(result.message)) {
        setLoading(false);
        Swal.fire(result.message[0].message, "", "error");
      } else if (result.statusCode && !Array.isArray(result.message)) {
        setLoading(false);
        Swal.fire(result.message, "", "error");
      } else if (!result.success) {
        setLoading(false);
        Swal.fire(result.message, "", "error");
      }
    }
  };

  useEffect(() => {
    if (location.state.id) {
      fetchTherapies(location.state.id);
      fetchAllBrandSummary();
    }
  }, []);

  const filteredBrandsData = (BrandsSummary: AllBrandsSummary[]) => {
    const groupBrandBasedonTherapy = BrandsSummary.reduce<TherapyWiseBrands[]>(
      (accumulator, current) => {
        const existingGroupIndex = accumulator.findIndex(
          (p) => p.therapyId === current.brand.therapyId
        );
        if (existingGroupIndex === -1) {
          return [
            ...accumulator,
            { therapyId: current.brand.therapyId, brandsIds: [current] },
          ];
        } else {
          accumulator[existingGroupIndex].brandsIds.push(current);
          return accumulator;
        }
      },
      []
    );
    setTherapywiseBrand(groupBrandBasedonTherapy);
  };

  useEffect(() => {
    therapies.splice(0, therapies.length);
    for (let i = 0; i < finalArray.length; i++) {
      for (let j = 0; j < finalArray[i].brands.length; j++) {
        therapies.push(finalArray[i].brands[j]);
      }
    }
    setTherapies([...therapies]);
  }, [allBrandsSummary]);

  const handleSaveAndContinueButton = (value: boolean) => {
    setDisableSaveButton(value);
  };

  const handleSaveAndContinue = () => {
    const filteredData: TherapyDataToSave[] = therapies.map((item) =>
      Object.assign(
        {},
        {
          therapyId: item.brand.therapyId,
          units: item.units,
          brand: item.brand.name,
          brandId: item.brand.brandId,
          competitors: item.competitors,
        }
      )
    );
    saveTherapyDetails(location.state.id, filteredData);
  };

  const handleCompetitorDetails = (
    value: TherapyCompetitor[],
    brandId: string
  ) => {
    const values = [...therapies];
    const updatedBrandData = values.map((brandDetails) => {
      if (brandDetails.brand.brandId === brandId) {
        return { ...brandDetails, competitors: value };
      } else {
        return brandDetails;
      }
    });

    handleSaveAndContinueButton(false);
    for (let i = 0; i < updatedBrandData.length; i++) {
      for (let j = 0; j < updatedBrandData[i].competitors.length; j++) {
        if (updatedBrandData[i].competitors[j].brandId === "") {
          handleSaveAndContinueButton(true);
          break;
        }
      }
    }
    setTherapies(updatedBrandData);
  };

  const handleBrandDetails = (value: number, brandId: string) => {
    const values = [...therapies];
    const updatedBrandData = values.map((brandDetails) => {
      if (brandDetails.brand.brandId === brandId) {
        return { ...brandDetails, units: value };
      } else {
        return brandDetails;
      }
    });
    setTherapies(updatedBrandData);
  };

  return (
    <Stack>
      <Stack
        top={0}
        position={"fixed"}
        width="100%"
        bgcolor={"white"}
        zIndex={1}
      >
        <Header
          title={"Therapy Preference"}
          showBackButton
          handleClick={() =>
            navigate(pageRoutes.customerProfiler, {
              state: {
                value: 0,
              },
            })
          }
        />
      </Stack>

      <Stack marginTop={8} marginBottom={10} overflow={"scroll"}>
        {fetchState == FetchState.LOADING && <TherapyLoadingSkeleton />}

        {fetchState == FetchState.SUCCESS &&
          finalArray.length > 0 &&
          finalArray.map((data, index) => {
            return (
              <Stack key={index}>
                <DoctorTherapyCard
                  handleCompetitorDetails={handleCompetitorDetails}
                  handleBrandDetails={handleBrandDetails}
                  therapy={data}
                />
              </Stack>
            );
          })}
        {fetchState == FetchState.SUCCESS && allBrandsSummary.length === 0 && (
          <Stack textAlign={"center"} alignItems={"center"}>
            <img
              src={dataNotFound}
              width={240}
              style={{ marginTop: "3rem", width: "60%" }}
            />

            <Typography variant="h6" fontWeight={600}>
              No Therapy Preference Found
            </Typography>
          </Stack>
        )}
      </Stack>
      {fetchState == FetchState.SUCCESS && allBrandsSummary.length > 0 && (
        <Stack
          bottom={0}
          position={"fixed"}
          width="100%"
          padding={2}
          bgcolor={"white"}
          zIndex={1}
        >
          <LoadingButton
            variant="contained"
            color="secondary"
            loading={loading}
            loadingPosition="start"
            disabled={disableSaveButton}
          >
            <Typography
              color={"white"}
              fontWeight={550}
              textAlign={"center"}
              onClick={handleSaveAndContinue}
            >
              {loading ? " Please wait... Saving Details" : "Save and Continue"}
            </Typography>
          </LoadingButton>
        </Stack>
      )}
    </Stack>
  );
};

export default DoctorTherapyList;
