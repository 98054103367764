import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  MobileStepper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";

import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Header from "../Header/Header";
import { pageRoutes } from "../../../routes";
import { ChemistStore } from "../../../store/main/ChemistStore";
import { FieldSize, FieldType, GaTextField } from "../../ga-components/Inputs";
import { Controller, useForm } from "react-hook-form";
import { ChemistFormDetailsData } from "../../../domain/models/ChemistFormDetailsData";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AUTH_API_URL,
  AUTH_HEADER_CHANNELPAY,
  AUTH_TOKEN_KEY,
} from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { RemoteGenerateFileUploadUrl } from "../../../data/usecases/remote-generate-file-upload-url";
import Endpoints from "../../../domain/endpoints";
import { RemoteConfirmFileUpload } from "../../../data/usecases/remote-confirm-file-upload";
import { RemoteUploadFile } from "../../../data/usecases/remote-upload-file";
import useMultiFileUpload from "../../hooks/useMultiFileUpload";
import { GenerateFileUploadUrl } from "../../../domain/usages/generate-file-upload-url";
import { UploadFile } from "../../../domain/usages/upload-file";
import { ConfirmFileUpload } from "../../../domain/usages/confirm-file-upload";
import { AddCircleOutlineOutlined } from "@mui/icons-material";

export const ConfirmRegistrationPage: React.FC = () => {
  const {
    ChemistTypeDetails,
    BricksDetails,
    setFormData,
    formData,
    activeStep,
    handleBack,
    handleNext,
    UploadRegDetails,
  } = ChemistStore();

  const { handleSubmit, control, setError, setValue } =
    useForm<ChemistFormDetailsData>({
      mode: "onChange",
    });
  var navigate = useNavigate();
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();

  // TODO:: replace the hard coded string with the dynamic user token
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER_CHANNELPAY]: token,
  });

  const remoteGenerateUploadURL = new RemoteGenerateFileUploadUrl(
    `${AUTH_API_URL}${Endpoints.GET_UPLOADED_IMAGE_URL}`,
    axiosHttpClient
  );

  const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);

  const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
    `${AUTH_API_URL}${Endpoints.CONFIRM_IMAGE_UPLOAD_TO_BUCKET}`,
    axiosHttpClient
  );
  const onsubmit = (data: ChemistFormDetailsData) => {
    let payload = {
      name: "",
      type: "",
      contacts: {
        value: "",
        type: "MOBILE",
      },

      brick_code: "",
      Images: [""],
    };
    payload.name = data.name;
    payload.type = data.type;
    payload.contacts.value = data.contacts.value;
    payload.contacts.type = "MOBILE";
    payload.brick_code = data.brick_code;
    payload.Images = data.Images;
    console.log(payload.contacts);
    const newFormData = { ...formData };

    newFormData.name = data.name;

    newFormData.type = data.type;

    newFormData.brick_code = data.brick_code;
    newFormData.contacts.value = data.contacts.value;

    setFormData(newFormData);
    UploadRegDetails(payload);
    navigate(pageRoutes.accountCreated);
  };

  useEffect(() => {
    setValue("name", formData.name);
    setValue("type", formData.type);
    setValue("brick_code", formData.brick_code);
    setValue("contacts.value", formData.contacts.value);
  }, []);

  const CustomMobileStepper = withStyles({
    dotActive: {
      width: 50, // Customize the width of the active dot
    },
  })(MobileStepper);

  return (
    <Stack
      sx={{
        height: "100vh",
        backgroundImage: "linear-gradient(to bottom, #E4E1F2 , #FFFFFF )",
      }}
    >
      <Stack>
        <Header
          title={""}
          showBackButton
          handleClick={() => {
            navigate(pageRoutes.DocumentUploadPage, {
              state: {
                value: 1,
              },
            });
            handleBack();
          }}
        />
        <Typography
          variant="caption"
          color={"black"}
          align="center"
          mb={"1rem"}
        >
          Basic Details
        </Typography>
        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={activeStep}
          sx={{
            maxWidth: 400,
            // right:70,
            alignSelf: "center",

            flexGrow: 1,
            bgcolor: "transparent",
            height: "10px",
          }}
          nextButton
          backButton
        />

        <Stack gap={1.5} sx={{ marginX: 2 }}>
          <Typography variant="h4" fontWeight={700}>
            Confirm your details
            <Typography variant="body1">
              You can make changes before submitting.
            </Typography>
          </Typography>

          <Typography variant="body1">
            <strong> Basic Information </strong>
          </Typography>

          <GaTextField
            title="Mobile"
            inputBoxType={FieldType.SEARCHBOX}
            size={FieldSize.MEDIUM}
            isDisabled
            startAdornment={<PhoneOutlinedIcon />}
            value={formData.contacts.value}
            onChange={() => {}}
          />

          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <GaTextField
                title="Firm Name"
                inputBoxType={FieldType.SEARCHBOX}
                size={FieldSize.MEDIUM}
                startAdornment={<CorporateFareOutlinedIcon />}
                value={value}
                onChange={onChange}
                placeholder="Enter your firm name"
              />
            )}
          />

<Controller
            name="type"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                id="Firm-type"
                options={ChemistTypeDetails}
                getOptionLabel={(option) => option.name}
                value={ChemistTypeDetails.find((type) => type.code === value)|| null}
                onChange={(event: any, newValue) => onChange(newValue?.code || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="selec Firm Type"
                    label="Firm Type"
                    error={!!error}
                    required
                    InputProps={{
                      ...params.InputProps,
                      sx: { borderRadius: "20px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <AddBusinessOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {...(!!error && error.message && (
                  <Typography>{error?.message}</Typography>
                ))}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />

          <Controller
            name="brick_code"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                id="brick-name"
                options={BricksDetails}
                getOptionLabel={(option) => option.name}
                value={
                  BricksDetails.find((brick) => brick.code === value) || null
                }
                onChange={(event, newValue) => onChange(newValue?.code || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Brick Name"
                    size="medium"
                    InputProps={{
                      ...params.InputProps,
                      sx: { borderRadius: "20px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <LocationOnOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
          />

          <Typography variant="body1">
            <strong> Form 20 & 21</strong>
          </Typography>

          <MultipleFileUploadComponent
            generateUploadURL={remoteGenerateUploadURL}
            uploadFile={remoteUploadFile}
            confirmFileUpload={remoteConfirmFileUpload}
            documentCode={"DRUG_LICENSE_FORM_20"}
          />

          <Box textAlign={"right"}>
            <Button
              color="primary"
              sx={{ mt: "1rem", borderRadius: "10px" }}
              variant="contained"
              onClick={handleSubmit(onsubmit)}
            >
              Continue <ArrowForwardOutlinedIcon />
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
type FileDisplayComponentProps = {
  file: File;
  isSuccess: boolean;
  index: number;
  handleReuploadChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
};
const FileDisplayComponent: React.FC<FileDisplayComponentProps> = ({
  file,
  isSuccess,
  index,
  handleReuploadChange,
}) => (
  <Box
    sx={{
      p: 2,
      border: "1px dashed grey",
      borderRadius: "8px",
      width: "100%",
      height: "100%",
    }}
  >
    {isSuccess && (
      <>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={(event) => handleReuploadChange(event, index)}
        />
        <label htmlFor="raised-button-file">
          {" "}
          {file.type.startsWith("image/") ? (
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          ) : (
            <Typography>{file.name}</Typography>
          )}
        </label>
      </>
    )}
  </Box>
);

type FilePickerComponentProps = {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const FilePickerComponent: React.FC<FilePickerComponentProps> = ({
  handleFileChange,
}) => {
  return (
    <Box
      sx={{
        p: 2,
        border: "1px dashed grey",
        borderRadius: "8px",
        width: "100%",
        height: "100%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <AddCircleOutlineOutlined />
      </label>
    </Box>
  );
};

type Props = {
  generateUploadURL: GenerateFileUploadUrl;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmFileUpload;
  documentCode: string;
};

const MultipleFileUploadComponent: React.FC<Props> = ({
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
  documentCode,
}) => {
  const [fileUploads, isLoading, uploadFiles, reset, reuploadFile] =
    useMultiFileUpload(
      generateUploadURL,
      uploadFile,
      confirmFileUpload,
      documentCode
    );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);

      uploadFiles(files);
    }
  };

  const handleReuploadChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.files && event.target.files[0]) {
      const newFile = event.target.files[0];
      reuploadFile(newFile, index);
    }
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        overflow: "auto",
        maxHeight: "70vh",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      {fileUploads.map((fileModel, index) => (
        <Grid item xs={6} key={index} sx={{ height: "42vw" }}>
          <FileDisplayComponent
            file={fileModel.file}
            isSuccess={fileModel.success}
            index={index}
            handleReuploadChange={handleReuploadChange}
          />
        </Grid>
      ))}
      <Grid item xs={6} sx={{ height: "42vw" }}>
        <FilePickerComponent handleFileChange={handleFileChange} />
      </Grid>
    </Grid>
  );
};
