import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Utils from "../../../common/Utils";
import { ChemistData } from "../../../domain/models/chemist/ChemistData";
import { MappedChemist } from "../../../domain/models/doctor/mappedChemist";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { FetchChemists } from "../../../domain/usages/Chemists/fetch-chemists";
import { MapChemistsToDoctor } from "../../../domain/usages/Doctors/map-chemists-to-doctor";
import { pageRoutes } from "../../../routes";
import Header from "../../components/Header/Header";
import UserDetails from "../../components/UserDetails/UserDetails";
import IMAGES from "../../../images";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import jwt_decode from "jwt-decode";

type Props = {
  mapChemist: MapChemistsToDoctor;
  fetchBrickChemists: FetchChemists;
};

const ChemistMapping: React.FC<Props> = ({
  mapChemist,
  fetchBrickChemists,
}) => {
  const [brickChemistList, setBrickChemistList] = useState<ChemistData[]>([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [brick, setBrick] = useState("");
  const [lastPage, setLastPage] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>({} as LoggedInUserDetails);

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);

  const trailingStyle = {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textAlign: "center",
    color: "info.main",
  };
  const buttonBox = {
    position: "absolute",
    bottom: 25,
    left: 10,
    right: 10,
  };
  const navigate = useNavigate();
  const location: any = useLocation();
  const handleSkip = () => {
    navigate(pageRoutes.customerProfiler, {
      state: {
        value: 0,
      },
    });
  };

  const filterRecords = (records: any) => {
    return records.filter((record: any) => {
      return (
        record.teamCode === loggedInUserDetails.team_code &&
        record.orgCode === loggedInUserDetails.org_code &&
        record.deptCode === loggedInUserDetails.dept_code
      );
    });
  };
  const fetchBrickChemistsList = async (
    brickCode: string,
    page: number,
    scrolled: boolean
  ) => {
    const filter = {
      q: searchFilter,
      brickCode: brickCode,
      page: page,
    };
    try {
      if (!scrolled) {
        setFetchState(FetchState.LOADING);
      }
      let result = await fetchBrickChemists.fetch(filter);
      if (result.success) {
        setCurrentPage(result.data.page);
        setLastPage(result.data.totalPages);
        if (scrolled) {
          setBrickChemistList((oldData) => [
            ...oldData,
            ...filterRecords(result.data.result),
          ]);
        } else {
          updateSelectedChemists(result.data.result);
          setBrickChemistList(filterRecords(result.data.result));
        }
        setFetchState(FetchState.SUCCESS);
      }
    } catch (err) {
      setFetchState(FetchState.ERROR);
    }
  };

  const updateSelectedChemists = (chemistResult: any) => {
    let chemistList: ChemistData[] = [];
    let mappedChemists: MappedChemist[] = location.state.mappedChemist;
    chemistResult.forEach((chemist: any) => {
      mappedChemists.forEach((mappedChemist: MappedChemist) => {
        if (chemist._id === mappedChemist.uuid) {
          chemist.selected = true;
        }
      });
      chemistList.push(chemist);
    });
    setBrickChemistList(chemistList);
  };

  useEffect(() => {
    if (location.state.brickCode) {
      setBrick(location.state.brickCode);
      fetchBrickChemistsList(location.state.brickCode, 1, false);
    }
  }, [searchFilter, location.state.id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chemistList = brickChemistList?.map((chemist) => {
      if (e.target.value === chemist._id) {
        chemist.selected = e.target.checked;
      }
      return chemist;
    });
    setBrickChemistList(chemistList);
  };

  const chemistMapping = async () => {
    setLoading(true);
    const payload: any = [];
    brickChemistList.forEach((chemist) => {
      if (chemist.selected) {
        payload.push({
          name: chemist.firmNames[0].name,
          mobile: Utils.getActiveMobile(chemist.contacts, "mobile", "active"),
          uuid: chemist._id,
          teamCode: loggedInUserDetails.team_code
            ? loggedInUserDetails.team_code
            : null,
          deptCode: loggedInUserDetails.dept_code,
          orgCode: loggedInUserDetails.org_code,
        });
      }
    });
    let result = await mapChemist.map(location.state.id, payload);
    if (result.success) {
      Swal.fire(result.message, "", "success");
      setLoading(false);
      navigate(pageRoutes.doctorProfilePage, {
        state: {
          id: location.state.id,
        },
      });
    } else {
      Swal.fire(result.message, "", "error");
    }
  };

  const handleSearch = (e: any) => {
    setSearchFilter(e.target.value);
  };
  const hasMoreData = () => {
    return fetchState == FetchState.SUCCESS ? currentPage < lastPage : false;
  };
  const debouncedOnChange = debounce(handleSearch, 2000);
  return (
    <>
      <Box mt={2}>
        <Header
          title="Map To Controlled Chemists"
          showBackButton={false}
          trailing={
            <Button onClick={handleSkip}>
              <Typography sx={trailingStyle}>Skip</Typography>
            </Button>
          }
        />
        <Box m={3} mt={3}>
          <SearchIcon
            sx={{ position: "absolute", marginTop: 1, marginLeft: 1 }}
          />
          <input
            style={{
              width: "90%",
              padding: 10,
              textIndent: 30,
              borderRadius: "4px",
            }}
            onChange={debouncedOnChange}
            placeholder="Search Chemist..."
          />
          <div id="div" style={{ overflowY: "auto", height: "68vh" }}>
            {fetchState === FetchState.LOADING && (
              <div
                style={{
                  margin: 0,
                  position: "absolute",
                  top: "50%",
                  left: "45%",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {fetchState === FetchState.SUCCESS &&
              brickChemistList &&
              brickChemistList.length > 0 && (
                <InfiniteScroll
                  scrollableTarget="div"
                  dataLength={brickChemistList?.length}
                  next={() => {
                    fetchBrickChemistsList(brick, currentPage + 1, true);
                  }}
                  hasMore={hasMoreData()}
                  loader={<h4>Loading...</h4>}
                >
                  {brickChemistList.map((brickChemist, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 20,
                          }}
                        >
                          <UserDetails data={brickChemist} key={index} />
                          <Checkbox
                            value={brickChemist._id}
                            checked={brickChemist.selected}
                            onChange={handleChange}
                            color="secondary"
                          />
                        </div>

                        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                      </>
                    );
                  })}
                </InfiniteScroll>
              )}
            {fetchState === FetchState.SUCCESS &&
              brickChemistList &&
              brickChemistList.length == 0 && (
                <Stack alignItems={"center"}>
                  <img src={IMAGES.noRecordsFound} alt="no records" />
                  <Typography variant="h5"> No Records Found</Typography>
                </Stack>
              )}
          </div>
          {fetchState === FetchState.SUCCESS &&
            brickChemistList &&
            brickChemistList.length > 0 && (
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  left: 0,
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                <div style={{ margin: 20 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    onClick={chemistMapping}
                    sx={{ color: "#fff" }}
                  >
                    Map chemists
                  </Button>
                </div>
              </div>
            )}
        </Box>
      </Box>
    </>
  );
};

export default ChemistMapping;
