import React from "react";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import { DoctorData } from "../../../domain/models/doctor/doctorData";

type userProps = {
  data: DoctorData;
};

const DoctorUserDetails: React.FC<userProps> = ({ data }) => {
  const titleStyles = {
    color: "#212121",
    width: "100%",
  };
  const subTitleStyle = {
    color: "#6B6B6B",
    margin: 2,
  };

  return (
    <div style={{ fontFamily: "Roboto" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={titleStyles}>
          <GaTypography typographyType={TypographyType.BASE}>
            {data.fullName}
          </GaTypography>
        </div>
      </div>
      {data.contacts?.map((contact: any) => {
        if (contact.type == "mobile" && contact.status == "active") {
          return (
            <div style={subTitleStyle}>
              <GaTypography typographyType={TypographyType.XS}>
                {contact.value}
              </GaTypography>
            </div>
          );
        }
      })}
    </div>
  );
};

export default DoctorUserDetails;
