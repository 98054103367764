import React from "react";
import { AuthenticateFactory } from "./main/AuthenticateFactory";
import { ChemistRegistrationFactory } from "./main/Chemists/ChemistRegistrationFactory";
import { DoctorMappingFactory } from "./main/Chemists/DoctorMappingFactory";
import { GetChemistDetailsFactory } from "./main/Chemists/GetChemistDetailsFactory";
import { ChemistMappingFactory } from "./main/Doctors/ChemistMappingFactory";
import { DoctorRegistrationFactory } from "./main/Doctors/DoctorRegistrationFactory";
import { GetDoctorDetailsFactory } from "./main/Doctors/GetDoctorDetailsFactory";
import { HospitalPharmacyMappingFactory } from "./main/Hospital/HospitalPharmacyMappingFactory";
import { HospitalDoctorMappingFactory } from "./main/Hospital/HospitalDoctorMappingFactory";
import ChemistQRCodeScanner from "./presentation/components/Chemist/ChemistQRCodeScanner";
import CustomerProfiler from "./presentation/pages/CustomerProfiler";
import { GetHospitalDetailsFactory } from "./main/Hospital/GetHospitalDetailsFactory";
import { HospitalRegistrationFactory } from "./main/Hospital/HospitalRegistrationFactory";
import { TherapyFactory } from "./main/Doctors/TherapyFactory";
import { HospitalTherapyFactory } from "./main/Hospital/HospitalTherapyFactory";
import { ConfirmRegistrationPage } from "./presentation/components/Chemist/ConfirmDetailsPage";
import { MobileExistsFactory } from "./main/Chemists/MobileExistsFactory";
import { AccountCreatedFactory } from "./main/Chemists/AccountCreatedFactory";
import ChemistMobileRegistration from "./presentation/components/Chemist/CheckMobileNumber";
import DocumentUploadComponent from "./presentation/components/UploadDocumentsComponent";
import UploadShopImage from "./presentation/components/UploadButton/UploadShopImage";


export const pageRoutes = {
  authenticate: "/auth/:authToken",
  doctorProfilePage: "/doctor-profile",
  chemistProfilePage: "/chemist-profile",
  hospitalProfilePage: "/hospital-profile",
  doctorMapping: "/doctor-mapping",
  chemistMapping: "/chemist-mapping",
  hospitalDoctorMapping: "/hospital-doctor-mapping",
  hospitalChemistMapping: "/hospital-chemist-mapping",
  doctorRegistration: "/doctor-registration",
  chemistRegistration: "/chemist-registration",
  hospitalRegistration: "/hospital-registration",
  customerProfiler: "/customer-profiler",
  scanChemistUPIQRCode: "/scan-upi-chemist",
  doctorTherapyPage: "/therapy-details",
  hospitalTherapyPage: "/hospital-therapy-details",

  confirmRegistrationPage: "/confirm-registration",
  DocumentUploadPage:"/document-upload",
  UploadShopImagePage: "/upload-shop-image",
  accountCreated: "/account-created",
  mobileExists: "/mobile-exists",
  checkMobileExists: "/check-mobile-exists",

};

export interface AppRoute {
  path: string;
  name: string;
  component: React.FC;
  children?: AppRoute[];
}

let indexRoutes: AppRoute[] = [
  {
    path: pageRoutes.authenticate,
    name: "Authenticate Page",
    component: AuthenticateFactory,
  },
  {
    path: pageRoutes.chemistProfilePage,
    name: "Chemist Profile Page",
    component: GetChemistDetailsFactory,
  } as AppRoute,
  {
    path: pageRoutes.hospitalProfilePage,
    name: "Hospital Profile Page",
    component: GetHospitalDetailsFactory,
  } as AppRoute,
  {
    path: pageRoutes.doctorProfilePage,
    name: "Doctor Profile Page",
    component: GetDoctorDetailsFactory,
  } as AppRoute,
  {
    path: pageRoutes.chemistRegistration,
    name: "Chemist Registration Page",
    component: ChemistRegistrationFactory,
  } as AppRoute,
  {
    path: pageRoutes.checkMobileExists,
    name: "Mobile Number",
    component:ChemistMobileRegistration,
  } as AppRoute,
  {
    path: pageRoutes.mobileExists,
    name: "Mobile Exists",
    component: MobileExistsFactory,
  } as AppRoute,
  {
    path: pageRoutes.accountCreated,
    name: "Account Created",
    component: AccountCreatedFactory,
  } as AppRoute,
  {
    path: pageRoutes.doctorRegistration,
    name: "Doctor Registration Page",
    component: DoctorRegistrationFactory,
  } as AppRoute,
  {
    path: pageRoutes.hospitalRegistration,
    name: "Hospital Registration Page",
    component: HospitalRegistrationFactory,
  } as AppRoute,
  {
    path: pageRoutes.hospitalChemistMapping,
    name: "chemist mapping",
    component: HospitalPharmacyMappingFactory,
  } as AppRoute,
  {
    path: pageRoutes.hospitalDoctorMapping,
    name: "doctor mapping",
    component: HospitalDoctorMappingFactory,
  } as AppRoute,
  {
    path: pageRoutes.chemistMapping,
    name: "chemist mapping",
    component: ChemistMappingFactory,
  } as AppRoute,
  {
    path: pageRoutes.doctorMapping,
    name: "doctor mapping",
    component: DoctorMappingFactory,
  } as AppRoute,
  {
    path: pageRoutes.customerProfiler,
    name: "customer profiler",
    component: CustomerProfiler,
  } as AppRoute,
  {
    path: pageRoutes.scanChemistUPIQRCode,
    name: "Chemist QR Code scanner",
    component: ChemistQRCodeScanner,
  } as AppRoute,
  {
    path: pageRoutes.doctorTherapyPage,
    name: "Doctor Therapy Page",
    component: TherapyFactory,
  } as AppRoute,
  {
    path: pageRoutes.hospitalTherapyPage,
    name: "Hospital Therapy Page",
    component: HospitalTherapyFactory,
  } as AppRoute,
  {
    path: pageRoutes.confirmRegistrationPage,
    name: "confirm Registration Page",
    component: ConfirmRegistrationPage,
  } as AppRoute,
  {
    path: pageRoutes.DocumentUploadPage,
    name: "Document Upload Page",
    component: DocumentUploadComponent,
  } as AppRoute,
  {
    path: pageRoutes.UploadShopImagePage,
    name: "Upload Shop Image",
    component: UploadShopImage,
  } as AppRoute,
  // 
];

export default indexRoutes;
