import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { GaAccordion } from "../../ga-components/Accordion";
import ProgramDetails from "./ProgramDetails";
import { EnrolledProgram } from "../../../domain/models/doctor/enrolled-program";

type Props = {
  enrolledProgramDetails: EnrolledProgram[];
};

const SelectedProgram: React.FC<Props> = ({ enrolledProgramDetails }) => {
  return (
    <div>
      <GaAccordion
        expandIcon={<ExpandMoreIcon />}
        bgColor={"#F5F5F5"}
        summary={
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >{`Enrolled Programs`}</GaTypography>
        }
        details={
          <div style={{ overflow: "auto", height: "200px" }}>
            {enrolledProgramDetails.length > 0 &&
              enrolledProgramDetails.map((enrolledProgram) => {
                return <ProgramDetails data={enrolledProgram} />;
              })}
          </div>
        }
      />
    </div>
  );
};

export default SelectedProgram;
