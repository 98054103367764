import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ChemistData } from "../../../domain/models/chemist/ChemistData";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { HospitalMappedPharmacy } from "../../../domain/models/Hospital/hospitalMappedPharmacy";
import { FetchChemists } from "../../../domain/usages/Chemists/fetch-chemists";
import { AddPharmacyToHospital } from "../../../domain/usages/Hospitals/add-pharmacy-to-hospital";
import { pageRoutes } from "../../../routes";
import Header from "../../components/Header/Header";
import UserDetails from "../../components/UserDetails/UserDetails";
import Slide, { SlideProps } from "@mui/material/Slide";
import { GetHospitalPharmacy } from "../../../domain/usages/Hospitals/get-hospital-pharmacy";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import dataNotFound from "../../../images/customers_not_found.png";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import { AUTH_TOKEN_KEY } from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import jwt_decode from "jwt-decode";

type Props = {
  mapPharmacy: AddPharmacyToHospital;
  fetchBrickChemists: FetchChemists;
  getHospitalPharmacy: GetHospitalPharmacy;
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

const HospitalPharmacyMapping: React.FC<Props> = ({
  mapPharmacy,
  fetchBrickChemists,
  getHospitalPharmacy,
}) => {
  const [brickPharmacyList, setBrickPharmacyList] = useState<ChemistData[]>([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [brick, setBrick] = useState("");
  const [lastPage, setLastPage] = useState<number>(0);
  const [selectedPharmacyIndex, setSelectedPharmacyIndex] = useState<any>();
  const [pharmacySelected, setPharmacySelected] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [mappedPharmacy, setMappedPharmacy] = useState<HospitalMappedPharmacy>(
    {} as HospitalMappedPharmacy
  );
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>({} as LoggedInUserDetails);

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);

  const filterRecords = (records: any) => {
    return records.filter((record: any) => {
      return (
        record.teamCode === loggedInUserDetails.team_code &&
        record.orgCode === loggedInUserDetails.org_code &&
        record.deptCode === loggedInUserDetails.dept_code
      );
    });
  };

  const trailingStyle = {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textAlign: "center",
    color: "info.main",
  };
  const navigate = useNavigate();
  const location: any = useLocation();
  const handleSkip = () => {
    navigate(pageRoutes.customerProfiler, {
      state: {
        value: 2,
      },
    });
  };

  const fetchBrickChemistsList = async (
    brickCode: string,
    page: number,
    scrolled: boolean,
    mappedData: HospitalMappedPharmacy
  ) => {
    const filter = {
      q: searchFilter,
      brickCode: brickCode,
      page: page,
    };
    try {
      if (!scrolled) {
        setFetchState(FetchState.LOADING);
      }
      let result = await fetchBrickChemists.fetch(filter);
      if (result.success) {
        setCurrentPage(result.data.page);
        setLastPage(result.data.totalPages);
        if (scrolled) {
          setBrickPharmacyList((oldData) => [
            ...oldData,
            ...filterRecords(result.data.result),
          ]);
        } else {
          updateSelectedPharmacy(filterRecords(result.data.result), mappedData);
          setBrickPharmacyList(filterRecords(result.data.result));
        }
        setFetchState(FetchState.SUCCESS);
      }
    } catch (err) {
      setFetchState(FetchState.ERROR);
    }
  };

  const fetchMappedPharmacy = async (id: string) => {
    if (id) {
      let result = await getHospitalPharmacy.get(id);
      if (result) {
        setPharmacySelected(true);
        setMappedPharmacy(result.data);
        fetchBrickChemistsList(location.state.brickCode, 1, false, result.data);
      } else {
        fetchBrickChemistsList(
          location.state.brickCode,
          1,
          false,
          {} as HospitalMappedPharmacy
        );
      }
    }
  };

  const updateSelectedPharmacy = (
    pharamcyResult: ChemistData[],
    mappedData: HospitalMappedPharmacy
  ) => {
    let pharmacyList: ChemistData[] = [];
    pharamcyResult.forEach((pharmacy: ChemistData) => {
      if (pharmacy._id === mappedData.uuid) {
        pharmacy.selected = true;
      }
      pharmacyList.push(pharmacy);
    });
    setBrickPharmacyList(pharmacyList);
  };

  useEffect(() => {
    if (location.state.brickCode) {
      setBrick(location.state.brickCode);
      fetchMappedPharmacy(location.state.id);
    }
  }, [searchFilter, location.state.id]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setPharmacySelected(true);
    setSelectedPharmacyIndex(index);
    const pharmacyList = brickPharmacyList?.map((pharmacy) => {
      if (e.target.value === pharmacy._id) {
        pharmacy.selected = e.target.checked;
      }
      return pharmacy;
    });
    setBrickPharmacyList(pharmacyList);
  };

  const pharmacyMapping = async () => {
    let payload: any = {};
    brickPharmacyList.forEach((pharmacy) => {
      if (pharmacy.selected) {
        payload = {
          name: pharmacy.firmNames[0].name,
          uuid: pharmacy._id,
          teamCode: loggedInUserDetails.team_code
            ? loggedInUserDetails.team_code
            : null,
          deptCode: loggedInUserDetails.dept_code,
          orgCode: loggedInUserDetails.org_code,
        };
      }
    });
    let result = await mapPharmacy.map(location.state.id, payload);
    if (result.success) {
      Swal.fire("Pharmacy Mapped Successfully to Hospital", "", "success");
      navigate(pageRoutes.hospitalProfilePage, {
        state: {
          id: location.state.id,
        },
      });
    } else if (result.statusCode && Array.isArray(result.message)) {
      Swal.fire(result.message[0].message, "", "error");
    } else if (result.statusCode && !Array.isArray(result.message)) {
      Swal.fire(result.message, "", "error");
    } else if (!result.success) {
      Swal.fire(result.message, "", "error");
    }
  };

  const handleChangeAlert = () => {
    setShowAlert(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };
  const handleSearch = (e: any) => {
    setSearchFilter(e.target.value);
  };
  const hasMoreData = () => {
    return fetchState == FetchState.SUCCESS ? currentPage < lastPage : false;
  };
  const debouncedOnChange = debounce(handleSearch, 2000);
  return (
    <>
      <Box mt={2}>
        <Header
          title="Map To Controlled Chemists"
          showBackButton={false}
          trailing={
            <Button onClick={handleSkip}>
              <Typography sx={trailingStyle}>Skip</Typography>
            </Button>
          }
        />
        <Box m={3} mt={3}>
          <SearchIcon
            sx={{ position: "absolute", marginTop: 1, marginLeft: 1 }}
          />
          <input
            style={{
              width: "90%",
              padding: 10,
              textIndent: 30,
              borderRadius: "4px",
            }}
            onChange={debouncedOnChange}
            placeholder="Search Pharmacy..."
          />
          <div id="div" style={{ overflowY: "auto", height: "68vh" }}>
            {fetchState === FetchState.LOADING && (
              <div
                style={{
                  margin: 0,
                  position: "absolute",
                  top: "50%",
                  left: "45%",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {fetchState === FetchState.SUCCESS && (
              <>
                {brickPharmacyList.length > 0 ? (
                  <InfiniteScroll
                    scrollableTarget="div"
                    dataLength={brickPharmacyList?.length}
                    next={() => {
                      fetchBrickChemistsList(
                        brick,
                        currentPage + 1,
                        true,
                        mappedPharmacy
                      );
                    }}
                    hasMore={hasMoreData()}
                    loader={<h4>Loading...</h4>}
                  >
                    {brickPharmacyList.map((brickPharmacy, index) => {
                      return (
                        <>
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: 20,
                            }}
                          >
                            <UserDetails data={brickPharmacy} key={index} />

                            <Checkbox
                              value={brickPharmacy._id}
                              defaultChecked={brickPharmacy.selected}
                              disabled={
                                pharmacySelected &&
                                Object.keys(mappedPharmacy).length != 0
                              }
                              checked={
                                brickPharmacy.selected ||
                                index == selectedPharmacyIndex
                              }
                              onChange={
                                !pharmacySelected &&
                                Object.keys(mappedPharmacy).length === 0
                                  ? (e) => handleChange(e, index)
                                  : handleChangeAlert
                              }
                              color="secondary"
                            />
                          </div>

                          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                        </>
                      );
                    })}
                  </InfiniteScroll>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={dataNotFound}
                      style={{ marginTop: "3rem", width: "70%" }}
                    />

                    <GaTypography typographyType={TypographyType.BASE_BOLD}>
                      No Pharmacy Found
                    </GaTypography>
                  </div>
                )}
              </>
            )}
          </div>
          <Snackbar
            open={showAlert}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              You can select only one pharmacy!
            </Alert>
          </Snackbar>
          {Object.keys(mappedPharmacy).length == 0 &&
            brickPharmacyList.length > 0 && (
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  left: 0,
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                <div style={{ margin: 20 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    onClick={pharmacyMapping}
                    sx={{ color: "#fff" }}
                  >
                    Map Pharmacy
                  </Button>
                </div>
              </div>
            )}
        </Box>
      </Box>
    </>
  );
};

export default HospitalPharmacyMapping;
