import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  HOSPITAL_PROFILER_API_URL,
} from "../../base";
import { RemoteGetReadLink } from "../../data/usecases/remote-get-read-Link";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { RemoteGetHospital } from "../../data/usecases/Hospitals/remote-get-hospital";
import HospitalProfilePage from "../../presentation/pages/Hospital/HospitalProfilePage";
import { RemoteGetHospitalPharmacy } from "../../data/usecases/Hospitals/remote-get-hospital-pharmacy";
import { RemoteAddStaffToPharmacy } from "../../data/usecases/Hospitals/remote-add-staff-to-pharmacy";
import { RemoteFetchHospitalMaster } from "../../data/usecases/Hospitals/remote-fetch-hospital-masters";
import { RemoteGenerateFileUploadUrl } from "../../data/usecases/remote-generate-file-upload-url";
import { RemoteUploadFile } from "../../data/usecases/remote-upload-file";
import { RemoteConfirmFileUpload } from "../../data/usecases/remote-confirm-file-upload";
import { RemoteAddMoreImages } from "../../data/usecases/remote-add-more-image";
import { RemoteSaveHospitalQuestionaireData } from "../../data/usecases/Hospitals/remote-save-hospital-questionaire-data";
import { RemoteGetHospitalQuestionaireData } from "../../data/usecases/Hospitals/remote-get-hospital-questionaire-data";
import { RemoteDeleteMappedPharmacy } from "../../data/usecases/Hospitals/remote-delete-mapped-pharmacy";

export const GetHospitalDetailsFactory = () => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const remoteGetHospital = new RemoteGetHospital(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.GET_HOSPITAL_BY_ID}`,
    axiosHttpClient
  );

  const remoteGetHospitalPharmacy = new RemoteGetHospitalPharmacy(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.GET_HOSPITAL_PHARMACY}`,
    axiosHttpClient
  );

  const remoteGetReadLink = new RemoteGetReadLink(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.GET_DOCTOR_READ_IMAGE_URL}`,
    axiosHttpClient
  );

  const remoteAddStaffToPharmacy = new RemoteAddStaffToPharmacy(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.ADD_STAFF_TO_PHARMACY}`,
    axiosHttpClient
  );

  const remoteFetchHospitalMaster = new RemoteFetchHospitalMaster(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.FETCH_HOSPITAL_MASTER}`,
    axiosHttpClient
  );
  const remoteGenerateUploadURL = new RemoteGenerateFileUploadUrl(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.GET_DOCTOR_UPLOADED_IMAGE_URL}`,
    axiosHttpClient
  );

  const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);

  const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.CONFIRM_DOCTOR_IMAGE_UPLOAD_TO_BUCKET}`,
    axiosHttpClient
  );
  const remoteAddMoreImage = new RemoteAddMoreImages(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.ADD_HOSPITAL_IMAGES}`,
    axiosHttpClient
  );

  const remoteSaveHospitalQuestionaireData =
    new RemoteSaveHospitalQuestionaireData(
      `${HOSPITAL_PROFILER_API_URL}${Endpoints.HOSPITAL_QUESTIONAIRE}`,
      axiosHttpClient
    );
  const remoteGetHospitalQuestionaireData =
    new RemoteGetHospitalQuestionaireData(
      `${HOSPITAL_PROFILER_API_URL}${Endpoints.HOSPITAL_QUESTIONAIRE}`,
      axiosHttpClient
    );
  const remoteDeleteMappedPharmacy = new RemoteDeleteMappedPharmacy(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.DELETE_MAPPED_PHARMACY}`,
    axiosHttpClient
  );
  return (
    <HospitalProfilePage
      getHospitalPharmacy={remoteGetHospitalPharmacy}
      getHospital={remoteGetHospital}
      getReadLink={remoteGetReadLink}
      addStaffToPharmacy={remoteAddStaffToPharmacy}
      fetchHospitalMaster={remoteFetchHospitalMaster}
      ReuploadImage={remoteAddMoreImage}
      generateUploadURL={remoteGenerateUploadURL}
      uploadFile={remoteUploadFile}
      confirmFileUpload={remoteConfirmFileUpload}
      saveHospitalQuestionaireData={remoteSaveHospitalQuestionaireData}
      getHospitalQuestionaireData={remoteGetHospitalQuestionaireData}
      deleteMappedPharmacy={remoteDeleteMappedPharmacy}
    />
  );
};
