import { SavePrescriptionDetails } from "../../../../domain/usages/Doctors/therapy/save-prescription-details";
import { HttpConstants } from "../../../protocols/http/http-constants";
import { HttpPostClient } from "../../../protocols/http/http-post-client";

export class RemoteSavePrescriptionDetails implements SavePrescriptionDetails {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async save(id: string, params: SavePrescriptionDetails.Params): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url.replace(":id", id),
      body: params,
      headers: {
        ["ngrok-skip-browser-warning"]: "1",
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
