import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { GaButtonColor } from "../ga-components/Button";
import { useNavigate } from "react-router-dom";
import mobileExists from "../../images/mobile_exists.png";
import { Stack } from "@mui/material";
import { pageRoutes } from "../../routes";

type MobileExistsComponentProps = {
  userName: string;
  mobileNumber: string;
};

const MobileExistsComponent: React.FC<MobileExistsComponentProps> = ({
  userName,
  mobileNumber,
}) => {
  var navigate = useNavigate();
  const backToCheckMobileNumber = () => {
    navigate(pageRoutes.checkMobileExists, {});
  };
  return (
    <Stack marginX={2} >
      <Stack width={"100%"} justifyContent={"center"}>
        <img
          style={{
            width: "70%",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          src={mobileExists}
          alt="Loading"
        />
      </Stack>
<Stack gap={2}>
      <Typography variant="h6" fontWeight={400}>
        <b>{mobileNumber}</b> is already registered with us.
      </Typography>

      <Typography variant="body1" fontWeight={400} textAlign={"left"}>
        You can contact support, or
        <Typography fontWeight={700}> use another phone number</Typography>
        <Typography fontWeight={400}> to proceed with the onboarding.</Typography>

      </Typography>
      </Stack>

      <Stack
        gap={2}
        position={"absolute"}
        bottom= {30}
        left= {70}
        padding={ "12px 24px"}
        alignSelf= {"flex-end"}
        
      >
        <LoadingButton
          size="medium"
          variant="contained"
          loadingPosition="start"
          loading={false}
          sx={{borderRadius:"16px"}}
          color={GaButtonColor.PRIMARY}
          onClick={() => {
            navigate(-1);
          }}
        >
          <Typography color="white" textTransform={"none"}>
            {"Contact Support"}
          </Typography>
        </LoadingButton>

        <Typography
          variant="body1"
          fontWeight={400}
          align="center"
          onClick={backToCheckMobileNumber}
        >
          Use another phone number
        </Typography>
      </Stack>
    </Stack>
  );
};

export default MobileExistsComponent;
