import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { WorkerData } from "../../../domain/models/chemist/workerData";
import ButtonColor from "../../ga-components/Button/button-color";
import { GaTextField } from "../../ga-components/Inputs";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";

type Props = {
  handleClose: Function;
  open: boolean;
  successCallBack: Function;
  selectedWorker: WorkerData;
};

type AddWorkersForm = {
  mobileNumber: string;
  emailId: string;
};

const AddWorkerContacts: React.FC<Props> = ({
  handleClose,
  open,
  successCallBack,
  selectedWorker,
}) => {
  const { handleSubmit, control, setError, setValue } = useForm<AddWorkersForm>(
    {
      mode: "onChange",
    }
  );

  const onSubmit = (data: AddWorkersForm) => {
    successCallBack(data, selectedWorker._id);
  };
  return (
    <Dialog open={open} onClose={() => handleClose}>
      <div style={{ marginTop: 10, textAlign: "center" }}>
        <GaTypography
          color={TypographyColor.SECONDARY}
          typographyType={TypographyType.BASE_BOLD}
        >
          Add Member
        </GaTypography>
      </div>

      <DialogContent sx={{ display: "grid", gap: 2 }}>
        <GaTypography typographyType={TypographyType.BASE_BOLD}>
          {selectedWorker.name} - {selectedWorker.workerType}
        </GaTypography>
        <Controller
          name="mobileNumber"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <GaTextField
              onChange={onChange}
              value={value}
              title="Mobile Number"
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{
            pattern: {
              value: /^[6-9]{1}[0-9]{9}$/,
              message: "Invalid mobile",
            },
          }}
        />
        <Controller
          name="emailId"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <GaTextField
              onChange={onChange}
              value={value}
              title="Email ID"
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email",
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => handleClose()}
          color={ButtonColor.SECONDARY}
        >
          {" "}
          Cancel
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          color={ButtonColor.SECONDARY}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWorkerContacts;
