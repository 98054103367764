import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { Divider } from "@mui/material";
import { HospitalData } from "../../../domain/models/Hospital/hospitalData";

type Props = {
  hospitalDetails: HospitalData;
};
let regex = /(^|\s)\S/g;

const HospitalDetails: React.FC<Props> = ({ hospitalDetails }) => {
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: 3 }}>
        <GaTypography typographyType={TypographyType.LG_BOLD}>
          {hospitalDetails.fullNames[hospitalDetails.fullNames.length - 1].name
            ?.toLowerCase()
            .replace(regex, (letter: any) => letter.toUpperCase())}
        </GaTypography>

        <GaTypography typographyType={TypographyType.LG_BOLD}>
          ({hospitalDetails.hospitalType})
        </GaTypography>
      </div>

      {hospitalDetails.contacts?.map((contact: any) => {
        if (contact.type == "mobile" || contact.type == "landline") {
          return (
            <GaTypography
              typographyType={TypographyType.SM}
              color={TypographyColor.PRIMARY}
            >
              {contact.value}
            </GaTypography>
          );
        }
      })}
      {hospitalDetails.noOfPatients && (
        <GaTypography
          typographyType={TypographyType.SM_BOLD}
          color={TypographyColor.INFO}
        >
          No of Patients Per Day: {hospitalDetails.noOfPatients}
        </GaTypography>
      )}

      {hospitalDetails.noOfBeds && (
        <GaTypography
          typographyType={TypographyType.SM_BOLD}
          color={TypographyColor.INFO}
        >
          No of Beds: {hospitalDetails.noOfBeds}
        </GaTypography>
      )}
      {hospitalDetails.potentialPerMonth && (
        <GaTypography
          typographyType={TypographyType.SM_BOLD}
          color={TypographyColor.INFO}
        >
          Potential Per Month: {hospitalDetails.potentialPerMonth}
        </GaTypography>
      )}
      <div style={{ marginBottom: "6px", marginTop: "22px" }}>
        <GaTypography typographyType={TypographyType.SM}>
          Image Uploaded :
        </GaTypography>
      </div>
      <Divider />
    </div>
  );
};

export default HospitalDetails;
