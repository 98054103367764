import { useEffect, useState } from "react";
import { ChemistData } from "../../../domain/models/chemist/ChemistData";
import { FetchChemists } from "../../../domain/usages/Chemists/fetch-chemists";
import ChemistCard from "./ChemistCard";
import ChemistSummary from "./ChemistSummary";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "@mui/material";
import { FetchMappedDoctors } from "../../../domain/usages/Chemists/fetch-mapped-doctors";
import { FetchState } from "../../../domain/models/fetch-state-type";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonCard from "../../ga-components/SkeletonCard";

type Props = {
  fetchChemists: FetchChemists;
  fetchMappedDoctors: FetchMappedDoctors;
};

const ChemistsListSummary: React.FC<Props> = ({
  fetchChemists,
  fetchMappedDoctors,
}) => {
  const [chemistList, setChemistList] = useState<ChemistData[]>([]);
  const [totalChemist, setTotalChemist] = useState<number>(0);
  const [searchFilter, setSearchFilter] = useState("");
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);

  const handleSearch = (e: any) => {
    setSearchFilter(e.target.value);
  };
  const debouncedOnChange = debounce(handleSearch, 2000);

  const fetchChemistsList = async (page: number, scrolled: boolean) => {
    const filter = {
      q: searchFilter,
      page: page,
      limit: 20,
      types: ["retailer"],
    };
    try {
      if (!scrolled) {
        setFetchState(FetchState.LOADING);
      }
      let result = await fetchChemists.fetch(filter);
      if (result.success) {
        setCurrentPage(result.data.page);
        setLastPage(result.data.totalPages);
        setTotalChemist(result.data.total);
        if (scrolled) {
          setChemistList((oldData) => [...oldData, ...result.data.result]);
        } else {
          setChemistList(result.data.result);
        }
        setFetchState(FetchState.SUCCESS);
      }
    } catch (err) {
      setFetchState(FetchState.ERROR);
    }
  };

  const cardStyle = {
    width: "20rem",
    margin: "30px 20px 20px 20px",
    borderColor: "#94D2BD",
  };
  useEffect(() => {
    fetchChemistsList(1, false);
  }, [searchFilter]);

  const hasMoreData = () => {
    return fetchState == FetchState.SUCCESS ? currentPage < lastPage : false;
  };
  return (
    <>
      <div style={{ margin: 20 }}>
        <SearchIcon
          sx={{ position: "absolute", marginTop: 1, marginLeft: 1 }}
        />
        <input
          style={{
            width: "90%",
            padding: 10,
            textIndent: 30,
            borderRadius: "4px",
          }}
          onChange={debouncedOnChange}
          placeholder="Search Chemists..."
        />
      </div>
      <ChemistSummary count={totalChemist} />
      <div
        id="div"
        style={{
          overflowY: "auto",
          height: "60%",
          position: "fixed",
          width: "100%",
        }}
      >
        <InfiniteScroll
          scrollableTarget="div"
          dataLength={chemistList?.length}
          next={() => {
            fetchChemistsList(currentPage + 1, true);
          }}
          hasMore={hasMoreData()}
          loader={<h4>Loading...</h4>}
        >
          {fetchState === FetchState.LOADING ? (
            <div>
              <div style={cardStyle}>
                <SkeletonCard />
              </div>
              <div style={cardStyle}>
                <SkeletonCard />
              </div>
              <div style={cardStyle}>
                <SkeletonCard />
              </div>
            </div>
          ) : (
            <ChemistCard
              chemistCardDetails={chemistList}
              fetchMappedDoctors={fetchMappedDoctors}
            />
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default ChemistsListSummary;
