import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GOOGLE_MAPS_API_KEY } from "../../../base";
import Constants from "../../../Constants";
import useGeoLocation from "../../../domain/hooks/useGeoLocation";
import { Qualification } from "../../../domain/models/doctor/qualification";
import { Speciality } from "../../../domain/models/doctor/speciality";
import { DoctorFormDetails } from "../../../domain/models/doctorFormDetails";
import { Option } from "../../../domain/models/option";
import { ConfirmFileUpload } from "../../../domain/usages/confirm-file-upload";
import { RegisterDoctor } from "../../../domain/usages/Doctors/register-doctor";
import { FetchBricks } from "../../../domain/usages/fetch-bricks";
import { FetchGeoAddresses } from "../../../domain/usages/fetch-geo-addresses";
import { GenerateFileUploadUrl } from "../../../domain/usages/generate-file-upload-url";
import { UploadFile } from "../../../domain/usages/upload-file";
import { pageRoutes } from "../../../routes";
import { GaButton, GaButtonColor } from "../../ga-components/Button";
import {
  FieldVariant,
  GaMultiSelectField,
  GaSelectField,
  GaTextField,
  OutputValue,
} from "../../ga-components/Inputs";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import useBricks from "../../hooks/useBricks";
import useFileUpload from "../../hooks/useFileUpload";
import UploadFileCard from "../UploadButton/UploadFileCard";
import { GetAddressByPincode } from "../../../domain/usages/get-address-by-pincode";

const useGeoAddress = (
  fetchGeoAddresses: FetchGeoAddresses,
  geoLocation?: GeolocationCoordinates
): [any] => {
  const [address, setAddress] = useState<any>();

  const fetchAddress = useCallback(async () => {
    let result = await fetchGeoAddresses.fetch({
      latLong: geoLocation?.latitude + "," + geoLocation?.longitude,
    });
    if (result.success) {
      setAddress(Constants.geoAddressToAddress(result.data));
    }
  }, [fetchGeoAddresses, geoLocation]);

  useEffect(() => {
    if (geoLocation && geoLocation.latitude) {
      fetchAddress();
    }
  }, [geoLocation]);

  return [address];
};

const useRegisterDoctor = (
  register: RegisterDoctor,
  fetchGeoAddresses: FetchGeoAddresses
): [boolean, Function, any] => {
  const geoLocation = useGeoLocation();
  const [address] = useGeoAddress(fetchGeoAddresses, geoLocation);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();

  const submit = useCallback(
    async (data: DoctorFormDetails) => {
      setIsLoading(true);
      let geoAddress = address;
      if (!geoAddress) {
        geoAddress = Constants.geoAddressToAddress({
          geometry: {
            location: {
              lat: geoLocation?.latitude,
              lng: geoLocation?.longitude,
            },
          },
        });
      }

      const payload = {
        fullName: data.name,
        contacts: [
          {
            status: "active",
            type: "mobile",
            value: data.mobileNumber,
            source: "channelpay",
          },
        ],
        speciality: data.speciality,
        mclNumber: data.mclNumber ? data.mclNumber : "",
        noOfPatients: data.noOfPatients,
        qualifications: data.qualification, // TODO:: rename it to qualification
        mappings: {
          brickCode: data.brick.short_code,
          brickName: data.brick.name,
        },
        images: [
          {
            type: "visiting_card",
            uuid: data.uuid,
            ...geoAddress,
          },
        ],
        addresses: [
          {
            source: "profiling",
            type: "primary",
            latLong: geoLocation?.latitude + "," + geoLocation?.longitude,
            line: data.line,
            landmark: data.landmark,
            area: data.area,
            pincode: parseInt(data.pincode),
            state: [data.state],
            district: data.district,
            country: [data.country],
          },
        ],
      };
      let result = await register.create(payload);
      setResponse(result);
      setIsLoading(false);
      if (result.statusCode && Array.isArray(result.message)) {
        Swal.fire(result.message[0].message, "", "error");
      } else if (result.statusCode && !Array.isArray(result.message)) {
        Swal.fire(result.message, "", "error");
      } else if (!result.success) {
        if (result.errors) {
          let errorText = "";
          for (let x in result.errors) {
            errorText += result.errors[x] + "\n";
          }
          Swal.fire(errorText, "", "error");
        } else {
          Swal.fire(result.message, "", "error");
        }
      }
    },
    [register, address]
  );

  return [isLoading, submit, response];
};

type Props = {
  generateUploadURL: GenerateFileUploadUrl;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmFileUpload;
  specialities: Speciality[];
  qualifications: Qualification[];
  fetchBricks: FetchBricks;
  registerDoctor: RegisterDoctor;
  fetchGeoAddresses: FetchGeoAddresses;
  getAddressByPincode: GetAddressByPincode;
};

const DoctorRegistrationForm: React.FC<Props> = ({
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
  specialities,
  qualifications,
  fetchBricks,
  registerDoctor,
  fetchGeoAddresses,
  getAddressByPincode,
}) => {
  const navigate = useNavigate();
  const bricks = useBricks(fetchBricks);
  const [submitting, submit, response] = useRegisterDoctor(
    registerDoctor,
    fetchGeoAddresses
  );
  const [fileUpload, processingFile, processFile, resetFile] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload
  );
  const {
    handleSubmit,
    control,
    setError,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm<DoctorFormDetails>({
    mode: "onChange",
  });

  const patientCount = [
    {
      key: 1,
      value: "Above 50",
    },
    {
      key: 2,
      value: "30-50",
    },
    {
      key: 3,
      value: "20-30",
    },
    {
      key: 4,
      value: "Below 20",
    },
  ];

  useEffect(() => {
    if (fileUpload && fileUpload.uuid) {
      setValue("uuid", fileUpload.uuid);
    }
  }, [fileUpload]);

  const onSubmit = (data: DoctorFormDetails) => {
    submit(data);
  };

  const getAddress = async (pincodeValue: string) => {
    let result = await getAddressByPincode.get({ pincode: pincodeValue });
    if (result.success) {
      setValue("state", result.data[0].state);
      setValue("district", result.data[0].district);
      setValue("country", result.data[0].country);
    } else {
      Swal.fire("Invalid Pincode", "", "error");
    }
  };
  let regexPincode = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

  useEffect(() => {
    if (response && response.success) {
      Swal.fire("Doctor registered successfully.", "", "success");
      navigate(pageRoutes.doctorProfilePage, {
        state: {
          id: response.data._id,
        },
      });
    }
  }, [response]);

  return (
    <div style={{ display: "grid", gap: "17px" }}>
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <GaTextField
            onChange={onChange}
            value={value}
            title="Name *"
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{ required: { value: true, message: "Required" } }}
      />

      <Controller
        name="mobileNumber"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            value={value}
            label="Mobile Number*"
            error={!!error}
            helperText={error?.message}
            type="number"
          />
        )}
        rules={{
          pattern: {
            value: /^[6-9]{1}[0-9]{9}$/,
            message: "Invalid mobile",
          },
          required: { value: true, message: "Field Required" },
        }}
      />

      <Controller
        name="speciality"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <GaSelectField
            options={specialities.map(
              (s) => ({ key: s._id, value: s.name } as Option)
            )}
            value={value}
            onChange={onChange}
            outputValue={OutputValue.VALUE}
            variant={FieldVariant.OUTLINED}
            title="Speciality *"
            error={!!error}
            helperText={error?.message}
          ></GaSelectField>
        )}
        rules={{ required: { value: true, message: "Field Required" } }}
      />
      <Controller
        name="qualification"
        control={control}
        render={({
          field: { onChange, value = [] },
          fieldState: { error },
        }) => (
          <GaMultiSelectField
            options={qualifications.map(
              (s) => ({ key: s.name, value: s.name } as Option)
            )}
            value={value}
            onChange={onChange}
            outputValue={OutputValue.VALUE}
            label="Qualification *"
            error={!!error}
            helperText={error?.message}
          ></GaMultiSelectField>
        )}
        rules={{ required: { value: true, message: "Field Required" } }}
      />

      <Controller
        name="mclNumber"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <GaTextField onChange={onChange} value={value} title="MCI Number *" />
        )}
      />

      {bricks && (
        <Controller
          name="brick"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <GaSelectField
              options={bricks.map(
                (s) => ({ key: s.short_code, value: s.name } as Option)
              )}
              value={value?.short_code}
              onChange={(event: any) => {
                if (event.target && event.target.value) {
                  onChange(
                    bricks.find((b) => b.short_code == event.target.value)
                  );
                }
              }}
              outputValue={OutputValue.KEY}
              variant={FieldVariant.OUTLINED}
              title="Brick *"
              error={!!error}
              helperText={error?.message}
            ></GaSelectField>
          )}
          rules={{ required: { value: true, message: "Field Required" } }}
        />
      )}

      <Controller
        name="noOfPatients"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <GaSelectField
            options={patientCount}
            value={value}
            onChange={onChange}
            outputValue={OutputValue.VALUE}
            variant={FieldVariant.OUTLINED}
            title="No of patients *"
            error={!!error}
            helperText={error?.message}
          ></GaSelectField>
        )}
        rules={{ required: { value: true, message: "Field Required" } }}
      />

      <Controller
        name="uuid"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <UploadFileCard
            title="Upload visting card * "
            id={5}
            onFileSelect={(file: File) => processFile(file)}
            file={fileUpload?.file}
            reset={() => resetFile()}
            isLoading={processingFile}
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{ required: { value: true, message: "Field Required" } }}
      />

      <Typography ml={1} fontWeight={600}>
        Add Address
      </Typography>

      <Controller
        name="line"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            label="Line"
            placeholder="Enter line..."
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{
          required: { value: true, message: "Field Required" },
          validate: {
            alphanumericLength: (value) => {
              const alphanumericCount = value.replace(
                /[^A-Za-z0-9]/g,
                ""
              ).length;
              return (
                alphanumericCount >= 15 ||
                "Enter minimum 15 alphanumeric characters"
              );
            },
          },
        }}
      />
      <Controller
        name="landmark"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            label="Landmark"
            placeholder="Enter landmark..."
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{
          required: { value: true, message: "Field Required" },
          validate: {
            alphanumericLength: (value) => {
              const alphanumericCount = value.replace(
                /[^A-Za-z0-9]/g,
                ""
              ).length;
              return (
                alphanumericCount >= 15 ||
                "Enter minimum 15 alphanumeric characters"
              );
            },
          },
        }}
      />
      <Controller
        name="area"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            label="Area"
            placeholder="Enter Area..."
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{
          required: { value: true, message: "Field Required" },
        }}
      />
      <Controller
        name="pincode"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            label="Pincode"
            placeholder="Enter Pincode"
            type="number"
            value={value}
            onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
              (e.target as HTMLInputElement).blur();
            }}
            onChange={(e: any) => {
              onChange(e.target.value);
              if (e.target.value.length == 6) {
                getAddress(e.target.value);
              } else {
                setValue("state", "");
                setValue("district", "");
                setValue("country", "");
              }
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{
          pattern: {
            value: regexPincode,
            message: "Invalid Pincode",
          },
          required: { value: true, message: "Field Required" },
        }}
      />
      <TextField
        fullWidth
        label="District, State, Country"
        disabled={true}
        value={
          watch("district") && watch("state") && watch("country")
            ? `${getValues("district")}, ${getValues("state")}, ${getValues(
                "country"
              )}`
            : ""
        }
      />

      <div
        style={{
          position: "sticky",
          bottom: 0,
          right: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
          zIndex: 1,
        }}
      >
        <div
          style={{
            marginBlock: 20,
            backgroundColor: "white",
          }}
        >
          <LoadingButton
            size="small"
            variant="contained"
            fullWidth
            loadingPosition="start"
            loading={submitting}
            color={GaButtonColor.SECONDARY}
            onClick={handleSubmit(onSubmit)}
          >
            <Typography color="white" textTransform={"none"}>
              {submitting ? "Creating..." : "SUBMIT"}
            </Typography>
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default DoctorRegistrationForm;
