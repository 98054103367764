import { StateCreator } from "zustand";

interface DoctorFilter {
  sampleEligible: boolean;
  selectedProgram: string;
}

interface CommonSliceInterface {
  doctorFilter: DoctorFilter;
  setDoctorFilter: Function;
  resetDoctorFilter: Function;
}

const initialStates = {
  doctorFilter: {
    sampleEligible: false,
    selectedProgram: "",
  },
};

export const useCommonSlice: StateCreator<CommonSliceInterface> = (
  set,
  get
) => ({
  ...initialStates,

  setDoctorFilter: (filter: DoctorFilter, callback?: () => void) => {
    set({ doctorFilter: filter });
    if (callback) callback();
  },
  resetDoctorFilter: () => {
    set({ doctorFilter: initialStates.doctorFilter });
  },
});
