import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Button from "@mui/material/Button";
import { pageRoutes } from "../../../routes";
import {
  GaTypography,
  TypographyType,
  TypographyColor,
  TypographyAlignment,
} from "../../ga-components/Typography";
import { GaAccordion } from "../../ga-components/Accordion";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import jwt_decode from "jwt-decode";
import { Contacts } from "../../../domain/models/contacts";
import { HospitalData } from "../../../domain/models/Hospital/hospitalData";
import BrickHospitalDoctorDetails from "./BrickHospitalDoctorDetails";
import BrickHospitalPharmacyDetails from "./BrickHospitalPharmacyDetails";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { CardContent, Skeleton, Stack, Typography } from "@mui/material";
import { HospitalMappedPharmacy } from "../../../domain/models/Hospital/hospitalMappedPharmacy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Mappings } from "../../../domain/models/mappings";
import { HospitalMappedDoctor } from "../../../domain/models/Hospital/hospitalMappedDoctor";

type Props = {
  cardDetails: HospitalData;
  handleOpenConfirmModal: Function;
  fetchMappedPharmacy: Function;
  mappedPharmacy: HospitalMappedPharmacy;
  loading: FetchState;
};

const HospitalDetailsCard: React.FC<Props> = ({
  cardDetails,
  fetchMappedPharmacy,
  handleOpenConfirmModal,
  loading,
  mappedPharmacy,
}) => {
  const navigate = useNavigate();
  const [mobileContacts, setMobileContacts] = useState<Contacts[]>([]);
  const [emailContacts, setEmailContacts] = useState<Contacts[]>([]);

  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>();

  const container = {
    fontFamily: "Roboto",
    width: "100%",
  };
  const subTitleStyle = {
    flex: "grid",
    margin: 2,
    marginBottom: "10px",
    color: "#6B6B6B",
  };

  const handleClick = () => {
    navigate(pageRoutes.hospitalProfilePage, {
      state: {
        id: cardDetails._id,
      },
    });
  };
  const handleChemistMapping = () => {
    navigate(pageRoutes.hospitalChemistMapping, {
      state: {
        id: cardDetails._id,
        brickCode: checkMappingValidation(cardDetails.mappings).brickCode,
        mappedPharmacy: mappedPharmacy,
      },
    });
  };

  const handleDoctorMapping = () => {
    navigate(pageRoutes.hospitalDoctorMapping, {
      state: {
        id: cardDetails._id,
        brickCode: checkMappingValidation(cardDetails.mappings).brickCode,
        mappedDoctor: cardDetails.doctors,
      },
    });
  };

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }

    mobileContacts.length = 0;
    {
      cardDetails.contacts?.map((contact, index) => {
        if (contact.type == "mobile" && contact.status == "active") {
          mobileContacts.length = 0;
          mobileContacts.push(contact);
          return <div key={index}></div>;
        } else if (contact.type == "email" && contact.status == "active") {
          emailContacts.length = 0;
          emailContacts.push(contact);
          return <div key={index}></div>;
        }
      });
    }
  }, []);

  const handleClickTherapy = () => {
    navigate(pageRoutes.hospitalTherapyPage, {
      state: {
        id: cardDetails._id,
      },
    });
  };

  const checkMappingValidation = (mappingData: Mappings[]) => {
    if (mappingData.length > 0) {
      for (let i = 0; i <= mappingData.length - 1; i++) {
        if (
          mappingData[i].hqCode == loggedInUserDetails?.hq_code &&
          mappingData[i].brickCode &&
          mappingData[i].status === "active"
        ) {
          return mappingData[i];
        }
      }
    }
    return {} as Mappings;
  };

  const filterMappedDoctors = () => {
    let filteredData: HospitalMappedDoctor[] = [];
    if (loggedInUserDetails) {
      filteredData = cardDetails.doctors.filter((item) => {
        return (
          item.deptCode == loggedInUserDetails.dept_code &&
          item.orgCode == loggedInUserDetails.org_code &&
          item.teamCode == loggedInUserDetails.team_code
        );
      });
      return filteredData;
    }
    return filteredData;
  };

  return (
    <div style={container}>
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={handleClick}
      >
        <>
          <div style={{ width: "100%" }}>
            <GaTypography
              typographyType={TypographyType.XL_BOLD}
              color={TypographyColor.INHERIT}
            >
              {cardDetails.fullNames[cardDetails.fullNames.length - 1].name
                .length > 0
                ? cardDetails.fullNames[cardDetails.fullNames.length - 1].name
                : ""}
            </GaTypography>
          </div>
          {checkMappingValidation(cardDetails.mappings) &&
            checkMappingValidation(cardDetails.mappings).brickName && (
              <GaTypography
                typographyType={TypographyType.SM}
                align={TypographyAlignment.RIGHT}
              >
                {checkMappingValidation(cardDetails.mappings).brickName}
              </GaTypography>
            )}
        </>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 2,
          marginBottom: "10px",
        }}
      >
        <GaTypography typographyType={TypographyType.SM}>
          {mobileContacts[mobileContacts.length - 1]?.value}
        </GaTypography>
      </div>

      <GaAccordion
        expandIcon={<ExpandMore />}
        onChange={() => {}}
        summary={
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >
            {`${filterMappedDoctors().length} Mapped Doctor(s) `}
          </GaTypography>
        }
        details={
          <>
            {filterMappedDoctors().length > 0 &&
              filterMappedDoctors().map((details) => {
                return (
                  <BrickHospitalDoctorDetails
                    data={details}
                    hospitalData={cardDetails}
                  />
                );
              })}
          </>
        }
      />
      <GaAccordion
        expandIcon={<ExpandMore />}
        onChange={() => fetchMappedPharmacy(cardDetails._id)}
        summary={
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >
            Mapped Pharmacy
          </GaTypography>
        }
        details={
          <>
            {loading === FetchState.LOADING ? (
              <div>
                <Skeleton animation="wave" width="40%">
                  <CardContent />
                </Skeleton>
                <Skeleton animation="wave" width="20%">
                  <GaTypography children="do" />
                </Skeleton>
              </div>
            ) : (
              <div>
                <BrickHospitalPharmacyDetails
                  data={mappedPharmacy}
                  hospitalData={cardDetails}
                  handleOpenConfirmModal={handleOpenConfirmModal}
                />
              </div>
            )}
          </>
        }
      />
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"}>
          {loggedInUserDetails?.dept_code === "HOSPI_CARE" &&
            loggedInUserDetails.org_code == "PFIZER" && (
              <Button color="secondary" onClick={handleClickTherapy}>
                <Typography variant="caption" fontWeight={660}>
                  Therapy
                </Typography>
                {cardDetails.feedRxStatus &&
                  cardDetails.feedRxStatus.length > 0 &&
                  cardDetails.feedRxStatus.map((rx) => {
                    if (
                      rx.rxFeeded &&
                      rx.deptCode == loggedInUserDetails?.dept_code &&
                      rx.orgCode == loggedInUserDetails.org_code
                    ) {
                      return (
                        <CheckCircleIcon fontSize="small" color="success" />
                      );
                    }
                  })}
              </Button>
            )}
        </Stack>
        {checkMappingValidation(cardDetails.mappings) &&
          checkMappingValidation(cardDetails.mappings).brickCode && (
            <Button color="secondary" onClick={() => handleChemistMapping()}>
              <Typography variant="caption" fontWeight={660}>
                MAP PHARMACY
              </Typography>
            </Button>
          )}

        {checkMappingValidation(cardDetails.mappings) &&
          checkMappingValidation(cardDetails.mappings).brickCode && (
            <Button color="secondary" onClick={() => handleDoctorMapping()}>
              <Typography variant="caption" fontWeight={660}>
                MAP DOCTOR
              </Typography>
            </Button>
          )}
      </Stack>
    </div>
  );
};

export default HospitalDetailsCard;
