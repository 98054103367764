import { MapChemistsToDoctor } from "../../../domain/usages/Doctors/map-chemists-to-doctor";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteMapChemistsToDoctor implements MapChemistsToDoctor {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async map(id: string, params: MapChemistsToDoctor.Params): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url.replace(":id", id),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
