import { StateCreator } from "zustand";
import { AUTH_API_URL, AUTH_HEADER, AUTH_HEADER_CHANNELPAY,  AUTH_TOKEN_KEY } from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import Endpoints from "../../../domain/endpoints";
import { ChemistType, ChemistTypeResponse } from "../../../domain/models/chemist/chemistType";
import { RemoteGenerateFileUploadUrl } from "../../../data/usecases/remote-generate-file-upload-url";
import { GenerateFileUploadUrlType } from "../../../domain/models/generate-upload-url";
import { FileUpload } from "../../../presentation/hooks/useFileUpload";

interface GenerateUploadURLInterface {
  generateUploadUrl: Function; 
  GenerateUploadURlData: GenerateFileUploadUrlType; 

}

const initialStates = {
  GenerateUploadURlData: {} as GenerateFileUploadUrlType, 
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const GenerateUploadUrlSlice: StateCreator<GenerateUploadURLInterface> = (
  set,
  get
) => ({
  ...initialStates,
  generateUploadUrl: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);

    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER_CHANNELPAY]:token }); 

    const remoteGenerateFileUploadUrl = new RemoteGenerateFileUploadUrl(
      `${AUTH_API_URL}${Endpoints.GET_UPLOADED_IMAGE_URL}`,
      axiosHttpClient
    );

    try {
      const result = await remoteGenerateFileUploadUrl.generate({
          type: "",
          size: "",
          name: ""
      });

      if (result.success) {
        set((state) => ({
          GenerateUploadURlData: result
        }));
      } else {
        console.error("Remote fetch failed:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
});
