import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useGeoLocation from "../../../domain/hooks/useGeoLocation";
import { ChemistType } from "../../../domain/models/chemist/chemistType";
import { ChemistFormDetails } from "../../../domain/models/chemistFormDetails";
import { Option } from "../../../domain/models/option";
import { FetchChemistTypes } from "../../../domain/usages/Chemists/fetch-chemist-types";
import { RegisterChemist } from "../../../domain/usages/Chemists/register-chemist";
import { ConfirmFileUpload } from "../../../domain/usages/confirm-file-upload";
import { FetchBricks } from "../../../domain/usages/fetch-bricks";
import { GenerateFileUploadUrl } from "../../../domain/usages/generate-file-upload-url";
import { UploadFile } from "../../../domain/usages/upload-file";
import { pageRoutes } from "../../../routes";
import { GaButton, GaButtonColor } from "../../ga-components/Button";
import {
  FieldVariant,
  GaSelectField,
  GaTextField,
  OutputValue,
} from "../../ga-components/Inputs";
import useBricks from "../../hooks/useBricks";
import useFileUpload from "../../hooks/useFileUpload";
import UploadFileCard from "../UploadButton/UploadFileCard";
import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";

const useRegisterChemist = (
  register: RegisterChemist
): [boolean, Function, any] => {
  const geoLocation = useGeoLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();

  const submit = useCallback(
    async (data: ChemistFormDetails) => {
      let identities = [];
      let contacts = [{ type: "MOBILE", value: data.mobileNumber }];
      let kycDocuments = [{ type: "SHOP_IMAGE", uuid: data.shopUuid }];
      if (data.GSTNumber) {
        identities.push({ type: "GSTIN", value: data.GSTNumber.toUpperCase() });
      }
      if (data.PANNumber) {
        identities.push({ type: "PAN", value: data.PANNumber.toUpperCase() });
      }
      if (data.emailID) {
        contacts.push({ type: "EMAIL", value: data.emailID });
      }
      if (data.salesUuid) {
        kycDocuments.push({ type: "INVOICE_IMAGE", uuid: data.salesUuid });
      }
      if (data.billUuid) {
        kycDocuments.push({ type: "INVOICE_IMAGE", uuid: data.billUuid });
      }

      const payload = {
        name: data.name,
        type: data.type,
        contacts: contacts,
        location: {
          latitude: geoLocation && geoLocation.latitude?.toString(),
          longitude: geoLocation && geoLocation.longitude?.toString(),
        },
        kyc_documents: kycDocuments,
        identities: identities,
        brick_code: data.brick,
      };
      setIsLoading(true);
      let result = await register.create(payload);
      setResponse(result);
      setIsLoading(false);
      if (result.statusCode && Array.isArray(result.message)) {
        Swal.fire(result.message[0].message, "", "error");
      } else if (result.statusCode && !Array.isArray(result.message)) {
        Swal.fire(result.message, "", "error");
      } else if (!result.success) {
        if (result.errors) {
          if (
            typeof result.errors.message === "object" &&
            result.errors.message !== null
          ) {
            Swal.fire(result.errors.message.message, "", "error");
          } else if (Array.isArray(result.errors)) {
            let errorText = "";
            for (let x in result.errors) {
              errorText += result.errors[x] + "\n";
            }
            Swal.fire(errorText, "", "error");
          } else {
            Swal.fire(result.errors.message, "", "error");
          }
        }
      }
    },
    [register, geoLocation]
  );

  return [isLoading, submit, response];
};

type Props = {
  generateUploadURL: GenerateFileUploadUrl;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmFileUpload;
  fetchBricks: FetchBricks;
  registerChemist: RegisterChemist;
  fetchChemistTypes: FetchChemistTypes;
};

const ChemistRegistrationForm: React.FC<Props> = ({
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
  fetchBricks,
  registerChemist,
  fetchChemistTypes,
}) => {
  const [chemistTypes, setChemistTypes] = useState<ChemistType[]>([]);
  const navigate = useNavigate();
  const bricks = useBricks(fetchBricks);
  const [submitting, submit, response] = useRegisterChemist(registerChemist);
  const { handleSubmit, control, setError, setValue } =
    useForm<ChemistFormDetails>({
      mode: "onChange",
    });

  const [
    shopImageUpload,
    processingShopImage,
    processShopImage,
    resetShopImage,
  ] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "SHOP_IMAGE"
  );

  const [
    billImageUpload,
    processingBillImage,
    processBillImage,
    resetBillImage,
  ] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "INVOICE_IMAGE"
  );

  const [
    salesImageUpload,
    processingSalesImage,
    processSalesImage,
    resetSalesImage,
  ] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "INVOICE_IMAGE"
  );

  const fetchChemistType = async () => {
    const params = {
      category: "RETAILER",
    };
    let result = await fetchChemistTypes.fetch(params);
    if (result.success) {
      setChemistTypes(result.customer_types);
    }
  };

  useEffect(() => {
    fetchChemistType();
  }, []);

  useEffect(() => {
    if (shopImageUpload && shopImageUpload.uuid) {
      setValue("shopUuid", shopImageUpload.uuid);
    }
  }, [shopImageUpload]);

  useEffect(() => {
    if (billImageUpload && billImageUpload.uuid) {
      setValue("billUuid", billImageUpload.uuid);
    }
  }, [billImageUpload]);

  useEffect(() => {
    if (salesImageUpload && salesImageUpload.uuid) {
      setValue("salesUuid", salesImageUpload.uuid);
    }
  }, [salesImageUpload]);

  const onSubmit = (data: ChemistFormDetails) => {
    submit(data);
  };

  useEffect(() => {
    if (response && response.success) {
      Swal.fire("Chemist registered successfully.", "", "success");
      navigate(pageRoutes.chemistProfilePage, {
        state: {
          id: response.data.uuid,
        },
      });
    }
  }, [response]);

  return (
    <div style={{ display: "grid", gap: "17px" }}>
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <GaTextField
            onChange={onChange}
            value={value}
            title="Name *"
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{
          required: { value: true, message: "Field Required" },
        }}
      />

      <Controller
        name="mobileNumber"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            value={value}
            label="Chemist Mobile Number *"
            error={!!error}
            helperText={error?.message}
            type="number"
          />
        )}
        rules={{
          pattern: {
            value: /^[6-9]{1}[0-9]{9}$/,
            message: "Invalid mobile",
          },
          required: { value: true, message: "Field Required" },
        }}
      />

      <Controller
        name="emailID"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <GaTextField
            onChange={onChange}
            value={value}
            title="Email ID"
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address",
          },
        }}
      />

      <Controller
        name="type"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <GaSelectField
            options={chemistTypes.map(
              (s) => ({ key: s.code, value: s.name } as Option)
            )}
            value={value}
            onChange={onChange}
            outputValue={OutputValue.KEY}
            variant={FieldVariant.OUTLINED}
            title="Chemist Type *"
            error={!!error}
            helperText={error?.message}
          ></GaSelectField>
        )}
        rules={{ required: { value: true, message: "Field Required" } }}
      />

      {bricks && (
        <Controller
          name="brick"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <GaSelectField
              options={bricks.map(
                (s) => ({ key: s.code, value: s.name } as Option)
              )}
              value={value}
              onChange={onChange}
              outputValue={OutputValue.KEY}
              variant={FieldVariant.OUTLINED}
              title="Brick *"
              error={!!error}
              helperText={error?.message}
            ></GaSelectField>
          )}
          rules={{ required: { value: true, message: "Field Required" } }}
        />
      )}
      <Controller
        name="PANNumber"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            value={value}
            label="PAN Number"
            error={error && true}
            helperText={error?.message}
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
          />
        )}
        rules={{
          pattern: {
            value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/i,
            message: "Invalid PAN number",
          },
        }}
      />

      <Controller
        name="GSTNumber"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            value={value}
            label="GST Number"
            error={error && true}
            helperText={error?.message}
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
          />
        )}
        rules={{
          pattern: {
            value:
              /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}Z[0-9a-zA-Z]{1}$/i,
            message: "Invalid GST number",
          },
        }}
      />

      <Controller
        name="shopUuid"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <UploadFileCard
            title="Upload shop image *"
            id={3}
            onFileSelect={(file: File) => processShopImage(file)}
            file={shopImageUpload?.file}
            reset={() => resetShopImage()}
            isLoading={processingShopImage}
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{ required: { value: true, message: "Field Required" } }}
      />

      <Controller
        name="billUuid"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <UploadFileCard
            title="Upload purchase bill"
            id={1}
            onFileSelect={(file: File) => processBillImage(file)}
            file={billImageUpload?.file}
            reset={() => resetBillImage()}
            isLoading={processingBillImage}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />

      <Controller
        name="salesUuid"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <UploadFileCard
            title="Upload sales bill"
            id={2}
            onFileSelect={(file: File) => processSalesImage(file)}
            file={salesImageUpload?.file}
            reset={() => resetSalesImage()}
            isLoading={processingSalesImage}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />

      <div
        style={{
          position: "sticky",
          bottom: 0,
          right: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <div style={{ marginBlock: 20 }}>
          <LoadingButton
            size="small"
            variant="contained"
            fullWidth
            loadingPosition="start"
            loading={submitting}
            color={GaButtonColor.SECONDARY}
            onClick={handleSubmit(onSubmit)}
          >
            <Typography color="white" textTransform={"none"}>
              {submitting ? "Creating..." : "SUBMIT"}
            </Typography>
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default ChemistRegistrationForm;
