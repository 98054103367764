import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Utils from "../../../common/Utils";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { FetchMappedDoctors } from "../../../domain/usages/Chemists/fetch-mapped-doctors";
import { MapDoctorsToChemist } from "../../../domain/usages/Chemists/map-doctors-to-chemist";
import { FetchDoctors } from "../../../domain/usages/Doctors/fetch-doctors";
import { pageRoutes } from "../../../routes";
import Header from "../../components/Header/Header";
import DoctorUserDetails from "../../components/UserDetails/DoctoruserDetails";
import InfiniteScroll from "react-infinite-scroll-component";
import IMAGES from "../../../images";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import jwt_decode from "jwt-decode";

type Props = {
  mapDoctor: MapDoctorsToChemist;
  fetchBrickDoctors: FetchDoctors;
  fetchMappedDoctors: FetchMappedDoctors;
};
const DoctorMapping: React.FC<Props> = ({
  mapDoctor,
  fetchBrickDoctors,
  fetchMappedDoctors,
}) => {
  const [brickDoctorList, setBrickDoctorList] = useState<DoctorData[]>([]);
  const location: any = useLocation();
  const [searchFilter, setSearchFilter] = useState("");
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [mappedDoctors, setMappedDoctors] = useState<DoctorData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [brick, setBrick] = useState("");

  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>({} as LoggedInUserDetails);

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);

  const filterRecords = (records: any) => {
    return records.filter((record: any) => {
      return (
        record.teamCode === loggedInUserDetails.team_code &&
        record.orgCode === loggedInUserDetails.org_code &&
        record.deptCode === loggedInUserDetails.dept_code
      );
    });
  };

  const fetchBrickDoctorsList = async (
    brickCode: string,
    page: number,
    scrolled: boolean
  ) => {
    const filter = {
      q: searchFilter,
      brickCode: brickCode,
      page: page,
    };
    try {
      if (!scrolled) {
        setFetchState(FetchState.LOADING);
      }
      let result = await fetchBrickDoctors.fetch(filter);
      if (result.success) {
        setCurrentPage(result.data.page);
        setLastPage(result.data.totalPages);
        if (scrolled) {
          setBrickDoctorList((oldData) => [
            ...oldData,
            ...filterRecords(result.data.result),
          ]);
        } else {
          setBrickDoctorList(filterRecords(result.data.result));
        }
        setFetchState(FetchState.SUCCESS);
      }
    } catch (err) {
      setFetchState(FetchState.ERROR);
    }
  };

  const fetchMappedDoctorsList = useCallback(
    async (id: string) => {
      if (id) {
        let result = await fetchMappedDoctors.fetch(id);
        if (result.success) {
          setMappedDoctors(result.data);
        }
      }
    },
    [fetchMappedDoctors]
  );

  useEffect(() => {
    if (location.state.brickCode) {
      setBrick(location.state.brickCode);
      fetchBrickDoctorsList(location.state.brickCode, 1, false).then(() => {
        if (location.state.chemist) {
          fetchMappedDoctorsList(location.state.chemist._id);
        }
      });
    }
  }, [searchFilter]);

  const trailingStyle = {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textAlign: "center",
    color: "info.main",
  };

  const navigate = useNavigate();
  const handleSkip = () => {
    navigate(pageRoutes.customerProfiler, {
      state: {
        value: 1,
      },
    });
  };

  const doctorMapping = async (data: any) => {
    const selectedDoctorIds: any = [];
    brickDoctorList.forEach((doctor) => {
      if (doctor.selected) {
        selectedDoctorIds.push(doctor._id);
      }
    });
    const payload = {
      name: location.state.chemist.firmNames[0].name,
      mobile: Utils.getActiveMobile(
        location.state.chemist.contacts,
        "mobile",
        "active"
      ),
      uuid: location.state.chemist._id,
      doctorIds: selectedDoctorIds,
      teamCode: loggedInUserDetails.team_code
        ? loggedInUserDetails.team_code
        : null,
      deptCode: loggedInUserDetails.dept_code,
      orgCode: loggedInUserDetails.org_code,
    };

    let result = await mapDoctor.map(payload);
    if (result.success) {
      Swal.fire(result.message, "", "success");
      navigate(pageRoutes.chemistProfilePage, {
        state: {
          id: location.state.chemist._id,
        },
      });
    } else {
      Swal.fire(result.message, "", "error");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const doctorList = brickDoctorList?.map((doctor) => {
      if (e.target.value === doctor._id) {
        doctor.selected = e.target.checked;
      }
      return doctor;
    });
    setBrickDoctorList(doctorList);
  };

  useEffect(() => {
    let doctorList: DoctorData[] = [];
    if (mappedDoctors.length > 0) {
      brickDoctorList.map((doctor: DoctorData) => {
        mappedDoctors.forEach((mappedDoctor: DoctorData) => {
          if (doctor._id === mappedDoctor._id) {
            doctor.selected = true;
          }
        });
        doctorList.push(doctor);
      });

      setBrickDoctorList(doctorList);
    }
  }, [mappedDoctors]);
  const handleSearch = (e: any) => {
    setSearchFilter(e.target.value);
  };
  const debouncedOnChange = debounce(handleSearch, 2000);

  const hasMoreData = () => {
    return fetchState == FetchState.SUCCESS ? currentPage < lastPage : false;
  };

  return (
    <>
      <Box mt={2}>
        <Header
          title="Map To Controlled Doctors"
          showBackButton={false}
          trailing={
            <Button onClick={handleSkip}>
              <Typography sx={trailingStyle}>Skip</Typography>
            </Button>
          }
        />
        <Box m={3} mt={3}>
          <SearchIcon
            sx={{ position: "absolute", marginTop: 1, marginLeft: 1 }}
          />
          <input
            style={{
              width: "90%",
              padding: 10,
              textIndent: 30,
              borderRadius: "4px",
            }}
            onChange={debouncedOnChange}
            placeholder="Search Doctor..."
          />
          <div id="div" style={{ overflowY: "auto", height: "68vh" }}>
            {fetchState === FetchState.LOADING && (
              <div
                style={{
                  margin: 0,
                  position: "absolute",
                  top: "50%",
                  left: "45%",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {fetchState === FetchState.SUCCESS &&
              brickDoctorList &&
              brickDoctorList.length > 0 && (
                <InfiniteScroll
                  scrollableTarget="div"
                  dataLength={brickDoctorList?.length}
                  next={() => {
                    fetchBrickDoctorsList(brick, currentPage + 1, true);
                  }}
                  hasMore={hasMoreData()}
                  loader={<h4>Loading...</h4>}
                >
                  {brickDoctorList.map((brickDoctor: DoctorData, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 20,
                          }}
                        >
                          <DoctorUserDetails data={brickDoctor} />

                          <Checkbox
                            value={brickDoctor._id}
                            checked={brickDoctor.selected ? true : false}
                            onChange={handleChange}
                            color="secondary"
                          />
                        </div>
                        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                      </>
                    );
                  })}
                </InfiniteScroll>
              )}
            {fetchState === FetchState.SUCCESS &&
              brickDoctorList &&
              brickDoctorList.length == 0 && (
                <Stack alignItems={"center"}>
                  <img src={IMAGES.noRecordsFound} alt="no records" />
                  <Typography variant="h5"> No Records Found</Typography>
                </Stack>
              )}
          </div>
          {fetchState === FetchState.SUCCESS &&
            brickDoctorList &&
            brickDoctorList.length > 0 && (
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  left: 0,
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                <div style={{ margin: 20 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    sx={{ color: "#fff" }}
                    onClick={doctorMapping}
                  >
                    Map Doctors
                  </Button>
                </div>
              </div>
            )}
        </Box>
      </Box>
    </>
  );
};

export default DoctorMapping;
