import { StateCreator } from "zustand";
// import { ChemistFormDetailsData } from "../../../domain/models/chemistNewFormDetails";
import { ChemistFormDetailsData } from "../../../domain/models/ChemistFormDetailsData";

interface chemistFormProp {
  formData: ChemistFormDetailsData;
  setFormData: Function
}

const initialState = {
  formData:  {
    name: "",
    type: "",
    contacts: {
        type: "MOBILE",
        value: "",
    },
    location: {
        latitude: "",
        longitude: "",
    },
    brick_code: "",
    Images:[],
}
};

const useChemistFormDetailsData: StateCreator<chemistFormProp> = (set, get) => ({
  ...initialState,
  setFormData: (newFormData:ChemistFormDetailsData) => {
    set({ formData: newFormData });
  },
});

export default useChemistFormDetailsData;
