import React from "react";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import FieldVariant from "./field-variant";
import FieldSize from "./field-size";
import FieldType from "./field-type";

type Props = {
  title?: string;
  placeholder?: string;
  readOnly?: boolean;
  error?: boolean;
  required?: boolean;
  helperText?: string;
  startAdornment?: any;
  onChange: Function;
  value: string | number;
  variant?: FieldVariant;
  size?: FieldSize;
  isDisabled?: boolean;
  inputBoxType?: FieldType;
  borderRadius?: string | number;
};

const GaTextField: React.FC<Props> = ({
  title = "",
  placeholder = "",
  readOnly = false,
  error = false,
  required = false,
  helperText = "",
  startAdornment = "",
  onChange,
  value = "",
  variant = FieldVariant.OUTLINED,
  size = FieldSize.MEDIUM,
  isDisabled = false,
  inputBoxType = FieldType.TEXTBOX,
  borderRadius = 0,
}) => {
  return (
    <TextField
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: `${
            inputBoxType == FieldType.SEARCHBOX ? "20px" : borderRadius
          }`,
        },
      }}
      fullWidth
      error={error}
      required={required}
      disabled={isDisabled}
      size={size}
      label={title}
      InputProps={{
        readOnly: readOnly,
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
      }}
      variant={variant}
      placeholder={placeholder}
      onChange={(event) => onChange(event)}
      helperText={helperText}
      value={value}
    />
  );
};
export default GaTextField;
