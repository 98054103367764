import { Box, Button, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DoctorProgram } from "../../../domain/models/doctor/doctorProgram";
import { Option } from "../../../domain/models/option";
import {
  FieldVariant,
  GaSelectField,
  OutputValue,
} from "../../ga-components/Inputs";
import { GaTypography, TypographyType } from "../../ga-components/Typography";

type Props = {
  open: boolean;
  handleClose: Function;
  successCallBack: Function;
  programProducts: DoctorProgram[];
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EnrollProgramModal: React.FC<Props> = ({
  open,
  handleClose,
  successCallBack,
  programProducts,
}) => {
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedProgramDetails, setSelectedProgramDetails] =
    useState<DoctorProgram>({} as DoctorProgram);

  useEffect(() => {
    setSelectedProgram("");
    setSelectedProgramDetails({} as DoctorProgram);
  }, [open]);

  const handleSelectedProgram = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProgram(e.target.value);
    for (let i = 0; i <= programProducts.length; i++) {
      if (programProducts[i].code == e.target.value) {
        setSelectedProgramDetails(programProducts[i]);
        break;
      }
    }
  };

  const handleSubmit = () => {
    successCallBack(selectedProgram);
  };

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box sx={style}>
        <GaTypography typographyType={TypographyType.BASE_BOLD}>
          Select Program
        </GaTypography>
        <GaSelectField
          options={programProducts.map(
            (s) => ({ key: s.code, value: s.name } as Option)
          )}
          value={selectedProgram}
          onChange={handleSelectedProgram}
          outputValue={OutputValue.KEY}
          variant={FieldVariant.OUTLINED}
        />
        {selectedProgram && (
          <div style={{ marginTop: 10 }}>
            <GaTypography typographyType={TypographyType.LG_BOLD}>
              Selected Product Details:
            </GaTypography>
            <table
              style={{
                width: "100%",
                border: "1px solid grey",
                padding: 10,
                borderRadius: 5,
              }}
            >
              {selectedProgramDetails.products.map((product: any) => {
                return (
                  <tr>
                    <td style={{ width: "50%" }}>
                      <GaTypography typographyType={TypographyType.SM}>
                        {product.name}
                      </GaTypography>
                    </td>
                    <td style={{ width: "25%", paddingLeft: 20 }}>
                      <GaTypography typographyType={TypographyType.SM}>
                        x{product.units}
                      </GaTypography>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
        {selectedProgram && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: 5,
              marginTop: 10,
            }}
          >
            <Button
              variant="contained"
              size="small"
              fullWidth
              color="error"
              onClick={() => handleClose()}
              sx={{ color: "#fff" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              fullWidth
              color="secondary"
              onClick={handleSubmit}
              sx={{ color: "#fff" }}
            >
              Submit
            </Button>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default EnrollProgramModal;
