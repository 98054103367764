import { Box, Button, Modal } from "@mui/material";
import React from "react";
import { EnrolledProgram } from "../../../domain/models/doctor/enrolled-program";
import { GaTypography, TypographyType } from "../../ga-components/Typography";

type Props = {
  open: boolean;
  handleClose: Function;
  selectedProgramProductDetails: EnrolledProgram;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProgramDetailsModal: React.FC<Props> = ({
  open,
  handleClose,
  selectedProgramProductDetails,
}) => {
  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box sx={style}>
        <div style={{ marginTop: 10 }}>
          <GaTypography typographyType={TypographyType.LG_BOLD}>
            {selectedProgramProductDetails.program.name}
          </GaTypography>
          <table
            style={{
              width: "100%",
              border: "1px solid grey",
              padding: 10,
              borderRadius: 5,
            }}
          >
            {selectedProgramProductDetails.program.products.map(
              (product: any) => {
                return (
                  <tr>
                    <td style={{ width: "50%" }}>
                      <GaTypography typographyType={TypographyType.SM}>
                        {product.name}
                      </GaTypography>
                    </td>
                    <td style={{ width: "25%", paddingLeft: 20 }}>
                      <GaTypography typographyType={TypographyType.SM}>
                        x{product.units}
                      </GaTypography>
                    </td>
                  </tr>
                );
              }
            )}
          </table>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: 5,
            marginTop: 10,
          }}
        >
          <Button
            variant="contained"
            size="small"
            fullWidth
            color="error"
            onClick={() => handleClose()}
            sx={{ color: "#fff" }}
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ProgramDetailsModal;
