import { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { FetchWorkerTypes } from "../../../domain/usages/Chemists/fetch-workers-types";
import ButtonColor from "../../ga-components/Button/button-color";
import {
  FieldVariant,
  GaSelectField,
  GaTextField,
  OutputValue,
} from "../../ga-components/Inputs";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { Option } from "../../../domain/models/option";

type Props = {
  handleClose: Function;
  open: boolean;
  successCallBack: Function;
  fetchWorkerTypes: FetchWorkerTypes;
};

type AddWorkersForm = {
  memberType: string;
  name: string;
  mobileNumber: string;
  emailId: string;
};

type WorkerTypes = {
  name: string;
  value: string;
};

const AddWorkersForm: React.FC<Props> = ({
  handleClose,
  open,
  successCallBack,
  fetchWorkerTypes,
}) => {
  const [workerTypesOptions, setWorkerTypesOptions] = useState<WorkerTypes[]>(
    []
  );
  const { handleSubmit, control, setError, setValue } = useForm<AddWorkersForm>(
    {
      mode: "onChange",
    }
  );

  const workerTypes = async () => {
    let result = await fetchWorkerTypes.fetch();
    if (result.success) {
      setWorkerTypesOptions(result.data.types);
    }
  };

  useEffect(() => {
    workerTypes();
  }, []);

  const onSubmit = (data: AddWorkersForm) => {
    successCallBack(data);
  };
  return (
    <Dialog open={open} onClose={() => handleClose}>
      <div style={{ marginTop: 10, textAlign: "center" }}>
        <GaTypography
          color={TypographyColor.SECONDARY}
          typographyType={TypographyType.BASE_BOLD}
        >
          Add Owner/Staff
        </GaTypography>
      </div>

      <DialogContent sx={{ display: "grid", gap: 2 }}>
        <Controller
          name="memberType"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <GaSelectField
              options={workerTypesOptions.map(
                (s) => ({ key: s.value, value: s.name } as Option)
              )}
              value={value}
              onChange={onChange}
              outputValue={OutputValue.KEY}
              variant={FieldVariant.OUTLINED}
              title="Member Type *"
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{ required: { value: true, message: "Field Required" } }}
        />
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <GaTextField
              onChange={onChange}
              value={value}
              title="Name *"
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{
            required: { value: true, message: "Field Required" },
          }}
        />
        <Controller
          name="mobileNumber"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <GaTextField
              onChange={onChange}
              value={value}
              title="Mobile Number"
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{
            pattern: {
              value: /^[6-9]{1}[0-9]{9}$/,
              message: "Invalid mobile",
            },
          }}
        />
        <Controller
          name="emailId"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <GaTextField
              onChange={onChange}
              value={value}
              title="Email ID"
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid mobile",
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={() => handleClose()}
          color={ButtonColor.SECONDARY}
          variant="contained"
        >
          {" "}
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          color={ButtonColor.SECONDARY}
          variant="contained"
          fullWidth
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWorkersForm;
