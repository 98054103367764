import { StateCreator } from "zustand";
import { AUTH_API_URL, AUTH_HEADER, AUTH_HEADER_CHANNELPAY,  AUTH_TOKEN_KEY } from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import Endpoints from "../../../domain/endpoints";
import { RemoteConfirmFileUpload } from "../../../data/usecases/remote-confirm-file-upload";
import { ConfirmFileUploadType } from "../../../domain/models/confirm-upload-file";

interface confirmFileUploadInterface {
  confirmUploadUrl:Function; 
  confirmUploadData: ConfirmFileUploadType;
}

const initialStates = {
  confirmUploadData:{} as ConfirmFileUploadType, 
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const confirmUploadFileSlice: StateCreator<confirmFileUploadInterface> = (
  set,
  get
) => ({
  ...initialStates,
  confirmUploadUrl: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);

    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER_CHANNELPAY]:token }); 

    const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
      `${AUTH_API_URL}${Endpoints.CONFIRM_IMAGE_UPLOAD_TO_BUCKET}`,
      axiosHttpClient
    );

    try {
      const result = await remoteConfirmFileUpload.confirm({ uuid: ""});

      if (result.success) {
        set((state) => ({
          confirmUploadData: result,
        }));
      } else {
        console.error("Remote fetch failed:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
});
