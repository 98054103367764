import React, { useState } from "react";
import TherapyBrandDetailsComp from "./TherapyBrandDetails";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { TherapyDetails } from "../../../../domain/models/doctor/therapyDetails";

type Props = {
  handleCompetitorDetails: Function;
  handleBrandDetails: Function;
  therapy: TherapyDetails;
};

const HospitalTherapyCard: React.FC<Props> = ({
  handleCompetitorDetails,
  handleBrandDetails,
  therapy,
}) => {
  const [therapyName, setTherapyName] = useState<string>();

  const handleTherapyDetails = (value: string) => {
    setTherapyName(value);
  };

  return (
    <>
      <Stack margin={1}>
        <Card>
          <Typography
            variant="subtitle2"
            padding={1}
            bgcolor={"secondary.light"}
            textAlign={"center"}
          >
            {therapyName}
          </Typography>
          <CardContent>
            <Stack spacing={2}>
              <TherapyBrandDetailsComp
                brandDetails={therapy.brands}
                handleCompetitorDetails={handleCompetitorDetails}
                handleBrandDetailsFunc={handleBrandDetails}
                handleTherapyDetails={handleTherapyDetails}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

export default HospitalTherapyCard;
