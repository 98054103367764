import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_API_URL, AUTH_HEADER_CHANNELPAY, AUTH_TOKEN_KEY } from "../../../base";
import { RemotePostRegDetails } from "../../../data/usecases/Chemists/remote-post-regDetails";
import { ChemistFormDetailsData } from "../../../domain/models/ChemistFormDetailsData";

interface PostRegDetailsInterface {
  UploadRegDetails: (data: any) => Promise<void>;
  fetchUploadState: FetchState;
}

const initialStates = {
  fetchUploadState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useUploadRegDetails: StateCreator<PostRegDetailsInterface> = (set, get) => ({
  ...initialStates,

  UploadRegDetails: async (data: any) => {
    try {
      set(() => ({ fetchUploadState: FetchState.LOADING }));

      const token = storage.get(AUTH_TOKEN_KEY);

      axiosHttpClient.setAuthHeaders({ [AUTH_HEADER_CHANNELPAY]:token }); 

      const remotePostRegDetails = new RemotePostRegDetails(
        `${AUTH_API_URL}${Endpoints.CREATE_CUSTOMERS}`,
        axiosHttpClient
      );

      const result:ChemistFormDetailsData = await remotePostRegDetails.Post(data);

      if (result) {
        set(() => ({ fetchUploadState: FetchState.SUCCESS }));
      
      } else {
        Swal.fire("Oops...", "Something went wrong!", "error");
        set(() => ({ fetchUploadState: FetchState.ERROR }));
      }
      } catch (error) {
        console.error("Error uploading :", error);
        Swal.fire("Oops...", "Something went wrong!", "error");
        set(() => ({ fetchUploadState: FetchState.ERROR }));
      }
  },
});
