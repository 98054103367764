import { useLocation } from "react-router-dom";
import AccountCreatedComponent from "../../components/AccountCreatedComponent";
import DocumentUploadComponent from "../../components/UploadDocumentsComponent";
import { AUTH_API_URL, AUTH_HEADER_CHANNELPAY, AUTH_TOKEN_KEY } from "../../../base";
import { RemoteConfirmFileUpload } from "../../../data/usecases/remote-confirm-file-upload";
import { RemoteGenerateFileUploadUrl } from "../../../data/usecases/remote-generate-file-upload-url";
import { RemoteUploadFile } from "../../../data/usecases/remote-upload-file";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";

type Props = {};

const AccountCreatedPage: React.FC<Props> = ({

}) => {
    const location: any = useLocation();
      
    return (
        <div>
            {/* <DocumentUploadComponent documentName={"DL Form 20"} documentCode={"DRUG_LICENSE_FORM_20"} screenTitle={"Upload Drug License Form 20"} mobileNumber={location.state.mobileNumber}></DocumentUploadComponent> */}
            <AccountCreatedComponent userName={location.state?.name ?? ''} />
        </div>
    );
};

export default AccountCreatedPage;
