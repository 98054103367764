import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import { MappedChemist } from "../../../domain/models/doctor/mappedChemist";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import jwt_decode from "jwt-decode";

type userProps = {
  data: MappedChemist;
  doctorData: DoctorData;
};

const BrickChemistDetails: React.FC<userProps> = ({ data, doctorData }) => {
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>();

  const titleStyles = {
    color: "#212121",
    width: "100%",
  };
  const subTitleStyle = {
    color: "#6B6B6B",
    margin: 2,
  };

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);
  return (
    <div style={{ fontFamily: "Roboto" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div style={titleStyles}>
            <GaTypography typographyType={TypographyType.BASE}>
              {data.name}
            </GaTypography>
          </div>
          <div style={subTitleStyle}>
            <GaTypography typographyType={TypographyType.XS}>
              {data.mobile}
            </GaTypography>
          </div>
        </div>
        {doctorData.mappings?.map((mappingData, index) => {
          if (
            mappingData.hqCode == loggedInUserDetails?.hq_code &&
            mappingData.brickCode
          ) {
            return (
              <div key={index} style={subTitleStyle}>
                <GaTypography typographyType={TypographyType.SM}>
                  {mappingData.brickName}
                </GaTypography>
              </div>
            );
          }
        })}
      </div>

      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
    </div>
  );
};

export default BrickChemistDetails;
