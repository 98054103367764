import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { ContactsFormFields } from "../../domain/models/contactsFormFields";
import { Constants } from "../../common/constants";

type Props = {
  open: boolean;
  handleClose: Function;
  successCallback: Function;
  loading: boolean;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddContactsModal: React.FC<Props> = ({
  open,
  handleClose,
  successCallback,
  loading,
}) => {
  const { handleSubmit, control, setValue, watch } =
    useForm<ContactsFormFields>({
      mode: "onChange",
    });

  const handleSubmitAddress = (data: ContactsFormFields) => {
    successCallback(data);
  };

  useEffect(() => {
    setValue("type", "");
    setValue("value", "");
  }, [open]);

  useEffect(() => {
    setValue("value", "");
  }, [watch("type")]);

  const disableSubmitButton = (type: string) => {
    if (type == "mobile" || type == "whatsapp") {
      return !Constants.MOBILE_REGREX.test(watch("value"));
    } else if (type == "email") {
      return !Constants.EMAIL_REGREX.test(watch("value"));
    }
    return true;
  };

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box sx={style}>
        <Stack alignItems={"end"}>
          <CloseIcon color="error" onClick={() => handleClose()} />
        </Stack>
        <Typography fontWeight={500} variant="body1">
          Enter Doctor Contacts:
        </Typography>
        <Stack spacing={2} marginTop={2}>
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl fullWidth size="small">
                <InputLabel>Select Contact Type</InputLabel>
                <Select
                  value={value}
                  label="Select Contact Type"
                  onChange={onChange}
                >
                  <MenuItem value={"email"}>Email</MenuItem>
                  <MenuItem value={"mobile"}>Mobile</MenuItem>
                  <MenuItem value={"whatsapp"}>WhatsApp</MenuItem>
                </Select>
              </FormControl>
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          {watch("type") == "mobile" && (
            <Controller
              name="value"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Mobile"
                  placeholder="Enter mobile number..."
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
              rules={{
                pattern: {
                  value: Constants.MOBILE_REGREX,
                  message: "Invalid mobile",
                },
                required: { value: true, message: "Field Required" },
              }}
            />
          )}
          {watch("type") == "whatsapp" && (
            <Controller
              name="value"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="WhatsApp"
                  placeholder="Enter whatsapp number..."
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
              rules={{
                pattern: {
                  value: Constants.MOBILE_REGREX,
                  message: "Invalid mobile",
                },
                required: { value: true, message: "Field Required" },
              }}
            />
          )}
          {watch("type") == "email" && (
            <Controller
              name="value"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Email"
                  placeholder="Enter email..."
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
              rules={{
                pattern: {
                  value: Constants.EMAIL_REGREX,
                  message: "Invalid email address",
                },
                required: { value: true, message: "Field Required" },
              }}
            />
          )}

          <LoadingButton
            loading={loading}
            disabled={disableSubmitButton(watch("type"))}
            variant="contained"
            color="secondary"
            onClick={handleSubmit(handleSubmitAddress)}
          >
            <Typography fontWeight={550} color={"white"} textTransform={"none"}>
              Submit
            </Typography>
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddContactsModal;
