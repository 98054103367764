import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { GaButtonColor } from '../ga-components/Button';
import { useNavigate } from 'react-router-dom';
import accountCreated from "../../images/account_created.gif";
import { ArrowForward } from '@mui/icons-material';
import { Container } from '@mui/material';

type AccountCreatedComponentProps = {
    userName: string;
};

const AccountCreatedComponent: React.FC<AccountCreatedComponentProps> = ({ userName }) => {
    var navigate = useNavigate();
    return (
        <Container
        maxWidth="sm"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            background: 'linear-gradient(180deg, #E4E1F2 0%, rgba(255, 255, 255, 0) 100%)',
        }}
        >
            <Box
                sx={{
                    height: '4vh'
                }}
            ></Box>
            <Box
                component="img"
                sx={{
                    maxWidth: '70%',
                    height: 'auto',
                }}
                src={accountCreated}
                alt="Loading"
            />
            <Box
                sx={{
                    height: '8vh'
                }}
            ></Box>
            <Typography variant="h3"
                sx={{
                    fontFamily: 'DM Sans',
                    fontSize: '32px',
                    fontWeight: 700,
                    lineHeight: '40px',
                    textAlign: 'left',
                    color: '#1A1A1A',
                    alignSelf: 'flex-start',
                }}
            >
                <Box component="span" sx={{ color: '#12008A' }}>"{userName}"</Box> is now registered on ChannelPay!
            </Typography>
            <Box
                sx={{
                    height: '12px'
                }}
            ></Box>
            <Typography variant="h3"
                sx={{
                    fontFamily: 'DM Sans',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: '#1A1A1A',
                    alignSelf: 'flex-start',
                }}
            >We’d like to send you notifications from time to time to deepen your experience on the app.
            </Typography>
            <Box sx={{ flexGrow: 3 }} />
            <LoadingButton
                size="medium"

                variant="contained"
                loadingPosition="start"

                loading={false}
                endIcon={<ArrowForward />}
                color={GaButtonColor.PRIMARY}
                sx={{
                    height: '48px',
                    padding: '12px 24px',
                    borderRadius: '16px',

                }}
                onClick={() => { navigate(-1); }}
            >
                <Typography color="white" textTransform={"none"}>
                    {"Back to Customers"}
                </Typography>

            </LoadingButton>
            <Box sx={{ flexGrow: 2 }} />
        </Container>
    );
};

export default AccountCreatedComponent;