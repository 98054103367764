import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { ChemistsFetchingFactory } from "../../../main/Chemists/ChemistsFetchingFactory";
import { DoctorsFetchingFactory } from "../../../main/Doctors/DoctorsFetchingFactory";
import { HospitalsFetchingFactory } from "../../../main/Hospital/HospitalsFetchingFactory";
import { GaTypography } from "../../ga-components/Typography";
import HospitalsListSummary from "../Hospital/HospitalsListSummary";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import jwt_decode from "jwt-decode";
import { Department } from "../../../common/enums/department";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, index, value } = props;

  return (
    <div role="tab=panel" id={`simple-tabPanel-${index}`}>
      {value === index && (
        <div style={{ padding: 2 }}>
          <GaTypography>{children}</GaTypography>
        </div>
      )}
    </div>
  );
}

type Props = {
  tabValue: number;
};

const Tabbar: React.FC<Props> = ({ tabValue = 0 }) => {
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>({} as LoggedInUserDetails);

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);

  function tabsProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(tabValue);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const tabStyles = {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    width: "25%",
  };
  return (
    <div style={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        indicatorColor="secondary"
        variant="fullWidth"
      >
        <Tab label="DOCTORS" sx={tabStyles} {...tabsProps(0)} />
        <Tab label="CHEMISTS" sx={tabStyles} {...tabsProps(1)} />
        {(loggedInUserDetails.dept_code == Department.GMCH ||
          loggedInUserDetails.dept_code == Department.ASPIRANEW ||
          loggedInUserDetails.dept_code == Department.HOSPICARE) && (
          <Tab label="HOSPITALS" sx={tabStyles} {...tabsProps(2)} />
        )}
      </Tabs>

      <TabPanel index={0} value={value}>
        <DoctorsFetchingFactory />
      </TabPanel>

      <TabPanel index={1} value={value}>
        <ChemistsFetchingFactory />
      </TabPanel>
      {(loggedInUserDetails.dept_code == Department.GMCH ||
        loggedInUserDetails.dept_code == Department.ASPIRANEW ||
        loggedInUserDetails.dept_code == Department.HOSPICARE) && (
        <TabPanel index={2} value={value}>
          <HospitalsFetchingFactory />
        </TabPanel>
      )}
    </div>
  );
};

export default Tabbar;
