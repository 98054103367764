import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  DOCTOR_PROFILER_API_URL,
} from "../../base";
import { RemoteFetchAllBrand } from "../../data/usecases/Doctors/therapy/remote-fetch-all-brand";
import { RemoteFetchPrescriptions } from "../../data/usecases/Doctors/therapy/remote-fetch-prescriptions";
import { RemoteSavePrescriptionDetails } from "../../data/usecases/Doctors/therapy/remote-save-prescription-details";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import DoctorTherapyList from "../../presentation/components/Doctor/therapySection/DoctorTherapyList";

export const TherapyFactory = () => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const remoteFetchPrescriptions = new RemoteFetchPrescriptions(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.FETCH_THERAPY}`,
    axiosHttpClient
  );

  const remoteFetchAllBrand = new RemoteFetchAllBrand(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.FETCH_BRANDS}`,
    axiosHttpClient
  );

  const remoteSavePrescriptionDetails = new RemoteSavePrescriptionDetails(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.FETCH_THERAPY}`,
    axiosHttpClient
  );

  return (
    <DoctorTherapyList
      fetchPrescriptions={remoteFetchPrescriptions}
      fetchAllBrands={remoteFetchAllBrand}
      savePrescriptionDetails={remoteSavePrescriptionDetails}
    />
  );
};
