import React, { useEffect, useState } from "react";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Divider from "@mui/material/Divider";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import Utils from "../../../common/Utils";
import { ChemistData } from "../../../domain/models/chemist/ChemistData";
import { FirmNameType } from "../../../domain/models/chemist/firmNameType";

type userProps = {
  data: ChemistData;
  node?: React.ReactNode;
};

const UserDetails: React.FC<userProps> = ({ data, node }) => {
  const titleStyles = {
    color: "#212121",
    width: "100%",
    marginLeft: 5,
  };
  const subTitleStyle = {
    color: "#6B6B6B",
    marginLeft: 5,
  };
  const iconStyle = {
    color: "#2EC4B6",
    position: "absolute",
    marginLeft: 1,
  };

  const [firmNames, setFirmNames] = useState<FirmNameType[]>([]);

  useEffect(() => {
    for (let i = 0; i <= data.firmNames.length - 1; i++) {
      if (
        data.firmNames[i].source == "cpay" ||
        data.firmNames[i].source == "cpaisa"
      ) {
        firmNames.push(data.firmNames[i]);
        setFirmNames([...firmNames]);
      }
    }
  }, [data._id]);
  return (
    <div style={{ fontFamily: "Roboto", margin: 6 }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={titleStyles}>
          {firmNames.length > 0 && (
            <GaTypography typographyType={TypographyType.BASE}>
              {firmNames[firmNames.length - 1].name}
            </GaTypography>
          )}
        </div>
      </div>

      {data.contacts?.map((contact: any) => {
        if (contact.type == "mobile" && contact.status == "active") {
          return (
            <div style={subTitleStyle}>
              <GaTypography typographyType={TypographyType.XS}>
                {contact.value}
              </GaTypography>
            </div>
          );
        }
      })}
    </div>
  );
};

export default UserDetails;
