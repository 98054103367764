import { CardContent, Stack, Skeleton } from "@mui/material";
import React from "react";
import { GaCard, GaCardVariant, CardType } from "../Card";
import { GaTypography } from "../Typography";

type Props = {};

const index = (props: Props) => {
  return (
    <GaCard variant={GaCardVariant.OUTLINED} cardType={CardType.NORMAL_CARD}>
      <CardContent>
        <Stack direction="row" spacing={15} display="flex">
          <Skeleton animation="wave" width="40%">
            <CardContent />
          </Skeleton>
          <Skeleton animation="wave" width="20%">
            <GaTypography children="do" />
          </Skeleton>
        </Stack>
        <Stack direction="row" spacing={15} display="flex">
          <Skeleton animation="wave" width="40%">
            <CardContent />
          </Skeleton>
          <Skeleton width="20%">
            <GaTypography children="do" />
          </Skeleton>
        </Stack>
        <Skeleton
          animation="wave"
          width="20%"
          sx={{
            float: "right",
            mr: "30px",
            mt: "10px",
            mb: "10px",
          }}
        >
          <button>Yes</button>
        </Skeleton>
      </CardContent>
    </GaCard>
  );
};

export default index;
