import React, { useState } from "react";
import Button from "@mui/material/Button";
import { GaCard, GaCardVariant } from "../../ga-components/Card";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";

type Props = {
  value?: any;
  errorMessage?: string;
  filename: string;
  file: any;
  onChange: Function;
  handleUpload: () => void;
};

const UploadShopImage: React.FC<Props> = ({
  errorMessage = "",
  filename,
  file,
  onChange,
  handleUpload,
}) => {
  const uploadCard = {
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <GaCard variant={GaCardVariant.OUTLINED}>
        <div style={uploadCard}>
          <GaTypography
            typographyType={TypographyType.SM_BOLD}
            color={TypographyColor.INFO}
          >
            Upload Shop Image *
          </GaTypography>

          <input
            accept="image/*"
            type="file"
            id="shop-image"
            style={{ display: "none" }}
            onChange={(e: any) => onChange(e)}
          />
          <label htmlFor="shop-image">
            <Button
              sx={{ color: "#fff" }}
              variant="contained"
              color="secondary"
              component="span"
            >
              Capture
            </Button>
          </label>
        </div>
      </GaCard>
      <br />

      {file && (
        <GaCard variant={GaCardVariant.OUTLINED}>
          <div style={uploadCard}>
            {file && <img src={file} width="50px" height="50px" />}
            <GaTypography typographyType={TypographyType.SM_BOLD}>
              {filename}
            </GaTypography>
            <Button
              sx={{ color: "#fff" }}
              variant="contained"
              onClick={handleUpload}
              color="secondary"
              component="span"
            >
              Upload
            </Button>
          </div>
        </GaCard>
      )}
      <GaTypography color={TypographyColor.ERROR}>{errorMessage}</GaTypography>
    </div>
  );
};

export default UploadShopImage;
