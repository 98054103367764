import HTTPStatusCode from "../../../domain/enums/httpStatusCode";
import { UpdateDoctorAddress } from "../../../domain/usages/Doctors/update-doctor-address";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteUpdateDoctorAddress implements UpdateDoctorAddress {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}
  async update(
    addressId: string,
    doctorId: string,
    params: UpdateDoctorAddress.Params
  ): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", doctorId).replace(":addressId", addressId),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == HTTPStatusCode.OK) {
      return httpResponse.data;
    }
  }
}
