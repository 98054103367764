import { useEffect } from "react";
import { Button, Modal } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FieldVariant,
  GaSelectField,
  GaTextField,
  OutputValue,
} from "../../ga-components/Inputs";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { Option } from "../../../domain/models/option";
import { Box } from "@mui/system";
import { HospitalMaster } from "../../../domain/models/Hospital/hospitalMaster";

type Props = {
  handleClose: Function;
  open: boolean;
  successCallBack: Function;
  staffTypes: HospitalMaster[];
};

type AddStaffForm = {
  staffType: string;
  name: string;
  mobileNumber: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddPharmacyStaff: React.FC<Props> = ({
  handleClose,
  open,
  successCallBack,
  staffTypes,
}) => {
  const { handleSubmit, control, setValue } = useForm<AddStaffForm>({
    mode: "onChange",
  });

  const onSubmit = (data: AddStaffForm) => {
    successCallBack(data);
  };

  useEffect(() => {
    setValue("staffType", "");
    setValue("name", "");
    setValue("mobileNumber", "");
  }, [open]);

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box sx={style}>
        <div style={{ marginTop: 10, textAlign: "center" }}>
          <GaTypography
            color={TypographyColor.SECONDARY}
            typographyType={TypographyType.BASE_BOLD}
          >
            Add Pharmacy Staff
          </GaTypography>
        </div>

        <div style={{ display: "grid", gap: 10 }}>
          <Controller
            name="staffType"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <GaSelectField
                options={staffTypes.map(
                  (s) => ({ key: s.value, value: s.message } as Option)
                )}
                value={value}
                onChange={onChange}
                outputValue={OutputValue.KEY}
                variant={FieldVariant.OUTLINED}
                title="Staff Type *"
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{ required: { value: true, message: "Field Required" } }}
          />
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <GaTextField
                onChange={onChange}
                value={value}
                title="Name *"
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="mobileNumber"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <GaTextField
                onChange={onChange}
                value={value}
                title="Mobile Number"
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              pattern: {
                value: /^[6-9]{1}[0-9]{9}$/,
                message: "Invalid mobile",
              },
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: 5,
            marginTop: 10,
          }}
        >
          <Button
            variant="contained"
            size="small"
            fullWidth
            color="error"
            onClick={() => handleClose()}
            sx={{ color: "#fff" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            fullWidth
            color="secondary"
            onClick={handleSubmit(onSubmit)}
            sx={{ color: "#fff" }}
          >
            Submit
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddPharmacyStaff;
