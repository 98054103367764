import { Box, Drawer, Stack, Typography } from "@mui/material";

interface HelpAndInfoProps {
  open: boolean;
  handleClose: Function;
}

export const HelpAndInfoModal: React.FC<HelpAndInfoProps> = ({
  open,
  handleClose,
}) => {
  return (
    <Drawer anchor="bottom" open={open} onClose={() => handleClose()}>
      <Box width={300} m={2} pl={2}>
        <Typography variant="h6">Help & Information</Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          N - Name
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          M - Mobile
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          Q - Qualification
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          S - Speciality
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          A - Address
        </Typography>
        <Stack flexDirection={"row"} mt={2} alignItems={"center"}>
          <Box width={15} height={15} bgcolor={"green"} mr={2}></Box>
          <Typography>Verified by data entry team</Typography>
        </Stack>
        <Stack flexDirection={"row"} mt={1} alignItems={"center"}>
          <Box width={15} height={15} bgcolor={"red"} mr={2}></Box>
          <Typography>Not-Verified</Typography>
        </Stack>
      </Box>
    </Drawer>
  );
};
