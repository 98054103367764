import { LoadingButton } from "@mui/lab";
import { Box, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GetAddressByPincode } from "../../domain/usages/get-address-by-pincode";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { AddressFormFields } from "../../domain/models/addressFormFields";
import CloseIcon from "@mui/icons-material/Close";
import { Address } from "../../domain/models/doctor/address";

type Props = {
  open: boolean;
  handleClose: Function;
  getAddressByPincode: GetAddressByPincode;
  successCallback: Function;
  loading: boolean;
  addressData: Address;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddAddressModal: React.FC<Props> = ({
  open,
  handleClose,
  getAddressByPincode,
  successCallback,
  loading,
  addressData,
}) => {
  const { handleSubmit, control, setValue } = useForm<AddressFormFields>({
    mode: "onChange",
  });

  const handleSubmitAddress = (data: AddressFormFields) => {
    successCallback(data);
  };

  const getAddress = async (pincodeValue: string) => {
    let result = await getAddressByPincode.get({ pincode: pincodeValue });
    if (result.success) {
      setValue("state", result.data[0].state);
      setValue("district", result.data[0].district);
      setValue("country", result.data[0].country);
    } else {
      Swal.fire("Invalid Pincode", "", "error");
    }
  };
  let regexPincode = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
  const landmarkRegrex = /^[a-zA-Z0-9]{15,}$/;
  const lineRegrex = /^[a-zA-Z0-9]{15,}$/;
  useEffect(() => {
    if (addressData && addressData._id) {
      setValue(
        "line",
        lineRegrex.test(addressData.line) ? addressData.line : ""
      );
      setValue("state", addressData.state[0]);
      setValue("district", addressData.district);
      setValue("country", addressData.country[0]);
      setValue(
        "landmark",
        landmarkRegrex.test(addressData.landmark) ? addressData.landmark : ""
      );
      setValue(
        "pincode",
        regexPincode.test(addressData.pincode)
          ? addressData.pincode.toString()
          : ""
      );
      setValue("area", addressData.area);
    } else {
      setValue("state", "");
      setValue("district", "");
      setValue("country", "");
      setValue("line", "");
      setValue("landmark", "");
      setValue("pincode", "");
      setValue("area", "");
    }
  }, [addressData._id, open]);

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box sx={style}>
        <Stack alignItems={"end"}>
          <CloseIcon color="error" onClick={() => handleClose()} />
        </Stack>
        <Typography fontWeight={500} variant="body1">
          Enter Doctor Address:
        </Typography>
        <Stack spacing={2} marginTop={2}>
          <Controller
            name="line"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="Line"
                placeholder="Enter line..."
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
              validate: {
                alphanumericLength: (value) => {
                  const alphanumericCount = value.replace(
                    /[^A-Za-z0-9]/g,
                    ""
                  ).length;
                  return (
                    alphanumericCount >= 15 ||
                    "Enter minimum 15 alphanumeric characters"
                  );
                },
              },
            }}
          />
          <Controller
            name="landmark"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="Landmark"
                placeholder="Enter landmark..."
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
              validate: {
                alphanumericLength: (value) => {
                  const alphanumericCount = value.replace(
                    /[^A-Za-z0-9]/g,
                    ""
                  ).length;
                  return (
                    alphanumericCount >= 15 ||
                    "Enter minimum 15 alphanumeric characters"
                  );
                },
              },
            }}
          />
          <Controller
            name="area"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="Area"
                placeholder="Enter Area..."
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="pincode"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="Pincode"
                placeholder="Enter Pincode"
                type="number"
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (e.target.value.length == 6) {
                    getAddress(e.target.value);
                  } else {
                    setValue("state", "");
                    setValue("district", "");
                    setValue("country", "");
                  }
                }}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              pattern: {
                value: regexPincode,
                message: "Invalid Pincode",
              },
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="district"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="District"
                disabled={true}
                value={value ? value : ""}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="state"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="State"
                value={value ? value : ""}
                disabled={true}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="country"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="Country"
                value={value ? value : ""}
                disabled={true}
                onChange={onChange}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />

          {addressData && addressData._id ? (
            <LoadingButton
              loading={loading}
              variant="contained"
              color="secondary"
              onClick={handleSubmit(handleSubmitAddress)}
            >
              <Typography
                fontWeight={550}
                color={"white"}
                textTransform={"none"}
              >
                Update
              </Typography>
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={loading}
              variant="contained"
              color="secondary"
              onClick={handleSubmit(handleSubmitAddress)}
            >
              <Typography
                fontWeight={550}
                color={"white"}
                textTransform={"none"}
              >
                Submit
              </Typography>
            </LoadingButton>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddAddressModal;
