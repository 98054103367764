import { FormControl, FormHelperText } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { GaCard, GaCardVariant } from "../../ga-components/Card";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";

const uploadCard = {
  padding: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

type Props = {
  title: string;
  id: number;
  file: any;
  onFileSelect: Function;
  reset: () => void;
  isLoading: boolean;
  error?: boolean;
  helperText?: string;
  message?: string;
};

// TODO:: Convert all the hard coded styles to theme file.
const UploadFileCard: React.FC<Props> = ({
  title,
  id,
  file,
  onFileSelect,
  reset,
  isLoading = false,
  error = false,
  helperText = "",
  message = "",
}) => {
  if (file) return <UploadedFileCard file={file} reset={reset} />;
  else
    return (
      <FormControl error={error}>
        <FormHelperText sx={{ margin: 0 }}>
          <GaTypography
            typographyType={TypographyType.XS_BOLD}
            color={TypographyColor.PRIMARY}
          >
            {message}{" "}
          </GaTypography>
        </FormHelperText>
        <GaCard variant={GaCardVariant.OUTLINED}>
          <div style={uploadCard}>
            <GaTypography
              typographyType={TypographyType.SM_BOLD}
              color={TypographyColor.INFO}
            >
              {title}
            </GaTypography>

            <input
              accept="image/*"
              capture="environment"
              type="file"
              id={"visiting-card" + id.toFixed()}
              style={{ display: "none" }}
              onChange={(e: any) => onFileSelect(e.target.files[0])}
            />
            <label htmlFor={"visiting-card" + id.toFixed()}>
              <Button
                sx={{ color: "#fff" }}
                variant="contained"
                color="secondary"
                component="span"
              >
                {isLoading ? "Uploading..." : "Capture"}
              </Button>
            </label>
          </div>
        </GaCard>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
};

type UploadedProps = {
  file: File;
  reset: () => void;
};

//TODO:: instead of hard coding the width and height it should be from style file.
const UploadedFileCard: React.FC<UploadedProps> = ({ file, reset }) => {
  return (
    <GaCard variant={GaCardVariant.OUTLINED}>
      <div style={uploadCard}>
        {file && (
          <img src={URL.createObjectURL(file)} width="50px" height="50px" />
        )}
        <GaTypography typographyType={TypographyType.SM_BOLD}>
          {file.name}
        </GaTypography>
        <Button
          sx={{ color: "#fff" }}
          variant="contained"
          onClick={() => reset()}
          color="secondary"
          component="span"
        >
          Reset
        </Button>
      </div>
    </GaCard>
  );
};

export default UploadFileCard;
