import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useGeoLocation from "../../../domain/hooks/useGeoLocation";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import { ConfirmFileUpload } from "../../../domain/usages/confirm-file-upload";
import { GetDoctorDetails } from "../../../domain/usages/Doctors/get-doctor-details";
import { GenerateFileUploadUrl } from "../../../domain/usages/generate-file-upload-url";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import { AddMoreImages } from "../../../domain/usages/add-more-image";
import { UploadFile } from "../../../domain/usages/upload-file";
import { pageRoutes } from "../../../routes";
import DoctorDetails from "../../components/Doctor/DoctorDetails";
import MappedChemists from "../../components/Doctor/MappedChemists";
import Header from "../../components/Header/Header";
import ImageCards from "../../components/ImageCards";
import AddMoreImagesComponent from "../../components/UploadButton/AddMoreImagesComponent";
import useFileUpload from "../../hooks/useFileUpload";
import Swal from "sweetalert2";
import { Button, Stack } from "@mui/material";
import EnrollProgramModal from "../../components/Doctor/EnrollProgramModal";
import SelectedProgram from "../../components/Doctor/SelectedProgram";
import { GetAllProgramProducts } from "../../../domain/usages/Doctors/get-all-program-products";
import { GetEnrolledPrograms } from "../../../domain/usages/Doctors/get-enrolled-programs";
import { EnrollDoctorForProgram } from "../../../domain/usages/Doctors/enroll-doctor-for-program";
import { DoctorProgram } from "../../../domain/models/doctor/doctorProgram";
import { EnrolledProgram } from "../../../domain/models/doctor/enrolled-program";
import { FetchState } from "../../../domain/models/fetch-state-type";
import LoadingSkeleton from "../../components/Chemist/LoadingSkeleton";
import { GetAddressByPincode } from "../../../domain/usages/get-address-by-pincode";
import { StoreDoctorAddress } from "../../../domain/usages/Doctors/store-address";
import { AddDoctorContacts } from "../../../domain/usages/Doctors/add-doctor-contacts";
import { ConfirmDoctorAddress } from "../../../domain/usages/Doctors/confirm-doctor-address";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import { UpdateDoctorAddress } from "../../../domain/usages/Doctors/update-doctor-address";

const useReuploadImage = (
  ReuploadImage: AddMoreImages
): [boolean, Function, any] => {
  const geoLocation = useGeoLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();

  const submit = useCallback(
    async (
      imageType: string,
      file: string,
      id: string,
      handleOpenDialog: Function
    ) => {
      setIsLoading(true);
      const payload = {
        images: [
          {
            type: imageType,
            uuid: file,
            latLong: geoLocation?.latitude + "," + geoLocation?.longitude,
          },
        ],
      };
      let result = await ReuploadImage.reupload(id, payload);
      if (result.success) {
        setResponse(result);
        setIsLoading(false);
        Swal.fire("Image Added successfully.", "", "success");
      } else {
        handleOpenDialog();
        Swal.fire(result.message, "", "error");
      }
    },
    [ReuploadImage, geoLocation]
  );

  return [isLoading, submit, response];
};

type Props = {
  getDoctorDetails: GetDoctorDetails;
  getReadLink: GetReadLink;
  generateUploadURL: GenerateFileUploadUrl;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmFileUpload;
  ReuploadImage: AddMoreImages;
  getAllProgramProducts: GetAllProgramProducts;
  getEnrolledPrograms: GetEnrolledPrograms;
  enrollDoctorForProgram: EnrollDoctorForProgram;
  getAddressByPincode: GetAddressByPincode;
  storeAddress: StoreDoctorAddress;
  addContacts: AddDoctorContacts;
  confirmAddress: ConfirmDoctorAddress;
  updateAddress: UpdateDoctorAddress;
};

const DoctorProfilePage: React.FC<Props> = ({
  getDoctorDetails,
  getReadLink,
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
  ReuploadImage,
  getAllProgramProducts,
  getEnrolledPrograms,
  enrollDoctorForProgram,
  getAddressByPincode,
  storeAddress,
  addContacts,
  confirmAddress,
  updateAddress,
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [doctorDetails, setDoctorDetails] = useState<DoctorData>(
    {} as DoctorData
  );
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [programProducts, setProgramProducts] = useState<DoctorProgram[]>([]);
  const [enrolledDoctorPrograms, setEnrolledDoctorPrograms] = useState<
    EnrolledProgram[]
  >([]);
  const [openEnrollProgramModal, setOpenEnrollProgramModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(true);
  const [imageType, setImageType] = useState("");

  const getDetails = async (id: string) => {
    if (id) {
      setFetchState(FetchState.LOADING);
      try {
        let result = await getDoctorDetails.get(id);
        if (result.success) {
          setDoctorDetails(result.data);
          enrolledPrograms(result.data._id);
        }
      } catch (err) {}
    }
  };

  const getProgramProducts = async (id: string) => {
    let result = await getAllProgramProducts.get(id);
    if (result.success) {
      setProgramProducts(result.data);
    }
  };

  const enrolledPrograms = async (id: string) => {
    let result = await getEnrolledPrograms.get(id);
    if (result.success && result.data) {
      setEnrolledDoctorPrograms(result.data);
      setFetchState(FetchState.SUCCESS);
    } else {
      setFetchState(FetchState.SUCCESS);
    }
  };

  const enrollDoctorforProgram = async (name: string) => {
    let payload = {
      code: name,
    };

    let result = await enrollDoctorForProgram.add(doctorDetails._id, payload);

    if (result.success) {
      handleCloseProgramModal();
      Swal.fire("Enrolled for Program Successfully", "", "success");
      getDetails(doctorDetails._id);
    } else if (result.statusCode && Array.isArray(result.message)) {
      handleCloseProgramModal();
      Swal.fire(result.message[0].message, "", "error");
    } else if (result.statusCode && !Array.isArray(result.message)) {
      handleCloseProgramModal();
      Swal.fire(result.message, "", "error");
    } else if (!result.success) {
      handleCloseProgramModal();
      if (result.errors.type) {
        Swal.fire(result.errors.type, "", "error");
      } else {
        Swal.fire("Program Enrollment Failed", "", "error");
      }
    }
  };

  const [submitting, submit, response] = useReuploadImage(ReuploadImage);

  const handleEnrollProgramClick = () => {
    getProgramProducts(location.state.id);
    setOpenEnrollProgramModal(true);
  };

  const handleCloseProgramModal = () => {
    setOpenEnrollProgramModal(false);
  };

  useEffect(() => {
    getDetails(location.state.id);
  }, [response]);

  const [fileUpload, processingFile, processFile, resetFile] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "INVOICE_IMAGE"
  );

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };
  const handleSubmit = () => {
    submit(imageType, fileUpload?.uuid, doctorDetails._id, handleOpenDialog);
  };

  const handleImageType = (event: any) => {
    setImageType(event.target.value as string);
  };

  useEffect(() => {
    setImageType("");
  }, [fileUpload?.uuid]);

  const imageTypeOptions = [
    {
      key: "1",
      value: "visiting_card",
    },
  ];

  return (
    <>
      {fetchState === FetchState.LOADING && <LoadingSkeleton />}
      {fetchState === FetchState.SUCCESS && (
        <div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Header
                title={""}
                showBackButton
                handleClick={() =>
                  navigate(pageRoutes.customerProfiler, {
                    state: {
                      value: 0,
                    },
                  })
                }
              />
              {doctorDetails.eligiblePrograms &&
                doctorDetails.eligiblePrograms.length > 0 && (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={handleEnrollProgramClick}
                    sx={{ color: "#fff", height: 30, marginRight: 2 }}
                  >
                    Enroll For Program
                  </Button>
                )}
            </div>
            <div style={{ margin: "32px", marginTop: 0 }}>
              <DoctorDetails
                doctorDetails={doctorDetails}
                getAddressByPincode={getAddressByPincode}
                storeAddress={storeAddress}
                getDetails={getDetails}
                addContacts={addContacts}
                confirmAddress={confirmAddress}
                updateAddress={updateAddress}
              />
              <Stack
                flexDirection={"row"}
                gap={2}
                alignItems={"center"}
                marginTop={2}
              >
                <GaTypography typographyType={TypographyType.SM}>
                  Image Uploaded:
                </GaTypography>
                <AddMoreImagesComponent
                  id={"6"}
                  onFileSelect={(file: File) => processFile(file)}
                  file={fileUpload?.file}
                  reset={() => resetFile()}
                  isLoading={processingFile}
                  submit={handleSubmit}
                  openDialog={openDialog}
                  imageType={imageType}
                  handleImageType={handleImageType}
                  handleOpenDialog={handleOpenDialog}
                  imageTypeOptions={imageTypeOptions}
                />
              </Stack>

              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  flexDirection: "row",
                  overflowX: "auto",
                  height: "140px",
                }}
              >
                {doctorDetails.images?.map((image) => {
                  return (
                    <ImageCards
                      getReadLink={getReadLink}
                      uuid={image.uuid}
                      imageDetails={image}
                    />
                  );
                })}
              </div>
            </div>
            <div style={{ margin: "16px", marginTop: 0 }}>
              <MappedChemists doctorDetails={doctorDetails} />
            </div>

            <div style={{ margin: "16px", marginTop: 0 }}>
              {enrolledDoctorPrograms.length > 0 && (
                <SelectedProgram
                  enrolledProgramDetails={enrolledDoctorPrograms}
                />
              )}
            </div>
          </div>
          <EnrollProgramModal
            open={openEnrollProgramModal}
            handleClose={handleCloseProgramModal}
            successCallBack={enrollDoctorforProgram}
            programProducts={programProducts}
          />
        </div>
      )}
    </>
  );
};

export default DoctorProfilePage;
