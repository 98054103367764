import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  HOSPITAL_PROFILER_API_URL,
} from "../../base";
import { RemoteDeleteMappedPharmacy } from "../../data/usecases/Hospitals/remote-delete-mapped-pharmacy";
import { RemoteFetchHospitals } from "../../data/usecases/Hospitals/remote-fetch-hospitals";
import { RemoteGetHospitalPharmacy } from "../../data/usecases/Hospitals/remote-get-hospital-pharmacy";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import HospitalsListSummary from "../../presentation/components/Hospital/HospitalsListSummary";

export const HospitalsFetchingFactory = () => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const remoteGetHospitalPharmacy = new RemoteGetHospitalPharmacy(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.GET_HOSPITAL_PHARMACY}`,
    axiosHttpClient
  );

  const remoteFetchHospitals = new RemoteFetchHospitals(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.FETCH_HOSPITALS}`,
    axiosHttpClient
  );
  const remoteDeleteMappedPharmacy = new RemoteDeleteMappedPharmacy(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.DELETE_MAPPED_PHARMACY}`,
    axiosHttpClient
  );
  return (
    <HospitalsListSummary
      fetchHospitals={remoteFetchHospitals}
      getHospitalPharmacy={remoteGetHospitalPharmacy}
      deleteMappedPharmacy={remoteDeleteMappedPharmacy}
    />
  );
};
