import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  HOSPITAL_PROFILER_API_URL,
} from "../../base";
import { RemoteFetchDoctors } from "../../data/usecases/Doctors/remote-fetch-doctors";
import { RemoteAddDoctorsToHospital } from "../../data/usecases/Hospitals/remote-add-hospitals-to-hospital copy";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import HospitalDoctorMapping from "../../presentation/pages/Hospital/HospitalDoctorMapping";

export const HospitalDoctorMappingFactory = () => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const remoteAddDoctorsToHospital = new RemoteAddDoctorsToHospital(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.ADD_DOCTORS_TO_HOSPITAL}`,
    axiosHttpClient
  );

  const remoteFetchDoctors = new RemoteFetchDoctors(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.FETCH_DOCTORS}`,
    axiosHttpClient
  );

  return (
    <HospitalDoctorMapping
      fetchBrickDoctors={remoteFetchDoctors}
      mapDoctor={remoteAddDoctorsToHospital}
    />
  );
};
