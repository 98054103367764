import HTTPStatusCode from "../../../domain/enums/httpStatusCode";
import { ConfirmDoctorAddress } from "../../../domain/usages/Doctors/confirm-doctor-address";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteConfirmDoctorAddress implements ConfirmDoctorAddress {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}
  async confirm(
    addressId: string,
    doctorId: string,
    params: ConfirmDoctorAddress.Params
  ): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", doctorId).replace(":addressId", addressId),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == HTTPStatusCode.OK) {
      return httpResponse.data;
    }
  }
}
