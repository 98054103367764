import { Skeleton } from "@mui/material";

type Props = {};

const LoadingSkeleton = (props: Props) => {
  return (
    <div style={{ margin: 10 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Skeleton animation="wave" width={100} height={100} />
        <Skeleton animation="wave" width={100} height={100} />
      </div>
      <div>
        <Skeleton animation="wave" width={200} height={100} />
      </div>
      <div>
        <Skeleton animation="wave" height={150} />
      </div>
      <Skeleton animation="wave" height={100} />
      <Skeleton animation="wave" height={100} />
      <Skeleton animation="wave" height={100} />
    </div>
  );
};

export default LoadingSkeleton;
