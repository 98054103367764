import { useCallback, useState } from "react";
import { ConfirmFileUpload } from "../../domain/usages/confirm-file-upload";
import { GenerateFileUploadUrl } from "../../domain/usages/generate-file-upload-url";
import { UploadFile } from "../../domain/usages/upload-file";
import {v4 as uuidv4} from 'uuid';

let myuuid = uuidv4();

export type FileUpload = {
  file: File;
  uuid?: string;
  url?: string;
  success: boolean;
  error?: string;
  previewUrl: string;
  uploadedUrl?: string;
  localUuid?: string;
};

const useMultiFileUpload = (
  generateUrl: GenerateFileUploadUrl,
  upload: UploadFile,
  confirm: ConfirmFileUpload,
  category?: string
): [FileUpload[], boolean, (files: File[]) => void, () => void, (file: File, index: number) => void] => {
  
  const [fileUploads, setFileUploads] = useState<FileUpload[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const reset = () => {
    setFileUploads([]);
  };

  const uploadFiles = async (files: File[]) => {
    setIsLoading(true);
    const uploads = files.map(async (file) => {
      const previewUrl = URL.createObjectURL(file);
      const fileUpload: FileUpload = { file, success: false, previewUrl, localUuid: myuuid };
      return uploadFile(fileUpload);
    });

    const results = await Promise.all(uploads);
    setFileUploads((prev) => [...prev, ...results]);
    setIsLoading(false);
  };

  const uploadFile = async (fileUpload: FileUpload) => {
    try {
      const generated = await generateUploadUrl(fileUpload);
      const uploaded = await uploadToBucket(generated);
      const confirmed = await confirmUpload(uploaded);
      return confirmed;
    } catch (error:any) {
      console.error(error);
      return { ...fileUpload, success: false, error: error.message };
    }
  };

  const generateUploadUrl = useCallback(
    async (fileUpload: FileUpload) => {
      const payload: any = {
        name: fileUpload.file.name,
        size: fileUpload.file.size,
        type: fileUpload.file.type,
      };
      if (category) {
        payload["category"] = category;
      }
      const result = await generateUrl.generate(payload);
      if (result.uuid) {
        return { ...fileUpload, uuid: result.uuid, url: result.url, success: true };
      } else {
        throw new Error("Couldn't generate url.");
      }
    },
    [generateUrl, category]
  );

  const uploadToBucket = useCallback(
    async (fileUpload: FileUpload) => {
      if (fileUpload.url) {
        const result = await upload.upload({ file: fileUpload.file, url: fileUpload.url });
        if (result) {
          return { ...fileUpload, success: true };
        } else {
          throw new Error("Couldn't upload to bucket.");
        }
      } else {
        throw new Error("Upload URL not available.");
      }
    },
    [upload]
  );

  const confirmUpload = useCallback(
    async (fileUpload: FileUpload) => {
      if (fileUpload.uuid) {
        const result = await confirm.confirm({ uuid: fileUpload.uuid });
        if (result.success) {
          return { ...fileUpload, success: true, uploadedUrl: fileUpload.url };
        } else {
          throw new Error("Couldn't confirm the upload.");
        }
      } else {
        throw new Error("UUID not available for confirmation.");
      }
    },
    [confirm]
  );

  const reuploadFile = async (newFile: File, index: number) => {
    setIsLoading(true);
    const previewUrl = URL.createObjectURL(newFile);
    const newFileUpload: FileUpload = { file: newFile, success: false, previewUrl };
    const result = await uploadFile(newFileUpload);
    setFileUploads((prev) =>
      prev.map((upload, i) => (i === index ? result : upload))
    );
    setIsLoading(false);
  };

  return [fileUploads, isLoading, uploadFiles, reset, reuploadFile];
};

export default useMultiFileUpload;
