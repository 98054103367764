import { create } from "zustand";
import { getChemistBrickSlice } from "../slice/chemist/FetchBricksSlice";
import { getChemistRegistrationSlice } from "../slice/chemist/chemistTypeSlice";
import useChemistNewFormDetails from "../slice/chemist/chemistRegistrationSlice";
import { useCheckMobile } from "../slice/chemist/checkMobileSlice";
import { useUploadRegDetails } from "../slice/chemist/PostRegistrationDetails";
import { GenerateUploadUrlSlice } from "../slice/chemist/GenerateUploadURL";
import { confirmUploadFileSlice } from "../slice/chemist/ConfirmFileUpload";
import { UploadFileSlice } from "../slice/chemist/UploadFileSlice";
import useStepperSlice from "../slice/Stepper";

interface ChemistStoreInterface
  extends ReturnType<typeof getChemistRegistrationSlice>,
    ReturnType<typeof getChemistBrickSlice>,
    ReturnType<typeof useChemistNewFormDetails>,
    ReturnType<typeof useCheckMobile>,
    ReturnType<typeof useUploadRegDetails>,
    ReturnType<typeof GenerateUploadUrlSlice>,
    ReturnType<typeof confirmUploadFileSlice>,
    ReturnType<typeof UploadFileSlice>,
    ReturnType<typeof useStepperSlice>

    
    
    {}

export const ChemistStore = create<ChemistStoreInterface>(
  (...data) => ({
    ...getChemistRegistrationSlice(...data),
 ...getChemistBrickSlice(...data),
 ...useChemistNewFormDetails(...data),
 ...useCheckMobile(...data),
 ...useUploadRegDetails(...data),
 ...GenerateUploadUrlSlice(...data),
 ...confirmUploadFileSlice(...data),
 ...UploadFileSlice(...data),
 ...useStepperSlice(...data),
    // Add other slices here...
  })
);
