import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import jwt_decode from "jwt-decode";
import { HospitalData } from "../../../domain/models/Hospital/hospitalData";
import { HospitalMappedPharmacy } from "../../../domain/models/Hospital/hospitalMappedPharmacy";
import { Stack, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { DeleteMappedPharmacy } from "../../../domain/usages/Hospitals/delete-mapped-pharmacy";

type userProps = {
  data: HospitalMappedPharmacy;
  hospitalData: HospitalData;
  handleOpenConfirmModal: Function;
};

const BrickHospitalPharmacyDetails: React.FC<userProps> = ({
  data,
  hospitalData,
  handleOpenConfirmModal,
}) => {
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>();

  const titleStyles = {
    color: "#212121",
    width: "100%",
  };
  const subTitleStyle = {
    color: "#6B6B6B",
    margin: 2,
  };

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }
  }, []);

  return (
    <div style={{ fontFamily: "Roboto" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack
          style={titleStyles}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="caption">{data.name}</Typography>
          {data.name && (
            <CancelIcon
              fontSize="small"
              color="error"
              onClick={() => {
                handleOpenConfirmModal(true, hospitalData, data);
              }}
            />
          )}
        </Stack>

        {/* {hospitalData.mappings?.map((mappingData, index) => {
          if (
            mappingData.hqCode == loggedInUserDetails?.hq_code &&
            mappingData.brickCode
          ) {
            return (
              <div key={1} style={subTitleStyle}>
                <GaTypography typographyType={TypographyType.SM}>
                  {mappingData.brickName}
                </GaTypography>
              </div>
            );
          }
        })} */}
      </div>

      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
    </div>
  );
};

export default BrickHospitalPharmacyDetails;
