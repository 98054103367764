import { StateCreator } from "zustand";
import { AUTH_API_URL, AUTH_HEADER, AUTH_HEADER_CHANNELPAY,  AUTH_TOKEN_KEY } from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import Endpoints from "../../../domain/endpoints";
import { RemoteFetchChemistTypes } from "../../../data/usecases/Chemists/remote-fetch-chemist-types";
import { ChemistType, ChemistTypeResponse } from "../../../domain/models/chemist/chemistType";

interface ChemistDetailsSliceInterface {
  getChemistTypeDetails: () => Promise<void>; 
  ChemistTypeDetails: ChemistType[]; 
}

const initialStates: ChemistDetailsSliceInterface = {
  ChemistTypeDetails: [], 
  getChemistTypeDetails: async () => {}, 
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const getChemistRegistrationSlice: StateCreator<ChemistDetailsSliceInterface> = (
  set,
  get
) => ({
  ...initialStates,
  getChemistTypeDetails: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);

    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER_CHANNELPAY]:token }); 

    const remoteFetchChemistTypes = new RemoteFetchChemistTypes(
      `${AUTH_API_URL}${Endpoints.FETCH_CHEMIST_TYPES}`,
      axiosHttpClient
    );

    try {
      const result:ChemistTypeResponse = await remoteFetchChemistTypes.fetch({
          category: ""
      });

      if (result.success) {
        set((state) => ({
          ChemistTypeDetails: result.customer_types,
        }));
      } else {
        console.error("Remote fetch failed:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
});
