import {
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import AddIcon from "@mui/icons-material/Add";
import { FieldVariant, GaSelectField } from "../../ga-components/Inputs";
import { Option } from "../../../domain/models/option";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

const uploadCard = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

type Props = {
  id?: string;
  file: any;
  onFileSelect: Function;
  reset: () => void;
  error?: boolean;
  helperText?: string;
  submit: Function;
  openDialog: boolean;
  imageType: string;
  handleImageType: Function;
  handleOpenDialog: Function;
  isLoading: boolean;
  imageTypeOptions: Option[];
};

const AddMoreImagesComponent: React.FC<Props> = ({
  id,
  file,
  onFileSelect,
  reset,
  error = false,
  helperText = "",
  submit,
  openDialog,
  handleImageType,
  imageType,
  handleOpenDialog,
  isLoading = false,
  imageTypeOptions,
}) => {
  useEffect(() => {
    handleOpenDialog();
  }, [file]);

  return (
    <>
      <FormControl error={error}>
        <div style={uploadCard}>
          <input
            accept="image/*"
            capture="environment"
            type="file"
            id={id}
            style={{ display: "none" }}
            onChange={(e: any) => onFileSelect(e.target.files[0])}
          />
          <label htmlFor={id}>
            <LoadingButton
              variant="outlined"
              color="secondary"
              size="small"
              component="span"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<AddIcon fontSize="small" />}
            >
              <Typography variant="caption" fontSize={"10px"} fontWeight={660}>
                ADD MORE IMAGES
              </Typography>
            </LoadingButton>
          </label>
        </div>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>

      <Dialog open={file && openDialog ? true : false} fullWidth>
        <DialogContent sx={{ display: "flex", justifyContent: "end" }}>
          <CloseIcon onClick={() => handleOpenDialog()} />
        </DialogContent>

        <div
          style={{
            paddingBlock: 10,
            display: "grid",
            gap: 10,
            justifyContent: "center",
          }}
        >
          <div>
            {file && (
              <>
                <img
                  src={URL.createObjectURL(file)}
                  width="50px"
                  height="50px"
                />

                <GaTypography typographyType={TypographyType.SM_BOLD}>
                  {file?.name}
                </GaTypography>
              </>
            )}
          </div>
        </div>
        <div style={{ padding: 10, display: "grid", gap: 20 }}>
          <GaSelectField
            options={imageTypeOptions}
            value={imageType}
            onChange={handleImageType}
            variant={FieldVariant.OUTLINED}
            title="Image Type"
          ></GaSelectField>
          {imageType && (
            <>
              <Button
                sx={{ color: "#fff" }}
                variant="contained"
                onClick={() => submit()}
                color="secondary"
                component="span"
              >
                SUBMIT
              </Button>
              <Button
                sx={{ color: "#fff" }}
                variant="contained"
                onClick={() => reset()}
                color="error"
                component="span"
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default AddMoreImagesComponent;
