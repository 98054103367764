import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Filter } from "../../../domain/models/filter";
import SkeletonCard from "../../ga-components/SkeletonCard";
import HospitalCard from "./HospitalCard";
import HospitalSummary from "./HospitalSummary";
import InfiniteScroll from "react-infinite-scroll-component";
import { FetchHospitals } from "../../../domain/usages/Hospitals/fetch-hospitals";
import { HospitalData } from "../../../domain/models/Hospital/hospitalData";
import { GetHospitalPharmacy } from "../../../domain/usages/Hospitals/get-hospital-pharmacy";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import jwt_decode from "jwt-decode";
import { DeleteMappedPharmacy } from "../../../domain/usages/Hospitals/delete-mapped-pharmacy";

type Props = {
  fetchHospitals: FetchHospitals;
  getHospitalPharmacy: GetHospitalPharmacy;
  deleteMappedPharmacy: DeleteMappedPharmacy;
};

const HospitalsListSummary: React.FC<Props> = ({
  fetchHospitals,
  getHospitalPharmacy,
  deleteMappedPharmacy,
}) => {
  const [hospitalList, setHospitalList] = useState<HospitalData[]>([]);
  const [totalDoctors, setTotalDoctors] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [searchFilter, setSearchFilter] = useState("");
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>({} as LoggedInUserDetails);

  const fetchHospitalsList = async (
    page: number,
    scrolled: boolean,
    hqCode: string
  ) => {
    const filter = {
      q: searchFilter,
      page: page,
      limit: 20,
      hqCode: hqCode,
    };
    try {
      if (!scrolled) {
        setFetchState(FetchState.LOADING);
      }
      let result = await fetchHospitals.fetch(filter);
      if (result.success) {
        setCurrentPage(result.data.page);
        setLastPage(result.data.totalPages);
        setTotalDoctors(result.data.total);
        if (scrolled) {
          setHospitalList((oldData) => [...oldData, ...result.data.result]);
        } else {
          setHospitalList(result.data.result);
        }
        setFetchState(FetchState.SUCCESS);
      }
    } catch (err) {
      setFetchState(FetchState.ERROR);
    }
  };

  const cardStyle = {
    margin: "30px 20px 20px 20px",
    borderColor: "#94D2BD",
    width: "20rem",
  };
  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    let tokenDetails: LoggedInUserDetails;
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
      tokenDetails = jwt_decode(token);
      fetchHospitalsList(1, false, tokenDetails.hq_code);
    }
  }, [searchFilter]);

  const handleSearch = (e: any) => {
    setSearchFilter(e.target.value);
  };
  const debouncedOnChange = debounce(handleSearch, 2000);

  const hasMoreData = () => {
    return fetchState == FetchState.SUCCESS ? currentPage < lastPage : false;
  };
  return (
    <>
      <div style={{ margin: 20 }}>
        <SearchIcon
          sx={{ position: "absolute", marginTop: 1, marginLeft: 1 }}
        />
        <input
          style={{
            width: "90%",
            padding: 10,
            textIndent: 30,
            borderRadius: "4px",
          }}
          onChange={debouncedOnChange}
          placeholder="Search Hospital..."
        />
      </div>
      <HospitalSummary count={totalDoctors} />
      <div
        id="div"
        style={{
          overflow: "auto",
          height: "60%",
          position: "fixed",
          width: "100%",
        }}
      >
        <InfiniteScroll
          scrollableTarget="div"
          dataLength={hospitalList?.length}
          next={() => {
            fetchHospitalsList(
              currentPage + 1,
              true,
              loggedInUserDetails.hq_code
            );
          }}
          hasMore={hasMoreData()}
          loader={<h4>Loading...</h4>}
        >
          {fetchState === FetchState.LOADING ? (
            <div>
              <div style={cardStyle}>
                <SkeletonCard />
              </div>
              <div style={cardStyle}>
                <SkeletonCard />
              </div>
              <div style={cardStyle}>
                <SkeletonCard />
              </div>
            </div>
          ) : (
            <HospitalCard
              hospitalCardDetails={hospitalList}
              getHospitalPharmacy={getHospitalPharmacy}
              deleteMappedPharmacy={deleteMappedPharmacy}
            />
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default HospitalsListSummary;
