import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import { pageRoutes } from "../../../routes";
import Header from "../../components/Header/Header";
import ImageCards from "../../components/ImageCards";
import Swal from "sweetalert2";
import { FetchState } from "../../../domain/models/fetch-state-type";
import LoadingSkeleton from "../../components/Chemist/LoadingSkeleton";
import HospitalDetails from "../../components/Hospital/HospitalDetails";
import { GetHospital } from "../../../domain/usages/Hospitals/get-hospital";
import { HospitalData } from "../../../domain/models/Hospital/hospitalData";
import { GetHospitalPharmacy } from "../../../domain/usages/Hospitals/get-hospital-pharmacy";
import { HospitalMappedPharmacy } from "../../../domain/models/Hospital/hospitalMappedPharmacy";
import MappedPharmacy from "../../components/Hospital/MappedPharmacy";
import MappedHospitalDoctors from "../../components/Hospital/MappedHospitalDoctors";
import AddIcon from "@mui/icons-material/Add";
import { AddStaffToPharmacy } from "../../../domain/usages/Hospitals/add-staff-to-pharmacy";
import AddPharmacyStaff from "../../components/Hospital/AddPharmacyStaff";
import { FetchHospitalMaster } from "../../../domain/usages/Hospitals/fetch-hospital-master";
import { HospitalMaster } from "../../../domain/models/Hospital/hospitalMaster";
import AddMoreImagesComponent from "../../components/UploadButton/AddMoreImagesComponent";
import useGeoLocation from "../../../domain/hooks/useGeoLocation";
import { AddMoreImages } from "../../../domain/usages/add-more-image";
import { ConfirmFileUpload } from "../../../domain/usages/confirm-file-upload";
import { UploadFile } from "../../../domain/usages/upload-file";
import { GenerateFileUploadUrl } from "../../../domain/usages/generate-file-upload-url";
import useFileUpload from "../../hooks/useFileUpload";
import { Button, Icon, Typography } from "@mui/material";
import QuestionaireModal from "../../components/Hospital/QuestionaireModal";
import { SaveHospitalQuestionaireData } from "../../../domain/usages/Hospitals/save-hospital-questionaire-data";
import { GetHospitalQuestionaireData } from "../../../domain/usages/Hospitals/get-hospital-questionaire-data";
import { DeleteMappedPharmacy } from "../../../domain/usages/Hospitals/delete-mapped-pharmacy";
import ConfirmModal from "../../components/ConfirmModal";

const useReuploadImage = (
  ReuploadImage: AddMoreImages
): [boolean, Function, any] => {
  const geoLocation = useGeoLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();

  const submit = useCallback(
    async (
      imageType: string,
      file: string,
      id: string,
      handleOpenDialog: Function
    ) => {
      setIsLoading(true);
      const payload = {
        images: [
          {
            type: imageType,
            uuid: file,
            latLong: geoLocation?.latitude + "," + geoLocation?.longitude,
          },
        ],
        source: "cpay",
      };
      let result = await ReuploadImage.reupload(id, payload);
      if (result.success) {
        setResponse(result);
        setIsLoading(false);
        Swal.fire("Image Added successfully.", "", "success");
      } else {
        handleOpenDialog();
        setIsLoading(false);
        Swal.fire("Image Added Failed.", "", "error");
      }
    },
    [ReuploadImage, geoLocation]
  );

  return [isLoading, submit, response];
};

type Props = {
  getHospital: GetHospital;
  getReadLink: GetReadLink;
  getHospitalPharmacy: GetHospitalPharmacy;
  addStaffToPharmacy: AddStaffToPharmacy;
  fetchHospitalMaster: FetchHospitalMaster;
  ReuploadImage: AddMoreImages;
  generateUploadURL: GenerateFileUploadUrl;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmFileUpload;
  saveHospitalQuestionaireData: SaveHospitalQuestionaireData;
  getHospitalQuestionaireData: GetHospitalQuestionaireData;
  deleteMappedPharmacy: DeleteMappedPharmacy;
};

type AddStaffForm = {
  staffType: string;
  name: string;
  mobileNumber: string;
};

const HospitalProfilePage: React.FC<Props> = ({
  getHospital,
  getReadLink,
  getHospitalPharmacy,
  addStaffToPharmacy,
  fetchHospitalMaster,
  ReuploadImage,
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
  saveHospitalQuestionaireData,
  getHospitalQuestionaireData,
  deleteMappedPharmacy,
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [hospitalDetails, setHospitalDetails] = useState<HospitalData>(
    {} as HospitalData
  );
  const [fetchState, setFetchState] = useState(FetchState.SUCCESS);
  const [mappedPharmacy, setMappedPharmacy] = useState<HospitalMappedPharmacy>(
    {} as HospitalMappedPharmacy
  );
  const [openAddStaffForm, setOpenAddStaffForm] = useState(false);
  const [staffTypes, setStaffTypes] = useState<HospitalMaster[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [imageType, setImageType] = useState("");
  const [openQuestionaireModal, setOpenQuestionaireModal] = useState(false);

  const imageTypeOptions = [
    {
      key: "1",
      value: "banner",
    },
  ];

  const [fileUpload, processingFile, processFile, resetFile] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "INVOICE_IMAGE"
  );

  const [submitting, submit, response] = useReuploadImage(ReuploadImage);

  const getDetails = async (id: string) => {
    const query = {
      imageUrls: "true",
    };
    if (id) {
      setFetchState(FetchState.LOADING);
      try {
        let result = await getHospital.get(id, query);
        if (result) {
          setFetchState(FetchState.SUCCESS);
          setHospitalDetails(result.data);
          fetchMappedPharmacy(result.data._id);
        }
      } catch (err) {}
    }
  };

  useEffect(() => {
    fetchMaster();
    if (location.state.id) {
      getDetails(location.state.id);
    }
  }, [location.state.id, response]);

  const fetchMappedPharmacy = async (id: string) => {
    if (id) {
      let result = await getHospitalPharmacy.get(id);
      if (result) {
        setMappedPharmacy(result.data);
      } else {
        setMappedPharmacy({} as HospitalMappedPharmacy);
      }
    }
  };

  const fetchMaster = async () => {
    let result = await fetchHospitalMaster.fetch();
    if (result.success) {
      setStaffTypes(result.data.staffTypes);
    }
  };

  const handleCloseAddStaffForm = () => {
    setOpenAddStaffForm(false);
  };

  const loadingIndicator = (loading: boolean) => {
    if (loading) {
      Swal.fire({
        title: "Please wait... processing request",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  const addStaff = async (data: AddStaffForm) => {
    let payload = {
      staff: {
        name: data.name,
        type: data.staffType,
        contacts: [
          {
            type: "mobile",
            value: data.mobileNumber,
            status: "active",
          },
        ],
        source: "cpay",
      },
    };
    setOpenAddStaffForm(false);
    loadingIndicator(true);

    let result = await addStaffToPharmacy.add(hospitalDetails._id, payload);
    if (result.success) {
      loadingIndicator(false);
      setOpenAddStaffForm(false);
      Swal.fire("Staff Added Successfully", "", "success");
      getDetails(hospitalDetails._id);
    } else if (result.statusCode && Array.isArray(result.message)) {
      loadingIndicator(false);
      setOpenAddStaffForm(false);
      Swal.fire(result.message[0].message, "", "error");
    } else if (result.statusCode && !Array.isArray(result.message)) {
      loadingIndicator(false);
      setOpenAddStaffForm(false);
      Swal.fire(result.message, "", "error");
    } else if (!result.success) {
      loadingIndicator(false);
      setOpenAddStaffForm(false);
      Swal.fire(result.message, "", "error");
    } else {
      loadingIndicator(false);
      setOpenAddStaffForm(false);
      Swal.fire(result.errors[0].message, "", "error");
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };
  const handleSubmit = () => {
    submit(imageType, fileUpload?.uuid, hospitalDetails._id, handleOpenDialog);
  };

  const handleImageType = (event: any) => {
    setImageType(event.target.value as string);
  };

  const handleQuestionaire = () => {
    setOpenQuestionaireModal(true);
  };

  const handleRXTherapy = () => {
    // setOpenRXTherapyModal(true);
    navigate(pageRoutes.hospitalTherapyPage, {
      state: {
        id: hospitalDetails._id,
      },
    });
  };
  const handleCloseQuestionaireModal = () => {
    setOpenQuestionaireModal(false);
  };

  const [showConfirmModal, setShowConfimModal] = useState(false);

  const handleOpenConfirmModal = (open: boolean) => {
    setShowConfimModal(open);
  };

  const deletePharmacy = async () => {
    handleOpenConfirmModal(false);
    loadingIndicator(true);
    let result = await deleteMappedPharmacy.delete(hospitalDetails._id);
    if (result.success) {
      loadingIndicator(false);
      Swal.fire("Mapped Pharmacy Removed Successfully", "", "success");
      fetchMappedPharmacy(hospitalDetails._id);
    } else {
      loadingIndicator(false);
      if (result.statusCode && Array.isArray(result.message)) {
        Swal.fire(result.message[0].message, "", "error");
      } else if (result.statusCode && !Array.isArray(result.message)) {
        Swal.fire(result.message, "", "error");
      } else if (!result.success) {
        if (result.errors) {
          let errorText = "";
          for (let x in result.errors) {
            errorText += result.errors[x] + "\n";
          }
          Swal.fire(errorText, "", "error");
        } else {
          Swal.fire(result.message, "", "error");
        }
      }
    }
  };

  return (
    <>
      {fetchState === FetchState.LOADING && <LoadingSkeleton />}
      {fetchState === FetchState.SUCCESS && (
        <div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Header
                title={""}
                showBackButton
                handleClick={() =>
                  navigate(pageRoutes.customerProfiler, {
                    state: {
                      value: 2,
                    },
                  })
                }
              />
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={handleQuestionaire}
                sx={{ color: "#fff", height: 30, marginRight: 2 }}
              >
                Take Questionnaire
              </Button>
            </div>
            <div style={{ margin: "32px", marginTop: 0 }}>
              {hospitalDetails._id && (
                <HospitalDetails hospitalDetails={hospitalDetails} />
              )}
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  flexDirection: "row",
                  overflowX: "auto",
                  height: "140px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12px",
                  }}
                >
                  <AddMoreImagesComponent
                    id={"6"}
                    onFileSelect={(file: File) => processFile(file)}
                    file={fileUpload?.file}
                    reset={() => resetFile()}
                    isLoading={processingFile}
                    submit={handleSubmit}
                    openDialog={openDialog}
                    imageType={imageType}
                    handleImageType={handleImageType}
                    handleOpenDialog={handleOpenDialog}
                    imageTypeOptions={imageTypeOptions}
                  />
                </div>
                {hospitalDetails.images?.map((image) => {
                  return (
                    <ImageCards
                      getReadLink={getReadLink}
                      uuid={image.uuid}
                      imageDetails={image}
                    />
                  );
                })}
              </div>
            </div>
            {mappedPharmacy._id && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 10,
                  marginInline: "16px",
                }}
                onClick={() => setOpenAddStaffForm(true)}
              >
                <AddIcon color={"secondary"} />

                <div>
                  <Typography
                    color={"secondary.main"}
                    variant="subtitle2"
                    fontWeight={660}
                  >
                    Add Pharmacy Staff
                  </Typography>
                </div>
              </div>
            )}
            <div style={{ margin: "16px", marginTop: 0 }}>
              <MappedHospitalDoctors hospitalDetails={hospitalDetails} />
            </div>
            {mappedPharmacy._id && (
              <div style={{ margin: "16px", marginTop: 0 }}>
                <MappedPharmacy
                  mappedPharmacy={mappedPharmacy}
                  hospitalData={hospitalDetails}
                  handleOpenConfirmModal={handleOpenConfirmModal}
                />
              </div>
            )}
          </div>

          <AddPharmacyStaff
            handleClose={handleCloseAddStaffForm}
            open={openAddStaffForm}
            successCallBack={addStaff}
            staffTypes={staffTypes}
          />
          {openQuestionaireModal && (
            <QuestionaireModal
              open={openQuestionaireModal}
              handleClose={handleCloseQuestionaireModal}
              getHospitalQuestionaireData={getHospitalQuestionaireData}
              hospitalDetails={hospitalDetails}
              saveHospitalQuestionaireData={saveHospitalQuestionaireData}
            />
          )}
          {showConfirmModal && (
            <ConfirmModal
              open={showConfirmModal}
              handleClose={() => {
                handleOpenConfirmModal(false);
              }}
              message={`Are you sure you want to unmap ${
                mappedPharmacy.name
              } from ${
                hospitalDetails.fullNames[hospitalDetails.fullNames.length - 1]
                  .name
              }`}
              successCallback={deletePharmacy}
            />
          )}
        </div>
      )}
    </>
  );
};

export default HospitalProfilePage;
