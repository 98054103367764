import { useCallback, useState } from "react";
import { ConfirmFileUpload } from "../../domain/usages/confirm-file-upload";
import { GenerateFileUploadUrl } from "../../domain/usages/generate-file-upload-url";
import { UploadFile } from "../../domain/usages/upload-file";

export type FileUpload = {
  file: any;
  uuid?: string;
  url?: string;
  success: boolean;
};

const useFileUpload = (
  generateUrl: GenerateFileUploadUrl,
  upload: UploadFile,
  confirm: ConfirmFileUpload,
  category?: string
): [FileUpload | undefined, boolean, Function, Function] => {
  const [fileUpload, setFileUpload] = useState<FileUpload>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const reset = () => {
    setFileUpload(undefined);
  };

  const uploadFile = async (file: File) => {
    setIsLoading(true);
    generateUploadUrl({ file: file, success: false })
      .then((res: any) => uploadToBucket(res))
      .then((res: any) => confirmUpload(res))
      .then((res: any) => setIsLoading(false))
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const generateUploadUrl = useCallback(
    async (fileUpload: FileUpload) => {
      let payload: any = {
        name: fileUpload.file.name,
        size: fileUpload.file.size,
        type: fileUpload.file.type,
      };
      if (category) {
        payload["category"] = category;
      }
      let result = await generateUrl.generate(payload);
      if (result.uuid) {
        fileUpload.uuid = result.uuid;
        fileUpload.url = result.url;
        fileUpload.success = true;
        return fileUpload;
      } else {
        throw new Error("Couldn't generate url.");
      }
    },
    [generateUrl]
  );

  const uploadToBucket = useCallback(
    async (fileUpload: FileUpload) => {
      if (fileUpload.url) {
        let result = await upload.upload({
          file: fileUpload.file,
          url: fileUpload.url,
        });
        if (result) {
          fileUpload.success = true;
          return fileUpload;
        } else {
          throw new Error("Couldn't upload to bucket.");
        }
      } else {
        throw new Error("Couldn't upload to bucket.");
      }
    },
    [upload]
  );

  const confirmUpload = useCallback(
    async (fileUpload: FileUpload) => {
      if (fileUpload.uuid) {
        let result = await confirm.confirm({ uuid: fileUpload.uuid });
        if (result.success) {
          fileUpload.success = true;
          setFileUpload(fileUpload);
          return fileUpload;
        } else {
          throw new Error("Couldn't confirm the upload.");
        }
      } else {
        throw new Error("Couldn't confirm the upload.");
      }
    },
    [confirm]
  );

  return [fileUpload, isLoading, uploadFile, reset];
};

export default useFileUpload;
