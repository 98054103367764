import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import DoctorFilters from "../../../domain/enums/doctorFilters";
import { FetchState } from "../../../domain/models/fetch-state-type";

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: "auto",
  color: theme.palette.text.secondary,
}));

type Props = {
  count: number;
  fetchDoctorsList: Function;
  fetchState: FetchState;
};

const DoctorSummary: React.FC<Props> = ({
  count,
  fetchDoctorsList,
  fetchState,
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string>(
    DoctorFilters.selectedDoctors
  );

  const handleDoctorListFilter = (value: boolean, name: string) => {
    fetchDoctorsList(1, false, value, false);
    setSelectedFilter(name);
  };

  return (
    <Stack
      direction="row"
      mt={2}
      justifyContent={"space-around"}
      textAlign={"center"}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Stack
        textAlign={"center"}
        onClick={() =>
          handleDoctorListFilter(false, DoctorFilters.selectedDoctors)
        }
        bgcolor={
          selectedFilter === DoctorFilters.selectedDoctors
            ? "secondary.dark"
            : "secondary.light"
        }
        padding={2}
        borderRadius={2}
      >
        <Typography variant="caption" color={"info.main"}>
          Total Doctors{" "}
          {selectedFilter === DoctorFilters.selectedDoctors &&
            fetchState == FetchState.SUCCESS &&
            ` (${count})`}
        </Typography>
      </Stack>

      <Stack
        textAlign={"center"}
        onClick={() =>
          handleDoctorListFilter(
            true,
            DoctorFilters.selectedSampleEligibleDoctors
          )
        }
        bgcolor={
          selectedFilter === DoctorFilters.selectedSampleEligibleDoctors
            ? "secondary.dark"
            : "secondary.light"
        }
        padding={2}
        borderRadius={2}
      >
        <Typography variant="caption" color={"info.main"}>
          Program Eligible Doctors
          {selectedFilter === DoctorFilters.selectedSampleEligibleDoctors &&
            fetchState == FetchState.SUCCESS &&
            ` (${count})`}
        </Typography>
      </Stack>
    </Stack>
  );
};
export default DoctorSummary;
