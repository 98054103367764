import { DeleteMappedPharmacy } from "../../../domain/usages/Hospitals/delete-mapped-pharmacy";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpDeleteClient } from "../../protocols/http/http-delete-client";

export class RemoteDeleteMappedPharmacy implements DeleteMappedPharmacy {
  constructor(
    private readonly url: string,
    private readonly httpDeleteClient: HttpDeleteClient
  ) {}

  async delete(id: string, params: DeleteMappedPharmacy.Params): Promise<any> {
    const httpResponse = await this.httpDeleteClient.delete({
      url: this.url.replace(":id", id),
      query: params,
      headers: {
        ["ngrok-skip-browser-warning"]: "1",
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
