import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Qualification } from "../../../domain/models/doctor/qualification";
import { Speciality } from "../../../domain/models/doctor/speciality";
import { FetchDoctorMasters } from "../../../domain/usages/Doctors/fetch-doctor-masters";
import { UploadDoctorFileFactory } from "../../../main/Doctors/UploadDoctorFileFactory";
import { pageRoutes } from "../../../routes";
import Header from "../../components/Header/Header";

type Props = {
  fetchDoctorMasters: FetchDoctorMasters;
};

const DoctorRegistrationPage: React.FC<Props> = ({ fetchDoctorMasters }) => {
  const navigate = useNavigate();
  const [specialities, setSpecialities] = useState<Speciality[]>([]);
  const [qualifications, setQualifications] = useState<Qualification[]>([]);

  const fetchMasterData = useCallback(async () => {
    let result = await fetchDoctorMasters.fetch({});
    if (result.success) {
      setSpecialities(result.specialities);
      setQualifications(result.qualifications);
    }
  }, [fetchDoctorMasters]);

  useEffect(() => {
    fetchMasterData();
  }, []);

  return (
    <div>
       <div
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                left: 0,
                width: "100%",
                backgroundColor: "white",
                zIndex:50, 
              }}
            >
      <Header
        title={"Register New Doctor"}
        handleClick={() => navigate(pageRoutes.customerProfiler, {
          state: {
            value:0 ,
          },
        })}
        showBackButton
      />
      </div>
      <div style={{ margin: "17px", marginTop: "70px" }}>
        <UploadDoctorFileFactory
          specialities={specialities}
          qualifications={qualifications}
        />
      </div>
    </div>
  );
};

export default DoctorRegistrationPage;
