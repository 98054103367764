import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AUTH_TOKEN_KEY, GOOGLE_MAPS_API_KEY } from "../../../base";
import Constants from "../../../Constants";
import useGeoLocation from "../../../domain/hooks/useGeoLocation";
import { HospitalFormDetails } from "../../../domain/models/Hospital/hospitalFormDetails";
import { HospitalMaster } from "../../../domain/models/Hospital/hospitalMaster";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import { Option } from "../../../domain/models/option";
import { ConfirmFileUpload } from "../../../domain/usages/confirm-file-upload";
import { FetchBricks } from "../../../domain/usages/fetch-bricks";
import { FetchGeoAddresses } from "../../../domain/usages/fetch-geo-addresses";
import { GenerateFileUploadUrl } from "../../../domain/usages/generate-file-upload-url";
import { CreateHospital } from "../../../domain/usages/Hospitals/create-hospital";
import { UploadFile } from "../../../domain/usages/upload-file";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { pageRoutes } from "../../../routes";
import { GaButton, GaButtonColor } from "../../ga-components/Button";
import jwt_decode from "jwt-decode";
import {
  FieldVariant,
  GaSelectField,
  GaTextField,
  OutputValue,
} from "../../ga-components/Inputs";
import { GaTypography, TypographyType } from "../../ga-components/Typography";
import useBricks from "../../hooks/useBricks";
import useFileUpload from "../../hooks/useFileUpload";
import UploadFileCard from "../UploadButton/UploadFileCard";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Contacts } from "../../../domain/models/contacts";
import { LoadingButton } from "@mui/lab";

const useGeoAddress = (
  fetchGeoAddresses: FetchGeoAddresses,
  geoLocation?: GeolocationCoordinates
): [any] => {
  const [address, setAddress] = useState<any>();

  const fetchAddress = useCallback(async () => {
    let payload = {
      latLong: geoLocation?.latitude + "," + geoLocation?.longitude,
    };
    let result = await fetchGeoAddresses.fetch(payload);
    if (result.success) {
      setAddress(Constants.geoAddressToAddress(result.data));
    }
  }, [fetchGeoAddresses, geoLocation]);

  useEffect(() => {
    if (geoLocation && geoLocation.latitude) {
      fetchAddress();
    }
  }, [geoLocation]);

  return [address];
};

const useRegisterHospital = (
  register: CreateHospital,
  fetchGeoAddresses: FetchGeoAddresses,
  loggedInUserDetails: LoggedInUserDetails
): [boolean, Function, any] => {
  const geoLocation = useGeoLocation();
  const [address] = useGeoAddress(fetchGeoAddresses, geoLocation);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();

  const submit = useCallback(
    async (data: HospitalFormDetails) => {
      setIsLoading(true);
      let geoAddress = address;
      if (!geoAddress) {
        geoAddress = Constants.geoAddressToAddress({
          geometry: {
            location: {
              lat: geoLocation?.latitude,
              lng: geoLocation?.longitude,
            },
          },
        });
      }
      let contacts: Contacts[] = [];
      if (data.mobile && data.landline) {
        contacts.push({
          type: "mobile",
          value: data.mobile,
        });
        contacts.push({
          type: "landline",
          value: data.landline,
        });
      } else if (data.landline) {
        contacts.push({
          type: "landline",
          value: data.landline,
        });
      } else if (data.mobile) {
        contacts.push({
          type: "mobile",
          value: data.mobile,
        });
      }
      const payload = {
        fullName: data.name,
        contacts: contacts,
        noOfPatients: data.noOfPatients,
        potentialPerMonth: parseInt(data.potentialPerMonth),
        noOfBeds: data.noOfBeds,
        hospitalType: data.type,
        images: [
          {
            type: "banner",
            uuid: data.uuid,
            ...geoAddress,
          },
        ],
        mappings: [
          {
            brickCode: data.brick.short_code,
            brickName: data.brick.name,
            hqCode: loggedInUserDetails.hq_code,
            hqName: loggedInUserDetails.hq_name,
            orgCode: loggedInUserDetails.org_code,
            deptCode: loggedInUserDetails.dept_code,
          },
        ],
        latLong: geoAddress.latLong,
        source: "cpay",
      };
      let result = await register.create(payload);
      setResponse(result);
      setIsLoading(false);
      if (result.statusCode && Array.isArray(result.message)) {
        Swal.fire(result.message[0].message, "", "error");
      } else if (result.statusCode && !Array.isArray(result.message)) {
        Swal.fire(result.message, "", "error");
      } else if (!result.success) {
        if (result.errors) {
          let errorText = "";
          for (let x in result.errors) {
            errorText += result.errors[x] + "\n";
          }
          Swal.fire(errorText, "", "error");
        } else {
          Swal.fire(result.message, "", "error");
        }
      }
    },
    [register, address]
  );

  return [isLoading, submit, response];
};

type Props = {
  generateUploadURL: GenerateFileUploadUrl;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmFileUpload;
  fetchBricks: FetchBricks;
  createHospital: CreateHospital;
  fetchGeoAddresses: FetchGeoAddresses;
  hospitalTypeOptions: HospitalMaster[];
  noOfBedsOptions: HospitalMaster[];
  noOfPatientsOptions: HospitalMaster[];
};

const HospitalRegistrationForm: React.FC<Props> = ({
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
  fetchBricks,
  createHospital,
  fetchGeoAddresses,
  hospitalTypeOptions,
  noOfPatientsOptions,
  noOfBedsOptions,
}) => {
  const navigate = useNavigate();
  const bricks = useBricks(fetchBricks);
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>({} as LoggedInUserDetails);
  const [contact, setContact] = useState("");
  const [selectContacterror, setSelectContactError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const [submitting, submit, response] = useRegisterHospital(
    createHospital,
    fetchGeoAddresses,
    loggedInUserDetails
  );
  const [fileUpload, processingFile, processFile, resetFile] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<HospitalFormDetails>({
    mode: "onChange",
  });

  useEffect(() => {
    if (fileUpload && fileUpload.uuid) {
      setValue("uuid", fileUpload.uuid);
    }
  }, [fileUpload]);

  const onSubmit = (data: HospitalFormDetails) => {
    if (contact == "") {
      setSelectContactError(true);
      setHelperText("Please select an option");
    } else {
      setSelectContactError(false);
      setHelperText(" ");
      submit(data);
    }
  };

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }

    if (response && response.success) {
      Swal.fire("Hospital registered successfully.", "", "success");
      navigate(pageRoutes.hospitalProfilePage, {
        state: {
          id: response.data.hospital._id,
        },
      });
    }
  }, [response]);

  const handleSelectContact = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value == "mobile") {
      setValue("landline", "");
    } else if (e.target.value == "landline") {
      setValue("mobile", "");
    }
    setContact(e.target.value);
    setSelectContactError(false);
    setHelperText(" ");
  };

  return (
    <div style={{ display: "grid", gap: "17px" }}>
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <GaTextField
            onChange={onChange}
            value={value}
            title="Hospital Name *"
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{ required: { value: true, message: "Required" } }}
      />
      <FormControl error={selectContacterror}>
        <FormLabel sx={{ fontSize: 15, fontWeight: 500 }}>
          Select type of contact number you want to enter
        </FormLabel>
        {selectContacterror && <FormHelperText>{helperText}</FormHelperText>}

        <RadioGroup row value={contact} onChange={handleSelectContact}>
          <FormControlLabel
            value="mobile"
            control={<Radio color={"secondary"} size="small" />}
            label="Mobile Number"
          />
          <FormControlLabel
            value="landline"
            control={<Radio color={"secondary"} size="small" />}
            label="Landline Number"
          />
          <FormControlLabel
            value="both"
            control={<Radio color={"secondary"} size="small" />}
            label=" Mobile & Landline number"
          />
        </RadioGroup>
      </FormControl>
      {(contact == "mobile" || contact == "both") && (
        <Controller
          name="mobile"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              value={value}
              label="Mobile Number *"
              error={!!error}
              helperText={error?.message}
              type="number"
            />
          )}
          rules={{
            pattern: {
              value: /^[6-9]{1}[0-9]{9}$/,
              message: "Invalid mobile",
            },
            required: { value: true, message: "Field Required" },
          }}
        />
      )}
      {(contact == "landline" || contact == "both") && (
        <Controller
          name="landline"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              value={value}
              label="Landline Number (eg: 0401111111) *"
              error={!!error}
              helperText={error?.message}
              type="number"
            />
          )}
          rules={{
            pattern: {
              value: /^[0-9]\d{10}$/,
              message: "Invalid landline number",
            },
            required: { value: true, message: "Field Required" },
          }}
        />
      )}

      {bricks && (
        <Controller
          name="brick"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <GaSelectField
              options={bricks.map(
                (s) => ({ key: s.short_code, value: s.name } as Option)
              )}
              value={value?.short_code}
              onChange={(event: any) => {
                if (event.target && event.target.value) {
                  onChange(
                    bricks.find((b) => b.short_code == event.target.value)
                  );
                }
              }}
              outputValue={OutputValue.KEY}
              variant={FieldVariant.OUTLINED}
              title="Brick *"
              error={!!error}
              helperText={error?.message}
            ></GaSelectField>
          )}
          rules={{ required: { value: true, message: "Field Required" } }}
        />
      )}

      <Controller
        name="type"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <GaSelectField
            options={hospitalTypeOptions.map(
              (s) => ({ key: s.value, value: s.message } as Option)
            )}
            value={value}
            onChange={onChange}
            outputValue={OutputValue.VALUE}
            variant={FieldVariant.OUTLINED}
            title="Hospital Type*"
            error={!!error}
            helperText={error?.message}
          ></GaSelectField>
        )}
        rules={{ required: { value: true, message: "Field Required" } }}
      />

      <Controller
        name="noOfBeds"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <GaSelectField
            options={noOfBedsOptions.map(
              (s) => ({ key: s.value, value: s.message } as Option)
            )}
            value={value}
            onChange={onChange}
            outputValue={OutputValue.VALUE}
            variant={FieldVariant.OUTLINED}
            title="No of Beds *"
            error={!!error}
            helperText={error?.message}
          ></GaSelectField>
        )}
        rules={{ required: { value: true, message: "Field Required" } }}
      />

      <Controller
        name="noOfPatients"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <GaSelectField
            options={noOfPatientsOptions.map(
              (s) => ({ key: s.value, value: s.message } as Option)
            )}
            value={value}
            onChange={onChange}
            outputValue={OutputValue.VALUE}
            variant={FieldVariant.OUTLINED}
            title="No of Patients Per Day *"
            error={!!error}
            helperText={error?.message}
          ></GaSelectField>
        )}
        rules={{ required: { value: true, message: "Field Required" } }}
      />
      <Controller
        name="potentialPerMonth"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            type={"number"}
            onChange={onChange}
            value={value}
            label="Potential Per Month *"
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{
          pattern: {
            value: /^[1-9]/,
            message: "Please type only numbers greater than 0",
          },
          required: { value: true, message: "Field Required" },
        }}
      />

      <Controller
        name="uuid"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <UploadFileCard
            title="Upload Hospital Photo * "
            id={5}
            onFileSelect={(file: File) => processFile(file)}
            file={fileUpload?.file}
            reset={() => resetFile()}
            isLoading={processingFile}
            error={!!error}
            helperText={error?.message}
            message={"Please make sure photo contain hospital name and address"}
          />
        )}
        rules={{ required: { value: true, message: "Field Required" } }}
      />

      <div
        style={{
          position: "sticky",
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: 1,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <div style={{ marginBlock: 20 }}>
          <LoadingButton
            size="small"
            variant="contained"
            fullWidth
            loadingPosition="start"
            loading={submitting}
            color={GaButtonColor.SECONDARY}
            onClick={handleSubmit(onSubmit)}
          >
            <Typography color="white" textTransform={"none"}>
              {submitting ? "Creating..." : "SUBMIT"}
            </Typography>
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default HospitalRegistrationForm;
