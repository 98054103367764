import {
  AUTH_API_URL,
  AUTH_HEADER,
  AUTH_HEADER_CHANNELPAY,
  AUTH_TOKEN_KEY,
  HOSPITAL_PROFILER_API_URL,
} from "../../base";
import { RemoteCreateHospital } from "../../data/usecases/Hospitals/remote-create-hospital";
import { RemoteConfirmFileUpload } from "../../data/usecases/remote-confirm-file-upload";
import { RemoteFetchBricks } from "../../data/usecases/remote-fetch-bricks";
import { RemoteFetchGeoAddresses } from "../../data/usecases/remote-fetch-geo-addresses";
import { RemoteGenerateFileUploadUrl } from "../../data/usecases/remote-generate-file-upload-url";
import { RemoteUploadFile } from "../../data/usecases/remote-upload-file";
import Endpoints from "../../domain/endpoints";
import { HospitalMaster } from "../../domain/models/Hospital/hospitalMaster";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import HospitalRegistrationForm from "../../presentation/components/Hospital/HospitalRegistrationForm";

type Props = {
  hospitalTypeOptions: HospitalMaster[];
  noOfBedsOptions: HospitalMaster[];
  noOfPatientsOptions: HospitalMaster[];
};

export const UploadHospitalFileFactory: React.FC<Props> = ({
  noOfPatientsOptions,
  noOfBedsOptions,
  hospitalTypeOptions,
}) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const axiosHttpClientChannelpay = AxiosHttpClient.getInstance();
  axiosHttpClientChannelpay.setAuthHeaders({
    [AUTH_HEADER_CHANNELPAY]: token,
  });

  const remoteGenerateUploadURL = new RemoteGenerateFileUploadUrl(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.GET_DOCTOR_UPLOADED_IMAGE_URL}`,
    axiosHttpClient
  );

  const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);

  const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.CONFIRM_DOCTOR_IMAGE_UPLOAD_TO_BUCKET}`,
    axiosHttpClient
  );

  const remoteFetchBricks = new RemoteFetchBricks(
    `${AUTH_API_URL}${Endpoints.FETCH_BRICKS}`,
    axiosHttpClientChannelpay
  );

  const remoteCreateHospital = new RemoteCreateHospital(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.CREATE_HOSPITAL}`,
    axiosHttpClient
  );

  const remoteFetchGeoAddresses = new RemoteFetchGeoAddresses(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.GEO_LOCATION}`,
    axiosHttpClient
  );

  return (
    <HospitalRegistrationForm
      createHospital={remoteCreateHospital}
      generateUploadURL={remoteGenerateUploadURL}
      uploadFile={remoteUploadFile}
      confirmFileUpload={remoteConfirmFileUpload}
      fetchBricks={remoteFetchBricks}
      fetchGeoAddresses={remoteFetchGeoAddresses}
      hospitalTypeOptions={hospitalTypeOptions}
      noOfBedsOptions={noOfBedsOptions}
      noOfPatientsOptions={noOfPatientsOptions}
    />
  );
};
