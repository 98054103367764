import {
  AUTH_API_URL,
  AUTH_HEADER,
  AUTH_HEADER_CHANNELPAY,
  AUTH_TOKEN_KEY,
  CUSTOMER_PROFILER_API_URL,
  DOCTOR_PROFILER_API_URL,
} from "../../base";
import { RemoteFetchMappedDoctors } from "../../data/usecases/Chemists/remote-fetch-mapped-doctors";
import { RemoteGetChemistDetails } from "../../data/usecases/Chemists/remote-get-chemist-details";
import { RemoteConfirmFileUpload } from "../../data/usecases/remote-confirm-file-upload";
import { RemoteGenerateFileUploadUrl } from "../../data/usecases/remote-generate-file-upload-url";
import { RemoteGetReadLink } from "../../data/usecases/remote-get-read-Link";
import { RemoteAddMoreImages } from "../../data/usecases/remote-add-more-image";
import { RemoteUploadFile } from "../../data/usecases/remote-upload-file";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import ChemistProfilePage from "../../presentation/pages/Chemist/ChemistProfilePage";
import { RemoteAddWorkers } from "../../data/usecases/Chemists/remote-add-workers";
import { RemoteStoreScannedUPI } from "../../data/usecases/Chemists/remote-store-scanned-UPI";
import { RemoteFindWorkersById } from "../../data/usecases/Chemists/remote-find-workers-by-id";
import { RemoteFindUPIById } from "../../data/usecases/Chemists/remote-find-upi-by-id";
import { RemoteAddWorkerContact } from "../../data/usecases/Chemists/remote-add-worker-contact";
import { RemoteFetchWorkerTypes } from "../../data/usecases/Chemists/remote-fetch-worker-types";

export const GetChemistDetailsFactory = () => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const cPayaxiosHttpClient = AxiosHttpClient.getInstance();
  cPayaxiosHttpClient.setAuthHeaders({
    [AUTH_HEADER_CHANNELPAY]: token,
  });

  const customerServiceAxiosHttpClient = AxiosHttpClient.getInstance();
  customerServiceAxiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const remoteGetChemistByID = new RemoteGetChemistDetails(
    `${CUSTOMER_PROFILER_API_URL}${Endpoints.GET_CUSTOMER_BY_ID}`,
    customerServiceAxiosHttpClient
  );

  const remoteFetchMappeddoctors = new RemoteFetchMappedDoctors(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.FETCH_MAPPED_DOCTORS}`,
    axiosHttpClient
  );

  const remoteGetReadLink = new RemoteGetReadLink(
    `${AUTH_API_URL}${Endpoints.GET_READ_LINK}`,
    cPayaxiosHttpClient
  );

  const remoteGenerateUploadURL = new RemoteGenerateFileUploadUrl(
    `${AUTH_API_URL}${Endpoints.GET_UPLOADED_IMAGE_URL}`,
    cPayaxiosHttpClient
  );

  const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);

  const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
    `${AUTH_API_URL}${Endpoints.CONFIRM_IMAGE_UPLOAD_TO_BUCKET}`,
    cPayaxiosHttpClient
  );
  const remoteAddMoreImages = new RemoteAddMoreImages(
    `${CUSTOMER_PROFILER_API_URL}${Endpoints.ADD_CUSTOMER_IMAGE}`,
    customerServiceAxiosHttpClient
  );

  const remoteAddWorkers = new RemoteAddWorkers(
    `${CUSTOMER_PROFILER_API_URL}${Endpoints.ADD_STAFF}`,
    customerServiceAxiosHttpClient
  );

  const remoteStoreUPI = new RemoteStoreScannedUPI(
    `${CUSTOMER_PROFILER_API_URL}${Endpoints.STORE_UPI_ID}`,
    customerServiceAxiosHttpClient
  );

  const remoteFindWorkersById = new RemoteFindWorkersById(
    `${CUSTOMER_PROFILER_API_URL}${Endpoints.FIND_WORKERS_BY}`,
    customerServiceAxiosHttpClient
  );

  const remoteFindUPIById = new RemoteFindUPIById(
    `${CUSTOMER_PROFILER_API_URL}${Endpoints.FIND_UPI_BY}`,
    customerServiceAxiosHttpClient
  );

  const remoteAddWorkerContact = new RemoteAddWorkerContact(
    `${CUSTOMER_PROFILER_API_URL}${Endpoints.ADD_WORKER_CONTACT}`,
    customerServiceAxiosHttpClient
  );

  const remoteFetchWorkerTypes = new RemoteFetchWorkerTypes(
    `${CUSTOMER_PROFILER_API_URL}${Endpoints.WORKER_TYPES}`,
    customerServiceAxiosHttpClient
  );

  return (
    <ChemistProfilePage
      getReadLink={remoteGetReadLink}
      getChemistDetails={remoteGetChemistByID}
      fetchMappedDoctors={remoteFetchMappeddoctors}
      generateUploadURL={remoteGenerateUploadURL}
      uploadFile={remoteUploadFile}
      confirmFileUpload={remoteConfirmFileUpload}
      ReuploadImage={remoteAddMoreImages}
      addWorkers={remoteAddWorkers}
      storeUPI={remoteStoreUPI}
      findWorkersById={remoteFindWorkersById}
      findUPIById={remoteFindUPIById}
      addWorkerContact={remoteAddWorkerContact}
      fetchWorkerTypes={remoteFetchWorkerTypes}
    />
  );
};
