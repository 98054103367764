import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HospitalMaster } from "../../../domain/models/Hospital/hospitalMaster";
import { FetchHospitalMaster } from "../../../domain/usages/Hospitals/fetch-hospital-master";
import { UploadHospitalFileFactory } from "../../../main/Hospital/UploadHospitalFileFactory";
import { pageRoutes } from "../../../routes";
import Header from "../../components/Header/Header";

type Props = {
  fetchHospitalMaster: FetchHospitalMaster;
};

const HospitalRegistrationPage: React.FC<Props> = ({ fetchHospitalMaster }) => {
  const [hospitalType, setHospitalType] = useState<HospitalMaster[]>([]);
  const [noOfBeds, setNoOfBeds] = useState<HospitalMaster[]>([]);
  const [noOfPatients, setNoOfPatients] = useState<HospitalMaster[]>([]);
  const navigate = useNavigate();

  const fetchMasterData = useCallback(async () => {
    let result = await fetchHospitalMaster.fetch();
    if (result.success) {
      setHospitalType(result.data.hospitalType);
      setNoOfBeds(result.data.noOfBeds);
      setNoOfPatients(result.data.noOfPatients);
    }
  }, [fetchHospitalMaster]);

  useEffect(() => {
    fetchMasterData();
  }, []);

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
          zIndex: 50,
        }}
      >
        <Header
          title={"Register New Hospital"}
          handleClick={() =>
            navigate(pageRoutes.customerProfiler, {
              state: {
                value: 2,
              },
            })
          }
          showBackButton
        />
      </div>
      <div style={{ margin: "17px", marginTop: "70px" }}>
        <UploadHospitalFileFactory
          hospitalTypeOptions={hospitalType}
          noOfBedsOptions={noOfBeds}
          noOfPatientsOptions={noOfPatients}
        />
      </div>
    </div>
  );
};

export default HospitalRegistrationPage;
