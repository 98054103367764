import {
  AUTH_API_URL,
  AUTH_HEADER_CHANNELPAY,
  AUTH_TOKEN_KEY,
  UPLOAD_IMAGE_API_URL,
} from "../../base";
import { RemoteFetchChemistTypes } from "../../data/usecases/Chemists/remote-fetch-chemist-types";
import { RemoteRegisterChemist } from "../../data/usecases/Chemists/remote-register-chemist";
import { RemoteConfirmFileUpload } from "../../data/usecases/remote-confirm-file-upload";
import { RemoteFetchBricks } from "../../data/usecases/remote-fetch-bricks";
import { RemoteGenerateFileUploadUrl } from "../../data/usecases/remote-generate-file-upload-url";
import { RemoteUploadFile } from "../../data/usecases/remote-upload-file";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { ChemistNewRegistrationForm } from "../../presentation/components/Chemist/ChemistNewRegistrationForm";
import ChemistRegistrationForm from "../../presentation/components/Chemist/ChemistRegistrationForm";

type Props = {};

export const UploadChemistFileFactory: React.FC<Props> = ({}) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();

  // TODO:: replace the hard coded string with the dynamic user token
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER_CHANNELPAY]: token,
  });

  const remoteGenerateUploadURL = new RemoteGenerateFileUploadUrl(
    `${AUTH_API_URL}${Endpoints.GET_UPLOADED_IMAGE_URL}`,
    axiosHttpClient
  );

  const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);

  const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
    `${AUTH_API_URL}${Endpoints.CONFIRM_IMAGE_UPLOAD_TO_BUCKET}`,
    axiosHttpClient
  );

  const remoteFetchBricks = new RemoteFetchBricks(
    `${AUTH_API_URL}${Endpoints.FETCH_BRICKS}`,
    axiosHttpClient
  );

  const remoteRegisterChemist = new RemoteRegisterChemist(
    `${AUTH_API_URL}${Endpoints.CREATE_CUSTOMERS}`,
    axiosHttpClient
  );

  const remoteFetchChemistTypes = new RemoteFetchChemistTypes(
    `${AUTH_API_URL}${Endpoints.FETCH_CHEMIST_TYPES}`,
    axiosHttpClient
  );

  return (
    <ChemistRegistrationForm
      registerChemist={remoteRegisterChemist}
      generateUploadURL={remoteGenerateUploadURL}
      uploadFile={remoteUploadFile}
      confirmFileUpload={remoteConfirmFileUpload}
      fetchBricks={remoteFetchBricks}
      fetchChemistTypes={remoteFetchChemistTypes}
    />
  );
};
