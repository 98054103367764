import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  DOCTOR_PROFILER_API_URL,
} from "../../base";
import { RemoteFetchDoctorMasters } from "../../data/usecases/Doctors/remote-fetch-doctor-masters";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import DoctorRegistrationPage from "../../presentation/pages/Doctor/DoctorRegistrationPage";

export const DoctorRegistrationFactory = () => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const remoteFetchDoctorMasters = new RemoteFetchDoctorMasters(
    `${DOCTOR_PROFILER_API_URL}${Endpoints.FETCH_DOCTOR_MASTERS}`,
    axiosHttpClient
  );

  return (
    <DoctorRegistrationPage fetchDoctorMasters={remoteFetchDoctorMasters} />
  );
};
