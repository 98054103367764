import { Card, CardContent, Skeleton, Stack } from "@mui/material";

type Props = {};

const TherapyLoadingSkeleton = (props: Props) => {
  return (
    <Stack margin={2} spacing={2}>
      <Card variant="outlined">
        <Skeleton
          variant="rectangular"
          height={40}
          animation={false}
        ></Skeleton>
        <CardContent>
          <Skeleton
            variant="rectangular"
            height={100}
            animation={"wave"}
          ></Skeleton>
        </CardContent>
      </Card>
      <Card variant="outlined">
        <Skeleton
          variant="rectangular"
          height={40}
          animation={false}
        ></Skeleton>
        <CardContent>
          <Skeleton
            variant="rectangular"
            height={100}
            animation={"wave"}
          ></Skeleton>
        </CardContent>
      </Card>
      <Card variant="outlined">
        <Skeleton
          variant="rectangular"
          height={40}
          animation={false}
        ></Skeleton>
        <CardContent>
          <Skeleton
            variant="rectangular"
            height={100}
            animation={"wave"}
          ></Skeleton>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default TherapyLoadingSkeleton;
