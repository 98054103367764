import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";

type Props = {
  open: boolean;
  handleClose: Function;
  successCallback: Function;
  message: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const ConfirmModal: React.FC<Props> = ({
  open,
  handleClose,
  successCallback,
  message,
}) => {
  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography variant="subtitle2" textAlign={"center"}>
          {message}
        </Typography>
        <Stack
          direction={"row"}
          spacing={4}
          justifyContent={"center"}
          alignItems={"center"}
          marginTop={3}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => successCallback()}
          >
            <Typography variant="subtitle2" color="white">
              Confirm
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleClose()}
          >
            <Typography variant="subtitle2" color="white">
              Cancel
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
