export default class Constants {
  static DATA_ENTRY_ROLE = "DATA_ENTRY";
  static YEAR_MONTH_DATE_FORMAT = "LL";

  static geoAddressToAddress(geoAddress: any) {
    let line, landmark, pincode, area, district, state, latLong, country;
    if (geoAddress.formatted_address) line = geoAddress.formatted_address;
    if (geoAddress.geometry && geoAddress.geometry.location) {
      latLong =
        geoAddress.geometry.location.lat +
        "," +
        geoAddress.geometry.location.lng;
    }
    if (
      geoAddress.address_components &&
      geoAddress.address_components.length > 0
    ) {
      geoAddress.address_components.map((a: any) => {
        if (a.types.includes("postal_code")) {
          pincode = parseInt(a.long_name);
        }
        if (a.types.includes("country")) {
          country = [a.long_name, a.short_name];
        }
        if (a.types.includes("administrative_area_level_1")) {
          state = [a.long_name, a.short_name];
        }
        if (a.types.includes("administrative_area_level_2")) {
          district = a.long_name;
        }
        if (a.types.includes("sublocality_level_1")) {
          area = a.long_name;
        }
        if (a.types.includes("sublocality_level_2")) {
          landmark = a.long_name;
        }
      });
    }
    return {
      latLong: latLong,
      line: line ?? "",
      landmark: landmark ?? "",
      pincode: pincode ?? 0,
      district: district ?? "",
      area: area ?? "",
      state: state ?? [],
      country: country ?? [],
    };
  }
}
