import { AUTH_HEADER_CHANNELPAY, AUTH_TOKEN_KEY } from "../../base";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import MobileExistsPage from "../../presentation/pages/Chemist/MobileExistPage";

export const MobileExistsFactory = () => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    const axiosHttpClient = AxiosHttpClient.getInstance();
    axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_CHANNELPAY]: token,
    });

    return <MobileExistsPage />;
};
