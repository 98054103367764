import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { HospitalData } from "../../../domain/models/Hospital/hospitalData";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { GetHospitalQuestionaireData } from "../../../domain/usages/Hospitals/get-hospital-questionaire-data";
import Swal from "sweetalert2";
import { SaveHospitalQuestionaireData } from "../../../domain/usages/Hospitals/save-hospital-questionaire-data";
import { FetchState } from "../../../domain/models/fetch-state-type";

type Props = {
  open: boolean;
  handleClose: Function;
  hospitalDetails: HospitalData;
  getHospitalQuestionaireData: GetHospitalQuestionaireData;
  saveHospitalQuestionaireData: SaveHospitalQuestionaireData;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "95vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

type QuestionaireFormDetails = {
  orthopaedicAttached: boolean;
  noOfOrthopaedicsAttached: string;
  patientsTreatedByOrthopaedics: string;
  orthoPractice: string;
  bascogastAvailable: boolean;
  bascogastUnits: string;
  bascogastSalesValue: string;
  depuraAvailable: boolean;
  depuraUnits: string;
  specialRate: boolean;
  sanofiRepVisits: boolean;
  profiledNewOrthopaedicians: boolean;
};

const QuestionaireModal: React.FC<Props> = ({
  open,
  handleClose,
  hospitalDetails,
  getHospitalQuestionaireData,
  saveHospitalQuestionaireData,
}) => {
  const [questionaireloading, setQuestionaireLoading] = useState(false);
  const [loading, setLoading] = useState<FetchState>(FetchState.DEFAULT);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<QuestionaireFormDetails>({
    mode: "onChange",
  });

  const saveQuestionaireData = async (data: QuestionaireFormDetails) => {
    let payload = {
      orthopaedicAttached: data.orthopaedicAttached,
      noOfOrthopaedicsAttached: data.noOfOrthopaedicsAttached
        ? parseInt(data.noOfOrthopaedicsAttached)
        : 0,
      patientsTreatedByOrthopaedics: data.patientsTreatedByOrthopaedics,
      orthoPractice: data.orthoPractice ? data.orthoPractice : null,
      bascogastAvailable: data.bascogastAvailable,
      bascogastUnits: data.bascogastUnits,
      bascogastSalesValue: data.bascogastSalesValue
        ? parseInt(data.bascogastSalesValue)
        : 0,
      depuraAvailable: data.depuraAvailable,
      depuraUnits: data.depuraUnits,
      specialRate: data.specialRate,
      sanofiRepVisits: data.sanofiRepVisits,
      profiledNewOrthopaedicians: data.profiledNewOrthopaedicians,
    };
    setQuestionaireLoading(true);
    let result = await saveHospitalQuestionaireData.save(
      hospitalDetails._id,
      payload
    );
    if (result.success) {
      getQuestionaireData(hospitalDetails._id);
      handleClose();
      setQuestionaireLoading(false);
      Swal.fire("Questionaire Submitted Successfully", "", "success");
    } else {
      setQuestionaireLoading(false);
      if (result.statusCode && Array.isArray(result.message)) {
        Swal.fire({
          icon: "error",
          title: result.message[0].message,
          customClass: {
            container: "swal2-style",
          },
        });
      } else if (result.statusCode && !Array.isArray(result.message)) {
        Swal.fire({
          icon: "error",
          title: result.message,
          customClass: {
            container: "swal2-style",
          },
        });
      } else if (!result.success) {
        if (result.errors) {
          let errorText = "";
          for (let x in result.errors) {
            errorText += result.errors[x] + "\n";
          }
          Swal.fire({
            icon: "error",
            title: errorText,
            customClass: {
              container: "swal2-style",
            },
          });
        } else {
          Swal.fire({
            icon: "error",
            title: result.message,
            customClass: {
              container: "swal2-style",
            },
          });
        }
      }
    }
  };

  const onSubmit = (data: QuestionaireFormDetails) => {
    saveQuestionaireData(data);
  };

  const getQuestionaireData = async (id: string) => {
    setLoading(FetchState.LOADING);
    let result = await getHospitalQuestionaireData.get(id);
    if (result.success) {
      setLoading(FetchState.SUCCESS);
      if (result.data) {
        setValue("orthopaedicAttached", result.data.orthopaedicAttached);
        setValue(
          "noOfOrthopaedicsAttached",
          result.data.noOfOrthopaedicsAttached.toString()
        );
        setValue(
          "patientsTreatedByOrthopaedics",
          result.data.patientsTreatedByOrthopaedics
        );
        setValue("orthoPractice", result.data.orthoPractice);
        setValue("bascogastAvailable", result.data.bascogastAvailable);
        setValue("bascogastUnits", result.data.bascogastUnits);
        setValue(
          "bascogastSalesValue",
          result.data.bascogastSalesValue.toString()
        );
        setValue("depuraAvailable", result.data.depuraAvailable);
        setValue("depuraUnits", result.data.depuraUnits);
        setValue("specialRate", result.data.specialRate);
        setValue("sanofiRepVisits", result.data.sanofiRepVisits);
        setValue(
          "profiledNewOrthopaedicians",
          result.data.profiledNewOrthopaedicians
        );
      } else {
        setValue("orthopaedicAttached", false);
        setValue("noOfOrthopaedicsAttached", "");
        setValue("patientsTreatedByOrthopaedics", "");
        setValue("orthoPractice", "");
        setValue("bascogastAvailable", false);
        setValue("bascogastUnits", "");
        setValue("bascogastSalesValue", "");
        setValue("depuraAvailable", false);
        setValue("depuraUnits", "");
        setValue("specialRate", false);
        setValue("sanofiRepVisits", false);
        setValue("profiledNewOrthopaedicians", false);
      }
    } else {
      setLoading(FetchState.ERROR);
      setValue("orthopaedicAttached", false);
      setValue("noOfOrthopaedicsAttached", "");
      setValue("patientsTreatedByOrthopaedics", "");
      setValue("orthoPractice", "");
      setValue("bascogastAvailable", false);
      setValue("bascogastUnits", "");
      setValue("bascogastSalesValue", "");
      setValue("depuraAvailable", false);
      setValue("depuraUnits", "");
      setValue("specialRate", false);
      setValue("sanofiRepVisits", false);
      setValue("profiledNewOrthopaedicians", false);
    }
  };

  useEffect(() => {
    getQuestionaireData(hospitalDetails._id);
  }, [open]);

  useEffect(() => {
    if (!watch("orthopaedicAttached")) {
      setValue("noOfOrthopaedicsAttached", "");
      setValue("patientsTreatedByOrthopaedics", "");
      setValue("orthoPractice", "");
    }
    if (!watch("bascogastAvailable")) {
      setValue("bascogastSalesValue", "");
      setValue("bascogastUnits", "");
    }
    if (!watch("depuraAvailable")) {
      setValue("depuraUnits", "");
    }
    if (!watch("bascogastAvailable") && !watch("depuraAvailable")) {
      setValue("specialRate", false);
    }
  }, [
    watch("orthopaedicAttached"),
    watch("bascogastAvailable"),
    watch("depuraAvailable"),
  ]);

  const disableSubmitButton = () => {
    if (
      !(
        watch("orthopaedicAttached") ||
        watch("bascogastAvailable") ||
        watch("depuraAvailable") ||
        watch("sanofiRepVisits") ||
        watch("profiledNewOrthopaedicians")
      )
    ) {
      return false;
    }

    return true;
  };

  const disableSubmitButtonBasedonOrthoAvaliable = () => {
    if (watch("orthopaedicAttached")) {
      if (
        watch("patientsTreatedByOrthopaedics") &&
        watch("orthoPractice") &&
        watch("patientsTreatedByOrthopaedics")
      ) {
        return false;
      }
      return true;
    }
  };

  const disableSubmitButtonBasedonDepuraAvailable = () => {
    if (watch("depuraAvailable")) {
      if (watch("depuraUnits")) {
        return false;
      }
      return true;
    }
  };

  const disableSubmitButtonBasedonBascogastAvailable = () => {
    if (watch("bascogastAvailable")) {
      if (watch("bascogastSalesValue") && watch("bascogastUnits")) {
        return false;
      }
      return true;
    }
  };

  let regex = /(^|\s)\S/g;
  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box sx={style}>
        <Stack
          position={"fixed"}
          width={"95%"}
          bgcolor={"white"}
          paddingBottom={2}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Stack width={"85%"}>
            <Typography textAlign={"center"} fontWeight={"550"}>
              {hospitalDetails.fullNames[
                hospitalDetails.fullNames.length - 1
              ].name
                ?.toLowerCase()
                .replace(regex, (letter: any) => letter.toUpperCase())}
            </Typography>
          </Stack>
          <Stack alignItems={"end"}>
            <CloseIcon
              fontSize="small"
              color="error"
              onClick={() => handleClose()}
            />
          </Stack>
        </Stack>
        {loading == FetchState.LOADING && (
          <Stack marginTop={4} spacing={3}>
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" height={50} />
          </Stack>
        )}
        {loading == FetchState.ERROR && (
          <Stack marginTop={4}>
            <Typography
              variant="subtitle2"
              color={"error.main"}
              textAlign={"center"}
            >
              Something went wrong
            </Typography>
          </Stack>
        )}
        {loading == FetchState.SUCCESS && (
          <Stack overflow={"auto"} height={"90%"} marginTop={3}>
            <Stack>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                marginTop={3}
                justifyContent={"space-between"}
              >
                <Typography variant="subtitle2">
                  Do this hospital has any orthopedic attached ?
                </Typography>
                <Controller
                  name="orthopaedicAttached"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Switch
                      checked={value}
                      onChange={onChange}
                      color="secondary"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  )}
                />
              </Stack>
              {watch("orthopaedicAttached") && (
                <Stack>
                  <Stack
                    direction={"row"}
                    spacing={10}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography fontSize={"13px"}>
                      How many orthopaediacians are attached to this hospital/NH
                      ?
                    </Typography>
                    <Controller
                      name="noOfOrthopaedicsAttached"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl size="small" sx={{ minWidth: 90 }}>
                          <Select
                            value={value}
                            onChange={onChange}
                            error={!!error}
                          >
                            <MenuItem value={"1"}>1</MenuItem>
                            <MenuItem value={"2"}>2</MenuItem>
                            <MenuItem value={"3"}>3</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      rules={{
                        required: {
                          value: watch("orthopaedicAttached") ? true : false,
                          message: "Required",
                        },
                      }}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={10}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography fontSize={"13px"}>
                      How many patients per day treated by orthopaedicians in
                      this hospital/NH ?
                    </Typography>
                    <Controller
                      name="patientsTreatedByOrthopaedics"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl size="small" sx={{ minWidth: 90 }}>
                          <Select
                            value={value}
                            onChange={onChange}
                            error={!!error}
                          >
                            <MenuItem value={"10"}>10</MenuItem>
                            <MenuItem value={"10-20"}>10 to 20</MenuItem>
                            <MenuItem value={"more than 20"}>
                              more than 20
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      rules={{
                        required: {
                          value: watch("orthopaedicAttached") ? true : false,
                          message: "Required",
                        },
                      }}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={10}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography fontSize={"13px"}>
                      What type Ortho practice happens more in this NH ?
                    </Typography>
                    <Controller
                      name="orthoPractice"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl size="small" sx={{ minWidth: 90 }}>
                          <Select
                            value={value}
                            onChange={onChange}
                            error={!!error}
                          >
                            <MenuItem value={"surgery"}>Surgery</MenuItem>
                            <MenuItem value={"opd"}>OPD</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      rules={{
                        required: {
                          value: watch("orthopaedicAttached") ? true : false,
                          message: "Required",
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                marginTop={3}
                justifyContent={"space-between"}
              >
                <Typography variant="subtitle2">
                  Does the pharmacy has Bascogast available ?
                </Typography>
                <Controller
                  name="bascogastAvailable"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Switch
                      checked={value}
                      onChange={onChange}
                      color="secondary"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  )}
                />
              </Stack>
              {watch("bascogastAvailable") && (
                <Stack>
                  <Stack
                    direction={"row"}
                    spacing={10}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography fontSize={"13px"}>
                      How many Bascogast the NH Pharmacy selling per month(In
                      strips) ?
                    </Typography>
                    <Controller
                      name="bascogastUnits"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl size="small" sx={{ minWidth: 90 }}>
                          <Select
                            value={value}
                            onChange={onChange}
                            error={!!error}
                          >
                            <MenuItem value={"10"}>10</MenuItem>
                            <MenuItem value={"10-20"}>10 to 20</MenuItem>
                            <MenuItem value={"more than 20"}>
                              more than 20
                            </MenuItem>
                          </Select>
                          <Typography
                            textAlign={"center"}
                            fontSize="12px"
                            color={"error.main"}
                          >
                            {error?.message}
                          </Typography>
                        </FormControl>
                      )}
                      rules={{
                        required: {
                          value: watch("bascogastAvailable") ? true : false,
                          message: "Required",
                        },
                      }}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={10}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography fontSize={"13px"}>
                      What's the value of sales per month of Bascogast from this
                      hospital ?
                    </Typography>
                    <Controller
                      name="bascogastSalesValue"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          size="small"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                        />
                      )}
                      rules={{
                        required: {
                          value: watch("bascogastAvailable") ? true : false,
                          message: "Required",
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                marginTop={3}
                justifyContent={"space-between"}
              >
                <Typography variant="subtitle2">
                  Does the pharmacy has Depura available ?
                </Typography>
                <Controller
                  name="depuraAvailable"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Switch
                      checked={value}
                      onChange={onChange}
                      color="secondary"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  )}
                />
              </Stack>
              {watch("depuraAvailable") && (
                <Stack>
                  <Stack
                    direction={"row"}
                    spacing={10}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography fontSize={"13px"}>
                      How many Depura the NH Pharmacy selling per month(In
                      vials) ?
                    </Typography>
                    <Controller
                      name="depuraUnits"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl size="small" sx={{ minWidth: 90 }}>
                          <Select
                            value={value}
                            onChange={onChange}
                            error={!!error}
                          >
                            <MenuItem value={"10"}>10</MenuItem>
                            <MenuItem value={"10-20"}>10 to 20</MenuItem>
                            <MenuItem value={"more than 20"}>
                              more than 20
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      rules={{
                        required: {
                          value: watch("depuraAvailable") ? true : false,
                          message: "Required",
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
            {(watch("bascogastAvailable") || watch("depuraAvailable")) && (
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                marginTop={3}
                justifyContent={"space-between"}
              >
                <Typography variant="subtitle2">
                  Do you get any special rate for Basogast & Depura ?
                </Typography>
                <Controller
                  name="specialRate"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Switch
                      checked={value}
                      onChange={onChange}
                      color="secondary"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  )}
                />
              </Stack>
            )}

            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              marginTop={3}
              justifyContent={"space-between"}
            >
              <Typography variant="subtitle2">
                Does any Sanofi representative visits this hospital/NH ?
              </Typography>
              <Controller
                name="sanofiRepVisits"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Switch
                    checked={value}
                    onChange={onChange}
                    color="secondary"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                )}
              />
            </Stack>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              marginTop={3}
              justifyContent={"space-between"}
            >
              <Typography variant="subtitle2">
                Have you completed profiling of the new Orthopaediacins of this
                NH ?
              </Typography>
              <Controller
                name="profiledNewOrthopaedicians"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Switch
                    checked={value}
                    onChange={onChange}
                    color="secondary"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                )}
              />
            </Stack>
          </Stack>
        )}
        {loading == FetchState.SUCCESS && (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            marginTop={1.5}
          >
            <LoadingButton
              size="small"
              variant="contained"
              fullWidth
              loadingPosition="start"
              loading={questionaireloading}
              color="secondary"
              disabled={
                disableSubmitButton() &&
                (disableSubmitButtonBasedonOrthoAvaliable() ||
                  disableSubmitButtonBasedonBascogastAvailable() ||
                  disableSubmitButtonBasedonDepuraAvailable())
              }
              onClick={handleSubmit(onSubmit)}
              sx={{ color: "#fff" }}
            >
              Submit
            </LoadingButton>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default QuestionaireModal;
