import { useState, useEffect } from "react";

function useGeoLocation() {
  const [location, setLocation] = useState<GeolocationCoordinates>();

  useEffect(() => {
    const { geolocation } = navigator;

    if (!geolocation) {
      console.log("Geolocation is not supported.");
      return;
    }

    geolocation.getCurrentPosition(
      (position) => {
        setLocation(position.coords);
      },
      (error) => {
        console.log("Couldn't fetch locaiton because of : ", error);
      },
      { enableHighAccuracy: true, maximumAge: 60000 }
    );
  }, []);

  return location;
}

export default useGeoLocation;
