import React, { useCallback, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MappedCustomerCard from "../MappedCustomerDetails";
import {
  GaTypography,
  TypographyColor,
  TypographyType,
} from "../../ga-components/Typography";
import { GaAccordion } from "../../ga-components/Accordion";
import { MappedDoctor } from "../../../domain/models/chemist/mappedDoctor";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import { FetchMappedDoctors } from "../../../domain/usages/Chemists/fetch-mapped-doctors";
import BrickDoctorDetails from "./BrickDoctorDetails";

type Props = {
  mappedDoctors: DoctorData[];
};

const MappedDoctors: React.FC<Props> = ({ mappedDoctors }) => {
  return (
    <div>
      <GaAccordion
        expandIcon={<ExpandMoreIcon />}
        bgColor={"#F5F5F5"}
        summary={
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >{`${mappedDoctors?.length} Mapped Doctor(s)`}</GaTypography>
        }
        details={
          <div style={{ overflow: "auto", height: "200px" }}>
            {mappedDoctors.length > 0 &&
              mappedDoctors.map((details) => {
                return <BrickDoctorDetails data={details} />;
              })}
          </div>
        }
      />
    </div>
  );
};

export default MappedDoctors;
