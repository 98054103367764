export const AUTH_HEADER: string = "Authorization";
export const AUTH_HEADER_CHANNELPAY: string = "auth-token";
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
export const CUSTOMER_PROFILER_API_URL =
  process.env.REACT_APP_CUSTOMER_PROFILER_API_URL;
export const HOSPITAL_PROFILER_API_URL =
  process.env.REACT_APP_HOSPITAL_PROFILER_API_URL;
export const DOCTOR_PROFILER_API_URL =
  process.env.REACT_APP_DOCTOR_PROFILER_API_URL;
export const LOCATION_SERVICE_API_URL =
process.env.REACT_APP_LOCATION_SERVICE_API_URL;
export const UPLOAD_IMAGE_API_URL = process.env.REACT_APP_UPLOAD_IMAGE_API_URL;
export const MOBILE = process.env.REACT_APP_MOBILE_API_URL;
export const AUTH_TOKEN_KEY: any = process.env.REACT_APP_TOKEN_KEY;
export const AUTH_USER_KEY: any = process.env.REACT_APP_ROLE_KEY;
export const GOOGLE_MAPS_API_KEY: any =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAPS_API: any = process.env.REACT_APP_GOOGLE_MAPS_API;
export const LOCATION_SERVICE_TOKEN =
process.env.REACT_APP_LOCATION_SERVICE_TOKEN
