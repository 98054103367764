import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useGeoLocation from "../../../domain/hooks/useGeoLocation";
import { LoggedInUser } from "../../../domain/usages/logged-in-user";
import { pageRoutes } from "../../../routes";

type Props = {
  authToken: string;
  loggedInUser: LoggedInUser;
};

const AuthenticatePage: React.FC<Props> = React.memo(
  ({ loggedInUser, authToken }) => {
    const location = useGeoLocation();
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      if (location && authToken) {
        loggedInUser.setToken(authToken);
        if (
          searchParams.get("appCode") == "CPAY" &&
          searchParams.get("screenType") == "chemistRegistration"
        ) {
          navigate(pageRoutes.checkMobileExists);
        } else {
          navigate(pageRoutes.customerProfiler);
        }
      }
    }, [location, authToken]);

    return (
      <div className="container h-full flex flex-col">
        <div
          className="h-full"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!location && (
            <h3 className="text-center text-indigo-800 text-3xl">
              Location is mandatory to do profiling.
            </h3>
          )}
        </div>
        <div className="flex-grow"></div>
      </div>
    );
  }
);

export default AuthenticatePage;
