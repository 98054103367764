import { useParams } from "react-router-dom";
import { LocalLoggedInUser } from "../data/usecases/local-logged-in-user";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import AuthenticatePage from "../presentation/pages/auth/authenticate-page";

export const AuthenticateFactory = () => {
  const { authToken } = useParams();
  const jsonStorage = LocalJsonStorage.getInstance();
  const localLoggedInUser = new LocalLoggedInUser(jsonStorage);

  if (!authToken) return <div>Invalid access</div>;
  return (
    <AuthenticatePage loggedInUser={localLoggedInUser} authToken={authToken} />
  );
};
