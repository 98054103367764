import { useNavigate } from "react-router-dom";
import { UploadChemistFileFactory } from "../../../main/Chemists/UploadChemistFileFactory";
import { pageRoutes } from "../../../routes";
import Header from "../../components/Header/Header";

type Props = {};

const ChemistRegistrationPage: React.FC<Props> = ({}) => {
  const navigate = useNavigate();

  return (
    <div>
      <>
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            width: "100%",
            backgroundColor: "white",
            zIndex: 50,
          }}
        >
          <Header
            title={"Register New Chemist"}
            showBackButton
            handleClick={() =>
              navigate(pageRoutes.customerProfiler, {
                state: {
                  value: 1,
                },
              })
            }
          />
        </div>
        <div style={{ margin: "17px", marginTop: "70px" }}>
          <UploadChemistFileFactory />
        </div>
      </>
    </div>
  );
};

export default ChemistRegistrationPage;
