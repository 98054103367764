import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  CUSTOMER_PROFILER_API_URL,
  HOSPITAL_PROFILER_API_URL,
} from "../../base";
import { RemoteFetchChemists } from "../../data/usecases/Chemists/remote-fetch-chemists";
import { RemoteAddPharmacyToHospital } from "../../data/usecases/Hospitals/remote-add-pharmacy-to-hospital";
import { RemoteGetHospitalPharmacy } from "../../data/usecases/Hospitals/remote-get-hospital-pharmacy";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import HospitalPharmacyMapping from "../../presentation/pages/Hospital/HospitalPharmacyMapping";

export const HospitalPharmacyMappingFactory = () => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);

  const customerServiceAxiosHttpClient = AxiosHttpClient.getInstance();
  customerServiceAxiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER]: `Bearer ${token}`,
  });

  const remoteMapPharmacy = new RemoteAddPharmacyToHospital(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.ADD_PHARMACY_TO_HOSPITAL}`,
    axiosHttpClient
  );

  const remoteFetchChemists = new RemoteFetchChemists(
    `${CUSTOMER_PROFILER_API_URL}${Endpoints.FETCH_CUSTOMERS}`,
    customerServiceAxiosHttpClient
  );

  const remoteGetHospitalPharmacy = new RemoteGetHospitalPharmacy(
    `${HOSPITAL_PROFILER_API_URL}${Endpoints.GET_HOSPITAL_PHARMACY}`,
    axiosHttpClient
  );

  return (
    <HospitalPharmacyMapping
      fetchBrickChemists={remoteFetchChemists}
      mapPharmacy={remoteMapPharmacy}
      getHospitalPharmacy={remoteGetHospitalPharmacy}
    />
  );
};
